import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../assets/images/loader-orange.svg';

class ViewMarkets extends Component {
  componentWillMount() {
    this.props.viewMarketsStore.getSports()
  }
  componentDidMount() { }
  componentWillUnmount() { }
  render() {
    const { viewMarketsStore } = this.props
    return (
      <div className="RDE-DB-Admin-screens">
        <div className="RDE-DB-Admin-addAdminMatchOdds">
          <div className="RDE-sportsPage-leagueHeader">
            View Admin Markets
          </div>
          <div className="RDE-DB-myBets-filters mb-10">
            <div className="RDE-DB-myBets-filters__left">
              <span className="RDE-DB-myBets-filters__label">Sports :</span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {viewMarketsStore.selectedSport.sportName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      viewMarketsStore.sportsList.map((sport, index) => {
                        return <li key={"adminmarketsport_" + index}
                          onClick={viewMarketsStore.selectSport.bind(viewMarketsStore, sport)}><Dropdown.Item eventKey={sport.sportName}>{sport.sportName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>

              <span className="RDE-DB-myBets-filters__label">League: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {viewMarketsStore.selectedLeague.leagueName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      viewMarketsStore.leaguesList.map((league, index) => {
                        return <li key={"adminmarketleague_" + index}
                          onClick={viewMarketsStore.selectLeague.bind(viewMarketsStore, league)}><Dropdown.Item eventKey={league.leagueName}>{league.leagueName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <span className="RDE-DB-myBets-filters__label">Events: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {viewMarketsStore.selectedEvent.eventName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      viewMarketsStore.eventsList.map((event, index) => {
                        return <li key={"adminmarketevent_" + index}
                          onClick={viewMarketsStore.selectEvent.bind(viewMarketsStore, event)}><Dropdown.Item eventKey={event.eventName}>{event.eventName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <span className="RDE-DB-myBets-filters__label">Bet Type: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {viewMarketsStore.selectedMarket}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    <li>
                      <Dropdown.Item eventKey="Match Odds"
                        onClick={viewMarketsStore.selectMarket.bind(viewMarketsStore, "Match Odds")}>Match Odds</Dropdown.Item>
                    </li>
                    <li>

                      <Dropdown.Item eventKey="Fancy Markets"
                        onClick={viewMarketsStore.selectMarket.bind(viewMarketsStore, "Fancy Markets")}>Fancy Markets</Dropdown.Item>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {viewMarketsStore.selectedMarket === "Match Odds" && viewMarketsStore.selectedEvent.eventName && viewMarketsStore.selectedEvent.eventName.length > 0 ? <>
            <div className="grid-noGutter">
              <div className="col-12 text-right mb-5">
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto mr-5"
                  onClick={viewMarketsStore.suspendMo.bind(viewMarketsStore, 'suspend')}>
                  Suspend Markets
                </button>
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto"
                  onClick={viewMarketsStore.suspendMo.bind(viewMarketsStore, 'unsuspend')}>
                  Unsuspend Markets
                </button>
              </div>
            </div>
            <div className="RDE-DB-table">
              <table className="RDE-DB-table-default">
                <thead>
                  <tr>
                    <th>Team</th>
                    <th>Back Odds</th>
                    <th>Lay Odds</th>
                    <th>Back Size</th>
                    <th>Lay Size</th>
                    <th>Min. Stake</th>
                    <th>Max. Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !viewMarketsStore.showLoader && viewMarketsStore.MatchOdds.map((outcome, index) => {
                      return <tr key={"viewmatchOdds_" + index}>
                        <td>{outcome.team}</td>
                        <td>{outcome.backOdds && outcome.backOdds}</td>
                        <td>{outcome.layOdds && outcome.layOdds}</td>
                        <td>{outcome.backSize && outcome.backSize}</td>
                        <td>{outcome.laySize && outcome.laySize}</td>
                        <td>{outcome.minStake}</td>
                        <td>{outcome.maxStake}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="space-5"></div>
            <div className="grid-noGutter text-right">
              {viewMarketsStore.MatchOdds.length > 0 ? <div className="col-2">
                <button className="btn RDE-btn-primary"
                  onClick={viewMarketsStore.confirmMO.bind(viewMarketsStore)}>Confirm</button>
              </div> : ''}
            </div></> : ''}

          {viewMarketsStore.selectedMarket === "Fancy Markets" && viewMarketsStore.selectedEvent.eventName && viewMarketsStore.selectedEvent.eventName.length > 0 ? <><div className="RDE-DB-table">
            <div className="grid-noGutter">
              <div className="col-12 text-right mb-5">
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto mr-5"
                  onClick={viewMarketsStore.suspendUnsuspendAllFancy.bind(viewMarketsStore, 'suspend')}>
                  Suspend All Markets
                </button>
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto mr-5"
                  onClick={viewMarketsStore.suspendUnsuspendAllFancy.bind(viewMarketsStore, 'unsuspend')}>
                  Unsuspend All Markets
                </button>
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto mr-5"
                  onClick={viewMarketsStore.suspendUnsuspendSelectedFancy.bind(viewMarketsStore, 'suspend')}>
                  Suspend Selected Markets
                </button>
                <button className="btn RDE-btn-default RDE-btn-small RDE-btn-auto"
                  onClick={viewMarketsStore.suspendUnsuspendSelectedFancy.bind(viewMarketsStore, 'unsuspend')}>
                  Unsuspend Selected Markets
                </button>
              </div>
            </div>
            <table className="RDE-DB-table-default">
              <thead>
                <tr>
                  <th rowspan="2">
                    <div className="RDE-checkbox">
                      <input type="checkbox" name="check0" />
                      <label></label>
                      <span className="RDE-checkboxLabel">Select All</span>
                    </div>
                  </th>
                  <th rowspan="2">Market</th>
                  <th colspan="2">Outcome</th>
                  <th colspan="2">Odds</th>
                  <th rowspan="2">Status</th>
                  <th rowspan="2">Min. Stake</th>
                  <th rowspan="2">Max. Stake</th>
                  <th rowspan="2">Modify</th>
                </tr>
                <tr>
                  <th>Yes</th>
                  <th>No</th>
                  <th>Yes</th>
                  <th>No</th>
                </tr>
              </thead>
              <tbody>
                {!viewMarketsStore.showLoader && viewMarketsStore.fancyMarketList.map((item, index) => {
                  return <tr key={"viewfancy_" + index}>
                    <td>
                      <div className="RDE-checkbox">
                        <input id={`viewfancy_${index}`} type="checkbox" name="check1"
                          onClick={viewMarketsStore.selectMarkets.bind(viewMarketsStore, item.fancyMarketId)}
                        />
                        <label for={`viewfancy_${index}`}></label>
                      </div>
                    </td>
                    <td>{item.fancyMarketName}</td>
                    <td>{+item.fancyOutcomeYes > 0 ? item.fancyOutcomeYes : '-'}</td>
                    <td>{+item.fancyOutcomeNo > 0 ? item.fancyOutcomeNo : '-'}</td>
                    <td>{+item.oddsYes > 0 ? item.oddsYes : '-'}</td>
                    <td>{+item.oddsNo > 0 ? item.oddsNo : '-'}</td>
                    <td>{item.suspended ? 'Suspended' : 'unsuspend'}</td>
                    <td>{+item.fancyMinStake > 0 ? item.fancyMinStake : '-'}</td>
                    <td>{+item.fancyMaxStake > 0 ? item.fancyMaxStake : '-'}</td>
                    <td>
                      <button class="btn RDE-btn-primary RDE-btn-auto RDE-btn-small" onClick={viewMarketsStore.callActive.bind(viewMarketsStore, item.fancyMarketId, item.fancyStatus)}>
                        {item.fancyStatus}
                      </button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
            <div>{viewMarketsStore.addFancyErr}</div>
          </div>
            <div className="space-5"></div>
          </> : ''}
          {!viewMarketsStore.showLoader &&
            (((viewMarketsStore.selectedMarket === "Match Odds" && viewMarketsStore.MatchOdds && viewMarketsStore.MatchOdds.length === 0) ||
              (viewMarketsStore.selectedMarket === "Fancy Markets" && viewMarketsStore.fancyMarketList && viewMarketsStore.fancyMarketList.length === 0)) ||
              viewMarketsStore.selectedMarket.length === 0) ? <div className="RDE-noDataMessageBox">
            Currently data is not available.
          </div> : ''}
          {viewMarketsStore.showLoader ? <div className="RDE-loader">
            <img src={dataLoader} alt="loading...!" />
          </div> : ''}
        </div>
      </div>
    )
  }
}
export default observer(ViewMarkets);