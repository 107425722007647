import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ChangePassword from './changepassword/changePassword';
import ChangePasswordStore from './changepassword/changePasswordStore';
import ChangeExposureLimit from './changeexposurelimit/changeExposureLimit';
import ChangeExposureLimitStore from './changeexposurelimit/changeExposureLimitStore';
import ChangeCommission from './changecommission/changeCommission';
import ChangeCommissionStore from './changecommission/changeCommissionStore';
import { getUserData } from "../../../sharedfiles/helper";
import { Modal } from "react-bootstrap";

const changepasswordStore = new ChangePasswordStore();
const changeExposureLimitStore = new ChangeExposureLimitStore();
const changeCommissionStore = new ChangeCommissionStore();
class AccountSummary extends Component {
	constructor(props) {
		super(props);
	}
	componentWillMount() {
		if (this.props.myAccountStore.userId && this.props.myAccountStore.userId !== null && (this.props.myAccountStore.userId !== this.props.accountSummaryStore.userId)) {
			this.props.accountSummaryStore.userId = this.props.myAccountStore.userId;
			this.props.accountSummaryStore.getAccountSummary();
		}

	}
	componentWillReceiveProps(nextProps) {
		if (this.props.myAccountStore.userId !== this.props.accountSummaryStore.userId) {
			this.props.accountSummaryStore.userId = this.props.myAccountStore.userId;
			this.props.accountSummaryStore.getAccountSummary();
		}
	}
	componentDidMount() { }
	componentWillUnmount() { }
	onChange() {

	}
	render() {
		const { mainLayoutStore, myAccountStore, accountSummaryStore, fromComponent } = this.props;
		return (
			<div className="RDE-Admin-profileView__accountSummary">
				<div className="RDE-sportsPage-leagueHeader">
					Account Summary
				</div>
				<div className="RDE-DB-table mb-10">
					<table className="RDE-DB-table-default">
						<thead>
							<tr>
								<th>Wallet</th>
								<th>Funds Available</th>
								<th>Current Exposure</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Main wallet</td>
								<td>{accountSummaryStore.userWallet.availableToBet && accountSummaryStore.userWallet.fundsAvailableToWithdraw &&
									(accountSummaryStore.userWallet.availableToBet !== null && accountSummaryStore.userWallet.availableToBet !== '' && accountSummaryStore.userWallet.fundsAvailableToWithdraw !== null && accountSummaryStore.userWallet.fundsAvailableToWithdraw !== '') ? Number(accountSummaryStore.userWallet.availableToBet) : '---'}</td>
								<td>{accountSummaryStore.userWallet && accountSummaryStore.userWallet.currentExposure && (accountSummaryStore.userWallet.currentExposure != null && accountSummaryStore.userWallet.currentExposure !== '') ? accountSummaryStore.userWallet.currentExposure : '---'}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="grid-top">
					<div className="col-12">
						<div className="RDE-sportsPage-leagueHeader">
							Profile
						</div>
					</div>
					<div className="col-6">
						<div className="RDE-DB-infoBox">
							<div className="RDE-DB-infoBox__header">
								<div className="RDE-DB-infoBox__header-left">About you</div>
							</div>
							<div className="RDE-DB-infoBox__content">
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>First
										name</span><span className="RDE-DB-colon">:</span></div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.firstName && (accountSummaryStore.userProfileDetails.firstName !== '' && accountSummaryStore.userProfileDetails.firstName !== null) ? accountSummaryStore.userProfileDetails.firstName : '---'}</b></div>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>Last
										name</span><span className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.lastName && (accountSummaryStore.userProfileDetails.lastName !== '' && accountSummaryStore.userProfileDetails.lastName !== null) ? accountSummaryStore.userProfileDetails.lastName : '---'}</b></div>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left">
										<span>Password</span><span className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>*************</b></div>
									</div>
									{/* {fromComponent!=='downline'?<div className="RDE-DB-infoBox-item__edit" onClick={accountSummaryStore.showHideChangePassword.bind(accountSummaryStore, true)}>
										<i className="RDE-icon-edit"></i>
									</div>:""} */}
									<div className="RDE-DB-infoBox-item__edit" onClick={accountSummaryStore.showHideChangePassword.bind(accountSummaryStore, true)}>
										<i className="RDE-icon-edit"></i>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>DOB</span><span
										className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{(accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.birthday && accountSummaryStore.userProfileDetails.birthday !== null && accountSummaryStore.userProfileDetails.birthday !== '') ? accountSummaryStore.userProfileDetails.birthday : '---'}</b></div>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>E-mail</span><span
										className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.emailId && (accountSummaryStore.userProfileDetails.emailId !== null && accountSummaryStore.userProfileDetails.emailId !== '') ? accountSummaryStore.userProfileDetails.emailId : '---'}</b></div>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>Primary
										number</span><span className="RDE-DB-colon">:</span></div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.contactNumber && (accountSummaryStore.userProfileDetails.contactNumber !== null && accountSummaryStore.userProfileDetails.contactNumber !== '') ? accountSummaryStore.userProfileDetails.contactNumber : '---'}</b></div>
									</div>
								</div>

								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>Time
										Zone</span><span className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.timeZone && (accountSummaryStore.userProfileDetails.timeZone !== null && accountSummaryStore.userProfileDetails.timeZone !== '') ? accountSummaryStore.userProfileDetails.timeZone : '---'}</b></div>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>
										Currency</span><span className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.currency ? accountSummaryStore.userProfileDetails.currency : '---'}</b></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="RDE-DB-infoBox">
							<div className="RDE-DB-infoBox__header">
								<div className="RDE-DB-infoBox__header-left">Limits & Commission</div>
								<div className="RDE-DB-infoBox__header-right"></div>
							</div>
							<div className="RDE-DB-infoBox__content">
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left"><span>Exposure
										Limit</span><span className="RDE-DB-colon">:</span></div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.exposureLimit && (accountSummaryStore.userProfileDetails.exposureLimit !== null) ? accountSummaryStore.userProfileDetails.exposureLimit : '---'}</b></div>
									</div>
									<div className="RDE-DB-infoBox-item__edit" onClick={accountSummaryStore.showHideChangeExposureLimit.bind(accountSummaryStore, true)}>
										<i className="RDE-icon-edit"></i>
									</div>
								</div>
								<div className="RDE-DB-infoBox-item">
									<div className="RDE-DB-infoBox-item__left">
										<span>Commission</span><span className="RDE-DB-colon">:</span>
									</div>
									<div className="RDE-DB-infoBox-item__right">
										<div><b>{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.commissionChanges && (accountSummaryStore.userProfileDetails.commissionChanges !== null) ? accountSummaryStore.userProfileDetails.commissionChanges : '---'}</b></div>
									</div>
									<div className="RDE-DB-infoBox-item__edit" onClick={accountSummaryStore.showHideChangeCommission.bind(accountSummaryStore, true)}>
										<i className="RDE-icon-edit"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					show={accountSummaryStore.showChangePassword}
					onHide={accountSummaryStore.showHideChangePassword.bind(accountSummaryStore, false)}
					backdrop={true}
					backdropClassName="fade"
					centered
					dialogClassName="RDE-loginSignup-modal">
					<ChangePassword changepasswordStore={changepasswordStore}
						accountSummaryStore={accountSummaryStore}
						fromComponent={fromComponent}
						mainLayoutStore={mainLayoutStore} />
				</Modal>

				<Modal
					show={accountSummaryStore.showChangeExposureLimit}
					onHide={accountSummaryStore.showHideChangeExposureLimit.bind(accountSummaryStore, false)}
					backdrop={true}
					backdropClassName="fade"
					centered
					dialogClassName="RDE-loginSignup-modal">
					<ChangeExposureLimit changeExposureLimitStore={changeExposureLimitStore}
						accountSummaryStore={accountSummaryStore}
						mainLayoutStore={mainLayoutStore} />
				</Modal>

				<Modal
					show={accountSummaryStore.showChangeCommission}
					onHide={accountSummaryStore.showHideChangeCommission.bind(accountSummaryStore, false)}
					backdrop={true}
					backdropClassName="fade"
					centered
					dialogClassName="RDE-loginSignup-modal">
					<ChangeCommission changeCommissionStore={changeCommissionStore}
						accountSummaryStore={accountSummaryStore}
						mainLayoutStore={mainLayoutStore} />
				</Modal>
			</div>
		)
	}
}
export default observer(AccountSummary);