import React, { Component } from 'react';
import { observer } from 'mobx-react';
import close from '../../../assets/images/modal-close.svg';

import { getUserData } from "../../../sharedfiles/helper";
class ChangeStatus extends Component {
    componentWillMount() {
      this.props.changeStatusStore.parentStore = this.props.downlineListTableStore;
      this.props.changeStatusStore.downlineStore = this.props.downlineStore;
      this.props.changeStatusStore.resetFields();
    }
    componentDidMount(){}
    componentWillUnmount(){}
    render() {
      const {userDetails,changeStatusStore,downlineListTableStore} = this.props;
      var userData = getUserData();
      return (
        <div className="RDE-changeStatus">
          <div className="RDE-modal-content">
            <div className="modal-header">
              <div className="RDE-modal-header__txt">Change Status</div>
              <img src={close} class="RDE-modal-closeIcon" onClick={downlineListTableStore.toggleChangeSatausPopUp.bind(downlineListTableStore,{})}/>
            </div>
            <div className="modal-body">
              <div className="grid">
                <div className="col-12">
                  <div className="RDE-statusInfo">
                    <div>
                      <span className={"RDE-badge "+ (userDetails.roleId===1 || userDetails.roleId===2?"green":userDetails.roleId===3?"seaGreen":"darkYellow")}>
                        {userDetails.roleId===1?"AD":userDetails.roleId===2?"SUP":userDetails.roleId===3?"MA":"PL"}
                      </span>{userDetails.account}
                    </div>

                    {userDetails.statusId===1?<div className="align-items-middle green"><span className="RDE-badge green"></span>Active</div>:
                     userDetails.statusId===2?<div className="align-items-middle red"><span className="RDE-badge red"></span>Suspend</div>:
                     userDetails.statusId===3?<div className="align-items-middle grey"><span className="RDE-badge grey"></span>Locked</div>:''}
                  </div>
                </div>
              </div>
              <div className="RDE-hr"></div>
              <div className="grid">
                <div className="col-12 text-center">
                  <div className="RDE-statusButtons">
                    <ul>
                      <li className={userDetails.statusId===1?"statusDisabled":changeStatusStore.selectedStatus===1?"statusActive":''}
                          onClick={changeStatusStore.selectStatus.bind(changeStatusStore,1)}>
                        <div><i className="RDE-icon-success"></i></div>
                        <div>Active</div>
                      </li>
                      <li className={userDetails.statusId===2 || userDetails.statusId===3?"statusDisabled":changeStatusStore.selectedStatus===2?"statusSuspend":''}
                          onClick={changeStatusStore.selectStatus.bind(changeStatusStore,2)}>
                        <div>
                          <i className="RDE-icon-user-block"></i>
                        </div>
                        <div>Suspend</div>
                      </li>
                      <li className={userDetails.statusId===3?"statusDisabled":changeStatusStore.selectedStatus===3?"statusLocked":''}
                          onClick={changeStatusStore.selectStatus.bind(changeStatusStore,3)}>
                        <div>
                          <i className="RDE-icon-lock"></i>
                        </div>
                        <div>Locked</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="RDE-modal-footer modal-footer">
              <div className="grid-center">
                <div className="col-10">
                <span>Password: </span><input type="password" className="RDE-formControl" placeholder="" value={changeStatusStore.password} onChange={changeStatusStore.setPassword.bind(changeStatusStore)}/>
                  <div className="RDE-formControl-errorMessage">{changeStatusStore.errorMsg}</div>
                </div>
                <div className="col-10">
                  <button className="btn RDE-btn-primary" onClick={changeStatusStore.changeStatus.bind(changeStatusStore,userDetails)}>Change</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}
export default observer(ChangeStatus);
