import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class AccountStatementStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole: getUserRole(),
      showLoader: false,
      accountStatement: [],
      showErrMsg: false,
    });
  }


  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getaccountStatement() {
    this.showLoader = true;
    this.showErrMsg = false;
    this.accountStatement = [];
    apiCaller(myaccount.getAccountStatement + "?userToken=" + getUserToken(), 'GET').then(response => {
      // apiCaller(myaccount.getAccountStatement+"?userToken=7203dcfd-dde2-44ee-ae85-7218096b9579",'GET').then(response => {
      if (response && response.data) {
        this.showLoader = false
        this.accountStatement = response.data;
      }
      else {
        this.showErrMsg = true;
      }
    })
  }

}

export default AccountStatementStore;
