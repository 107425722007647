import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route } from "react-router-dom";

import DownlineListTable from './downlinelisttable/DownlineListTable';
import DownlineListTableStore from './downlinelisttable/DownlineListTableStore';
import MyAccount from '../myaccount/myAccount';
import MyAccountStore from '../myaccount/myAccountStore';
import { getUserData } from "../../sharedfiles/helper";

const downlineListTableStore = new DownlineListTableStore();
const myAccountStore = new MyAccountStore();

class Downline extends Component {
  componentWillMount() {
    this.props.downlineStore.getDownlineList();
    var userData = getUserData();
    this.props.downlineStore.userBreadCrumb = [];
    this.props.downlineStore.userBreadCrumb.push({
      "userId": '',
      "roleId": userData.roleId,
      "userName": userData.userName
    });
    this.props.downlineStore.downlineProps = this.props;
    this.props.downlineStore.activeBreadCrumb = this.props.downlineStore.userBreadCrumb[0];
    this.props.downlineStore.loggedInUserRole = userData.roleId;
    this.props.downlineStore.currency = userData.currency;
    this.props.downlineStore.showingComponent = "downline";
  }
  componentDidMount() { }
  componentWillUnmount() { }
  render() {
    const { downlineStore, mainLayoutStore } = this.props;
    const userDetails = downlineStore.userDetails;
    return (
      <div className="RDE-DB-downloadList">
        {downlineStore.showingComponent === "downline" ? <div className="RDE-DB-balanceOverview-balanceSection">
          <div className="grid">
            <div className="col">
              <div className="RDE-DB-balanceOverview-myBalance">
                <div>CREDIT LIMIT</div>
                <div className="RDE-DB-myBalance__value">{downlineStore.currency} {downlineStore.creditLimmit && downlineStore.creditLimmit.toFixed(2)}</div>
              </div>
            </div>
            <div className="col">
              <div className="RDE-DB-balanceOverview-myBalance">
                <div>CREDIT DISTRIBUTED</div>
                <div className="RDE-DB-myBalance__value">{downlineStore.currency} {downlineStore.creditDistributed && downlineStore.creditDistributed.toFixed(2)}</div>
              </div>
            </div>
            <div className="col">
              <div className="RDE-DB-balanceOverview-myBalance">
                <div>TOTAL EXPOSURE</div>
                <div className="RDE-DB-myBalance__value">{downlineStore.currency} {downlineStore.totalExposure && downlineStore.totalExposure.toFixed(2)}</div>
              </div>
            </div>
            <div className="col">
              <div className="RDE-DB-balanceOverview-myBalance">
                <div>DOWNLINE AVL.BAL</div>
                <div className="RDE-DB-myBalance__value">{downlineStore.currency} {downlineStore.totalAvailableBalance && downlineStore.totalAvailableBalance.toFixed(2)}</div>
              </div>
            </div>
            {downlineStore.loggedInUserRole !== 3 ? <div className="col">
              <div className="RDE-DB-balanceOverview-myBalance">
                <div>TOTAL P&L</div>
                <div className="RDE-DB-myBalance__value">{downlineStore.currency} {downlineStore.totalProfitLoss && downlineStore.totalProfitLoss.toFixed(2)}</div>
              </div>
            </div> : ""}
          </div>
        </div> : ''}
        <div className="space-5"></div>
        <div className="grid-middle">
          <div className="col">
            <div className="RDE-DB-userBreadcrumbNav">
              <ul>
                {
                  downlineStore.userBreadCrumb.map((item, index) => {
                    return <li key={'userBreadCrumb_' + index} onClick={downlineStore.breadCrumbClick.bind(downlineStore, index, item.userId)}>
                      <span className={"RDE-badge " + (item.roleId === 1 || item.roleId === 2 ? "green" : item.roleId === 3 ? "seaGreen" : "darkYellow")}>{item.roleId === 1 ? "AD" : item.roleId === 2 ? "SUP" : item.roleId === 3 ? "MA" : "PL"}</span><span
                        className="text-underline">{item.userName}</span>
                    </li>
                  })
                }
              </ul>
            </div>
          </div>
          {downlineStore.showingComponent === 'downline' ? <div className="col">
            <div className="RDE-DB-userControls">
              <ul>
                <li>
                  {downlineStore.loggedInUserRole === downlineStore.activeBreadCrumb.roleId ? <button className="btn RDE-btn-primary RDE-btn-addUser" onClick={mainLayoutStore.toggleAddUser.bind(mainLayoutStore)}>
                    <i className="RDE-icon-user-profile"></i> {downlineStore.loggedInUserRole === 1 ? "Add Super Agent" : downlineStore.loggedInUserRole === 2 ? "Add Master Agent" : downlineStore.loggedInUserRole === 3 ? "Add Player" : ""}</button> : ""}
                </li>
                <li>
                  <button className="btn RDE-btn-primary" onClick={downlineStore.refreshData.bind(downlineStore)}><i className="RDE-icon-reload"></i></button>
                </li>
              </ul>
            </div>
          </div> : ""}
        </div>
        <div className="space-2"></div>
        {downlineStore.showingComponent === 'downline' ? <Route render={(props) => <DownlineListTable {...props} downlineListTableStore={downlineListTableStore}
          userDetails={userDetails}
          downlineStore={downlineStore}
          roleId={downlineStore.activeBreadCrumb.roleId}
          showLoader={downlineStore.showLoader}
        />
        } /> :
          <Route render={(props) => <MyAccount {...props} downlineStore={downlineStore} myAccountStore={myAccountStore}
            mainLayoutStore={mainLayoutStore}
            fromComponent="downline"
            userId={downlineStore.accountUserId}
            userRole={downlineStore.accountUserRole} />} />}

      </div>
    )
  }
}
export default observer(Downline);