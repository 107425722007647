import { extendObservable } from "mobx";
import Validator from 'validatorjs';
import { profile } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { getUserToken, getUserRole, getUserData } from "../../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class ChangePasswordStore {
    constructor(props) {
        extendObservable(this, {
            accountSummaryStore:null,
            fromComponent:'',
            loggedInUserRole: getUserRole(),
            changePasswordFields: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
               // email:""
            },
            changePasswordErrors: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
               // email:""
            },
            userDetails:{},
            responseMsg:''
        });
    }

    /**
      * This method is used to reset the login and signup fields
      *
      */
    resetFields() {
        this.responseMsg='';
        this.changePasswordFields = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            //email:""
        };
        this.changePasswordErrors = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
           // email:""
        }
    }

    onPasswordFieldChange(field, event) {
        this.responseMsg=''
        switch (field) {
            case 'oldPassword':
                this.changePasswordFields.oldPassword = event.target.value;
                this.changePasswordErrors.oldPassword = "";
                break;
            case 'newPassword':
                this.changePasswordFields.newPassword = event.target.value;
                this.changePasswordErrors.newPassword = "";
                break;
            case 'confirmPassword':
                this.changePasswordFields.confirmPassword = event.target.value;
                this.changePasswordErrors.confirmPassword = "";
                break;
                // case 'email':
                //     this.changePasswordFields.email = event.target.value;
                //     this.changePasswordErrors.email = "";

            default:
                console.log("change password");
        }
    }

    /**
    * This method is used to get the User profile data.
    */
    updatePassword() {
        if(this.fromComponent=='downline'){
        var rules = {
            // oldPassword: 'required',
            oldPassword: 'required|min:8',
            newPassword: 'required|min:8',
           // confirmPassword: 'min:8',
           // confirmPassword: 'required|same:newPassword',
            //email:'required'

        };
    }
    else{
        var rules = {
            // oldPassword: 'required',
            oldPassword: 'required|min:8',
            newPassword: 'required|min:8',
            confirmPassword: 'min:8',
            confirmPassword: 'required|same:newPassword',
           
        };
    }

        var validation = new Validator(this.changePasswordFields, rules, {
            "required.oldPassword": "Please enter old password",
            "min.oldPassword": "Password must be at least 8 characters.",
            "required.newPassword": "Please enter new password",
            "min.newPassword": "Password must be at least 8 characters.",
            "required.confirmPassword": "Please enter confirm password",
            "min.confirmPassword": "Password must be at least 8 characters.",
            "same.confirmPassword": "Password and confirm password should match",
            //"required.email":"Please enter email"
        });
        if(this.fromComponent=='downline'){
        var param = {
            userToken: getUserToken(),
            currentPassword: this.changePasswordFields.oldPassword,
            newPassword: this.changePasswordFields.newPassword,
            emailId:this.userDetails.emailId,
            isUser:this.loggedInUserRole===4?true:false
        }
    }
    else{
        var param = {
            userToken: getUserToken(),
            currentPassword: this.changePasswordFields.oldPassword,
            newPassword: this.changePasswordFields.newPassword,
            isUser:false
        }
    }

        if (validation.passes()) {
            apiCaller(profile.updatePassword, 'POST', param).then(response => {
                if (response && response.data) {
                    if(response.data.updated){
                        this.accountSummaryStore.showHideChangePassword(false);
                    }
                    else if(response.data.message){
                        this.responseMsg=response.data.message
                    }
                }
            })
        } else {
            if (validation.errors.has('oldPassword') && validation.errors.get("oldPassword").length > 0) {
                this.changePasswordErrors.oldPassword = validation.errors.get("oldPassword")[0]
            }
            if (validation.errors.has('newPassword') && validation.errors.get("newPassword").length > 0) {
                this.changePasswordErrors.newPassword = validation.errors.get("newPassword")[0]
            }
            if (validation.errors.has('confirmPassword') && validation.errors.get("confirmPassword").length > 0) {
                this.changePasswordErrors.confirmPassword = validation.errors.get("confirmPassword")[0]
            }
            if (validation.errors.has('email') && validation.errors.get("email").length > 0) {
                this.changePasswordErrors.email = validation.errors.get("email")[0]
            }
        }
    }

}

export default ChangePasswordStore;
