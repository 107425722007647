import { extendObservable } from "mobx";
import Validator from 'validatorjs';
import cookie from 'react-cookies';

import { getAuthenticationNumber } from "../../sharedfiles/helper";
import { login } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

const re = /^[0-9\b]+$/;
class LoginStore {
  constructor(props) {
    extendObservable(this, {
      email: "",
      password: "",
      reAuthNumber: '',
      authNumber: '',
      errorMsg: '',
      isEmailError: false,
    });
  }
  /**
  * This method is used to reset the login and signup fields
  *
  */
  resetFields() {
    this.email = '';
    this.password = '';
    this.reAuthNumber = '';
    this.isEmailError = false;
    this.errorMsg = '';
  }
  /**
  * This method is called when there is a change in the login fields(onchange event).
  * this method reads user typed value and assigns to respective state variable 
  * @param -field - {string}- this param contains the field name in which change occered 
  * @param -event- {js event object }- which contains user typed authentication number.
  */
  onLoginFiledChange(field, event) {
    switch (field) {
      case 'email':
        this.email = event.target.value;
        break;
      case 'password':
        this.password = event.target.value;
        break;
      case 'reAuthNumber':

        if (event.target.value === '' || re.test(event.target.value)) {
          this.reAuthNumber = event.target.value;
        } else if (event.keyCode === 13) {
          this.login(() => {
            window.location.href = "/downline"
          });
        }
        break;
      default:
        console.log("signup default");
    }
  }
  searchQuery(e) {
    var keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      this.login(() => {
        window.location.href = "/downline"
      });
    };

  }
  /**
  * This method is called when user clicks login button.
  * this method validate fileds
  * first it validates the authentication number
  * and it calls main login method in the app store
  */
  login(callBack) {
    var data = {
      emailId: this.email,
      password: this.password
    };
    this.validateLoginFields(data, (isValid) => {
      if (isValid) {
        if (Number(this.authNumber) === Number(this.reAuthNumber)) {
          this.showLoginLoader = true;
          this.mainLogin(data, (isSuccess) => {
            this.showLoginLoader = false;
            if (isSuccess) {
              this.email = "";
              this.password = "";
              this.errorMsg = "";
              callBack();
            } else {
              if (!this.errorMsg) {
                this.errorMsg = 'Invalid email id or password.';
              }
              this.authNumber = getAuthenticationNumber();

            }
            this.reAuthNumber = '';
          });
        } else {
          this.errorMsg = 'Invalid authentication number.';
          this.authNumber = getAuthenticationNumber();
          this.reAuthNumber = '';
        }
      } else {
        this.errorMsg = 'Invalid email id or password.';
        this.authNumber = getAuthenticationNumber();
      }
      this.reAuthNumber = '';
    });
    setTimeout(() => {
      if (this.errorMsg) {
        this.errorMsg = "";
      }
    }, 6000)
  }
  mainLogin(data, callback) {
    apiCaller(login.login, 'POST', data).then(response => {
      if (response.data && response.data.hasOwnProperty('isValidLogin') && response.data.isValidLogin &&
        response.data.hasOwnProperty('userLoginToken') && response.data.userLoginToken && response.data.userLoginToken.length > 0 &&
        response.data.hasOwnProperty('userId') && response.data.userId && response.data.userId > 0 &&
        response.data.hasOwnProperty('roleId') && response.data.roleId !== 4 &&
        response.data.hasOwnProperty('userName') && response.data.userName && response.data.userName.length > 0 &&
        response.data.hasOwnProperty('userExposure') &&
        response.data.hasOwnProperty('userAccountBalance')
      ) {
        cookie.save("UserToken", response.data.userLoginToken, { path: '/' });
        var userInfo = {
          userId: response.data.userId,
          userName: response.data.userName,
          userAccountBalance: response.data.userAccountBalance,
          userExposure: response.data.userExposure,
          roleId: response.data.roleId,
          currency: response.data.currencyCode,
          timeZone: ''
        };
        this.userInfo = userInfo;
        cookie.save("userData", userInfo, { path: '/' });
        this.isLoggedIn = true;
        callback(true);
      } else if (!response.data) {
        this.errorMsg = response.description
        callback(false);
      }
      else {
        callback(false);
      }
    });
  }
  validateLoginFields(data, callback) {
    var rules = {
      // emailId:'required|email',
      // password:'required|min:8'
      emailId: 'required',
      password: 'required'
    };
    var validation = new Validator(data, rules);
    if (validation.passes()) {
      callback(true);
    } else {
      callback(false);
    }
  }
}

export default LoginStore;
