import { extendObservable } from "mobx";

import { downline } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';

class MyReportsStore {
  constructor(props) {
    extendObservable(this, {
      balance:null,
    });
  }
}

export default MyReportsStore;
