
var endPoint="";
var endPointLogin="";

var devEndPoint = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/admin-panel/";
var devEndPointLogin = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/";

var qaEndPoint = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/admin-panel/"
var qaEndPointLogin = "https://exchange-qa-beapi.sportsit-tech.net/bm-exchange-middle-layer/";

var prodEndPoint = "http://ec2-3-10-60-18.eu-west-2.compute.amazonaws.com:8081/bm-exchange-middle-layer/admin-panel/";
var prodEndPointLogin = "http://ec2-3-10-60-18.eu-west-2.compute.amazonaws.com:8081/bm-exchange-middle-layer/";


if(window.location.hostname.includes('http://qardexchange.bettorlogic.com/') ||window.location.hostname.includes('http://qardexchange.bettorlogic.com/')){
	endPoint = qaEndPoint;
	endPointLogin = qaEndPointLogin;
} else if(window.location.hostname.includes('localhost')){
	endPoint = devEndPoint;
	endPointLogin = devEndPointLogin;
}else if(window.location.hostname.includes('agqa.victoryexch.com')){
	endPoint = qaEndPoint;
	endPointLogin = qaEndPointLogin;
}else if(window.location.hostname.includes('agpromo.victoryexch.com')){
	endPoint = qaEndPoint;
	endPointLogin = qaEndPointLogin;
}else if(window.location.hostname.includes('aggamex.group')){
	endPoint = prodEndPoint;
	endPointLogin = prodEndPointLogin;
}else{
	endPoint = qaEndPoint;
	endPointLogin = qaEndPointLogin;
}


export const login={
	"login": endPointLogin+"user-login",
}

export const downline={
	"getDownline":endPoint+"downline-list/get-downline-list-details",
	"addUser":endPoint+"downline-list/add-user",
	"changeStatus":endPoint+"downline-list/change-status",
	"settleCommission":endPoint+"commission/settle-commission"
}

export const reports={
	"byDownline": endPoint+"my-report/get-myreport-downlist",
	"bymarket": endPoint+"my-report/get-myreport-market",
	"getSports":endPoint+"downline-list/get-betlist-live-sports"
}

export const betList={
	"getBetList":endPoint+"bet-list/get-bet-list-details"
}

export const myaccount={
	"getAccountSummary":endPoint+"my-account/get-account-summary",
	"getAccountStatement":endPoint+"my-account/get-account-statement",
	"getActivityLog":endPoint+"my-account/get-activity-log",
	"getBettingHistoryDetails": endPoint+"my-account/get-betting-history-details",
	"getBettingProfitLossdetails" : endPoint+"my-account/get-betting-profit-loss-details",
	"updateExposureLimit" : endPoint+"my-account/update-exposure",
	"updateCommission" : endPoint+"my-account/update-commission"
}

export const profile ={
	"userProfile" : endPoint+"user-profile",
	"updatePassword" : endPointLogin+"password-update"
}

export const adminMarkets = {
	"getSports" : endPoint+"get-fancy-sports",
	"getLeagues": endPoint+"get-fancy-leagues",
	"getEvents": endPoint+"get-fancy-events",
	"getMarkets": endPoint+"get-fancy-markets",
	"addFancy": endPoint+"add-fancy",
	"addMo": endPoint+"add-match-odds",
	"getMo": endPoint+"get-match-odds",
	"getFancyMarkets": endPoint+"get-fancy-markets",
	"updateSuspension":endPoint+"update-suspension",
	"updateStatus":endPoint+"update-status"
}

export const banking = {
	getBankingDetails: endPoint+"banking/get-banking-details",
	payment:endPoint+"banking/update-banking-details",
	getLogs:endPoint+"banking/get-banking-logs",
	updateExpo:endPoint+"/banking/update-exposure-limit",
	updateCredit: endPoint+"/downline-list/update-credit-limit"
}

export const betSettlement = {
	"getAllBetSettlements":endPoint+"bet-settlements/get-all-bet-settlements",
	"updateSettlements":endPoint+"bet-settlements/update-bet-settlements"
}

export const usersServices = {
	"getUserBalance": endPointLogin+"user-balance"
}
export const betlistlive = {
	"getBetlistLiveDetails":endPoint+"downline-list/get-betlist-live-details"
}

export const riskmanagement = {
	"getPlayers" : endPoint+"risk-management/get-players",
	"getRiskMatchOdds" : endPoint+"risk-management/get-risk-match-odds",
	"getFancy":endPoint+"risk-management/get-fancy",
	"getOther":endPoint+"risk-management/get-other",
	"getMatchOddsList":endPoint+"risk-management/get-match-odds-list",
	"getFancyList":endPoint+"risk-management/get-fancy-list"
}

export const currency = {
	"getCurrency":endPoint+"currency/get-currency-list",
	"updateCurrencyRate":endPoint+"currency/save-currency-conversion",
	"addPointsToAdmin":endPoint+"banking/add-admin-deposit",
	"viewpoints":endPoint+"banking/get-deposit-details",
	"getBetfairPercentage":endPoint+"get-betfair-percentage_details",
	"updateBetfairPercentages":endPoint+"update_betfair-percentages"
}
