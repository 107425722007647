import { extendObservable } from "mobx";
import Validator from 'validatorjs';

import { downline } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, } from "../../../sharedfiles/helper";

class ChangeStatusStore {
  constructor(props) {
    extendObservable(this, {
      downlineStore:{},
      selectedStatus:-1,
      password:"",
      errorMsg:"",
      parentStore:{},
      downlineStore:{}
    });
  }
  /**
  * This method is called when user changes the status.
  * @param {Number} status contain the status id.
  */
  selectStatus(status){
    this.selectedStatus = status;
  }
  /**
  * This method is user to set the password.
  * @param {object} event contains the js object to get the target element value.
  */
  setPassword(event){
    this.password=event.target.value;
    this.errorMsg = '';
  }
  /**
  * This method is used to change the status of the user.
  * @param {Object} userDetails contains user information to which status should change.
  */
  changeStatus(userDetails){
    var rules={
          password:'required|min:8'
        };
    var validation = new Validator({password:this.password}, rules);
    if(validation.passes()){
      var params={
                      "userToken": getUserToken(),
                      "userId":userDetails.userId,
                      "statusCode":this.selectedStatus,
                      "userPassword":this.password
                  }
      apiCaller(downline.changeStatus,'POST',params).then(response => {
        if(response.hasOwnProperty('data') && 
           response.data.hasOwnProperty('isUpdated') && 
           response.data.isUpdated){
            this.parentStore.toggleChangeSatausPopUp({});
            this.downlineStore.refreshData();
        }else{
          if(response && response.hasOwnProperty('data') && 
             response.data.hasOwnProperty('isValidPassword') && !response.data.isValidPassword){
            this.errorMsg="Invalid password"
          }else{
            this.errorMsg="Unable to change status, please try after some time.";
          }
        }
      })
    }else{
      this.errorMsg="Invalid password.";
    }
  }
  /**
  * This method is used to reset the fields.
  */
  resetFields(){
    this.selectedStatus = -1;
    this.password = "";
    this.errorMsg = "";
  }
}

export default ChangeStatusStore;
