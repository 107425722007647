import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TransactionTable from '../transactiontable/transactionTable';
import TransactionTableStore from '../transactiontable/transactionTableStore';
import { getUserData } from "../../../sharedfiles/helper";

const transactionTableStore = new TransactionTableStore();
class TransactionHistory extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // this.props.accountStatementStore.getaccountStatement();
    }
    componentDidMount() { }
    componentWillUnmount() { }
    onChange() {

    }
    render() {
        const { mainLayoutStore, myAccountStore, transactionHistoryStore } = this.props;
        return (
            <div className="RDE-Admin-profileView__accountStatement">
                <div className="RDE-Admin-profileView__transactionHistory">
                    <div className="RDE-sportsPage-leagueHeader">
                        Transaction History
					</div>
                    <TransactionTable transactionTableStore={transactionTableStore} myAccountStore={myAccountStore} mainLayoutStore={mainLayoutStore}/>

                </div>
            </div>
        )
    }
}
export default observer(TransactionHistory);