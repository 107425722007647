import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole,getUserData } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class BettingProfitLossStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole: getUserRole(),
      bettingProfitLoss: [],
      selectedBetStatus: 'Matched',
      fromDate: new Date(),
      toDate: new Date(),
      selectedOption: '',
      // selectedStatus: betStatus[0],
      // betStatusList: betStatus,
      accordionOpenIndex: [0],
      userData:getUserData(),
      userId:"",
      totalProfitLoss:0,
      currency:""
    });
  }

  /**
  * This method is called when user selects status
  * @param {string} status contains the user selected status.
  */
  selectStatus(status) {
    this.selectedStatus = status;
  }

  resetFields(){
    this.dateErrMsg="";
    this.fromDate=new Date();
    this.toDate=new Date();
    this.selectedOption="";
   
  }
  /**
   *	This method is called when user selects the from date.
   * @param {date} selectDate contatins the selected from date.
   */
  selectFromDate(selectedDate) {
    this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate) {
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  getHistory() {
    this.selectedOption = "";
    this.getBettingProfitLoss();
  }
  getBettingProfitLoss() {
    
    this.showErrorMsg = false;
    this.bettingProfitLoss=[];
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);

    var fDate = fromDate.getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
    var tDate = toDate.getFullYear() + "-" + ("0" + (toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + toDate.getDate()).slice(-2);

    if (new Date(fDate) > new Date(tDate)) {
      this.dateErrMsg = "From date should less than to date";
      this.showErrorMsg = true;
      return;
    }
    this.showLoader = true;

    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    if (token.length > 0) {
      var param = {
        "userToken":cookeTocken,
        "playerUserId": this.userId,
        "fromDate":fDate,
        "toDate": tDate,
        "timeInterval":330
      }
      apiCaller(myaccount.getBettingProfitLossdetails, 'POST', param).then(response => {
        var self = this;

        this.showLoader = false;
        this.totalProfitLoss = response.data.totalProfitLoss;
        this.currency = response.data.currency
        if (response && response.data &&response.data.hasOwnProperty('bettingHistory') && response.data.bettingHistory.length > 0) {
          response.data.bettingHistory.map(function(item,index){
            
            item.betDetails.profitLoss=parseFloat( item.betDetails.profitLoss).toFixed(2)
            var obj = item.betDetails;
            item['betPLId'] = index;
            for (var x in obj) {
              item[x] = obj[x];
            }
            item['market'] = item.sportName +' > '+ item.eventName +' > '+ item.marketName;
            self.bettingProfitLoss.push(item);
          })
        }
      });
    }
  }
  
  /**
  * This method is called when user select today or yesterday
  * @param {String} option contains 'today' and 'yesterday' to get data
  */
  selectTodayYesterday(option) {
    this.dateErrMsg="";
    this.selectedOption = option;
    if (option === "today") {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getBettingProfitLoss();
    } else if (option === "yesterday") {
      var yDate = new Date();
      var yDay = yDate.getDate() - 1;
      var yMonth = yDate.getMonth() + 1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth + "/" + yDay + "/" + yYear);
      this.toDate = new Date();
      this.getBettingProfitLoss();
    }
  }

}

export default BettingProfitLossStore;
