import { extendObservable } from "mobx";

import { currency } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../sharedfiles/helper'

const re = /^[0-9\b]+$/;
class AddPointsToAdminStore {
  constructor(props) {
    extendObservable(this, {
      amount: "",
      rate: "",
      reference: "",
      errorMsg: '',
    });
  }
  /**
  * This method is called when rate is changed.
  * @param {String} field contains the field name.
  * @param {Object} e contains the js object with target value. 
  */
  filedChange(field, e) {
    this.errorMsg = '';
    switch (field) {
      case 'amount':
        if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
          this.amount = e.target.value;
        }
        break;
      case 'rate':
        if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
          this.rate = e.target.value;
        }
        break;
      case 'reference':
        this.reference = e.target.value;
        break;
      default:
        console.log('default')
    }
  }
  /**
  * This method is used to call the add points service.
  */
  addPoints() {
    const isValidated = this.validateFields();
    if (isValidated) {
    let transPoints=((this.rate/ 100) * this.amount);
      var params = {
        "userLoginToken": getUserToken(),
        "amount": this.amount,
        "conversionRate": this.rate,
        "transPoints": transPoints,
        "reference": this.reference
      }
      apiCaller(currency.addPointsToAdmin, 'POST', params).then(response => {
        this.showLoader = false;
        if (response && response.data && response.data === "Admin deposit in points saved successfully") {
          this.errorMsg = response.data;
          this.clearFields();
        }
        else {
          if (response && response.data) {
            this.errorMsg = response.data
          }
        }
      });
    }
  }

  /**
  * This method is used to validate the fields:
  */
  validateFields() {
    if (this.amount === '' || Number(this.amount) < 1 ||
      this.rate === '' || Number(this.rate) === 0 ||
      this.reference === '') {
      this.errorMsg = "Please enter all the fields."
      return false
    } else {
      return true;
    }
  }
  /**
  * this method is used to clear the fileds.
  */
  clearFields() {
    this.amount = '';
    this.rate = '';
    this.reference = '';
  }
}

export default AddPointsToAdminStore;
