import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class ActivityLogStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole: getUserRole(),
      activityLog: [],
      showLoader: false,
      showErr: false,
      userId: ''
    });
  }


  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getactivityLog(fromComponent) {
    this.showLoader = true;
    this.showErr = false;
    this.activityLog = [];
    let userId = fromComponent === 'downline' ? this.userId : ''
    apiCaller(`${myaccount.getActivityLog}?userToken=${getUserToken()}&userId=${userId}`, 'GET').then(response => {
      // apiCaller(myaccount.getActivityLog+"?userToken=7203dcfd-dde2-44ee-ae85-7218096b9579",'GET').then(response => {
      if (response && response.data && response.data.hasOwnProperty('activityLog') && response.data.activityLog.length > 0) {
        this.activityLog = response.data.activityLog;
        this.showLoader = false;
      }
      else {
        this.showLoader = false;
        this.showErr = true;
      }
    })
  }

}

export default ActivityLogStore;
