import React, { Component } from 'react';
import { observer } from 'mobx-react';
// import logo from '../../assets/images/american-exchange-logo.svg';
import logo from '../../assets/images/xchange-logo.svg';
import cookie from 'react-cookies';

import { getAuthenticationNumber } from "../../sharedfiles/helper";

class Login extends Component {
  componentWillMount() {
    this.props.loginStore.authNumber = getAuthenticationNumber();
    if (cookie.load('UserToken') && cookie.load('UserToken').length > 0) {
      this.navigateToHome();
    }
  }
  componentDidMount() { }
  componentWillUnmount() { }
  navigateToHome() {
    this.props.history.push('/downline');
  }
  render() {
    const { loginStore } = this.props;
    return (
      <div className="RD-login-dialog">
        <div className="modal" style={{ "display": "block" }}>
          <div className="RDE-centered-modal RDE-loginSignup-modal">
            <div className="RD-loginLogo">
              <img src={logo} className="RDE-logo" />
            </div>
            <div className="RDE-loginSignup-modal-content">
              <div className="RDE-loginSignup-form">
                <div className="RDE-loginSignupBox RDE-logIn">
                  <div className="">
                    <div className="RDE-loginSignupBox-header">Login</div>
                    <div className="RDE-loginSignupBox-content">
                      <div className="RDE-formGroup"><input type="text" className="RDE-formControl"
                        placeholder="Email" value={loginStore.email} onChange={loginStore.onLoginFiledChange.bind(loginStore, 'email')} /></div>
                      <div className="RDE-formControl-errorMessage"></div>
                      <div className="RDE-formGroup"><input type="password" className="RDE-formControl"
                        placeholder="Password" value={loginStore.password} onChange={loginStore.onLoginFiledChange.bind(loginStore, 'password')} /></div>
                      <div className="RDE-formControl-errorMessage"></div>
                      <div className="RDE-formGroup RDE-captchaBox"><input type="text" className="RDE-formControl"
                        placeholder="Validation" value={loginStore.reAuthNumber} onChange={loginStore.onLoginFiledChange.bind(loginStore, 'reAuthNumber')} maxLength={4} onKeyPress={(event)=>loginStore.searchQuery(event)}/>
                        <div className="RDE-captchaTxt RDE-skew"><span className="RDE-skewTxt">{loginStore.authNumber}</span></div>
                      </div>
                      <div className="RDE-formControl-errorMessage">{loginStore.errorMsg}</div>
                      <div className="RDE-formGroup text-center"><button
                        className="btn RDE-btn-primary RDE-btn-large" onClick={loginStore.login.bind(loginStore, this.navigateToHome.bind(this))}>Login</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default observer(Login);
