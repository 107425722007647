import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, Dropdown } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from 'react-bootstrap-table-next';

import { getUserData } from "../../../sharedfiles/helper";
// import { bettingHistory } from '../../../sharedfiles/EndpointConfig';

class BettingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: [0, 1], expandRowData: {} };

    }
    componentWillMount() {
        this.props.bettingHistoryStore.resetFields();
        if (this.props.myAccountStore.userId && this.props.myAccountStore.userId !== null && (this.props.myAccountStore.userId !== this.props.bettingHistoryStore.userId)) {
            this.props.bettingHistoryStore.userId = this.props.myAccountStore.userId;
            this.props.bettingHistoryStore.getBetHistory();
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.myAccountStore.userId !== this.props.bettingHistoryStore.userId) {
            this.props.bettingHistoryStore.userId = this.props.myAccountStore.userId;
            this.props.bettingHistoryStore.getBetHistory();
        }
    }
    componentDidMount() { }
    componentWillUnmount() { }
    onChange() {

    }
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.state.expandRowData = this.props.bettingHistoryStore.bettingHistoryData[rowIndex].betDetails;
        } else {
            this.state.expandRowData = {};
        }
    }
    render() {
        const { mainLayoutStore, myAccountStore, bettingHistoryStore } = this.props;
        const column = [{
            dataField: 'immediateUserName',
            text: bettingHistoryStore.userRole === 1 ? 'Sup ID' : bettingHistoryStore.userRole === 2 ? 'MA ID' : ''
        }, {
            dataField: 'immediateUserName',
            text: bettingHistoryStore.userRole !== 3 ? bettingHistoryStore.userRole === 1 ? 'MA ID' : bettingHistoryStore.userRole === 2 ? 'PL ID' : '' : ''
        }, {
            dataField: 'betId',
            text: 'Bet ID'
        }, {
            dataField: 'playerId',
            text: 'PL ID'
        }, {
            dataField: 'market',
            text: 'Market'
        }, {
            dataField: 'selection',
            text: 'Selection'
        }, {
            dataField: 'type',
            text: 'Type'
        }, {
            dataField: 'betPlaced',
            text: 'Bet Placed'
        }, {
            dataField: 'oddReq',
            text: 'Odds req.'
        }, {
            dataField: 'stake',
            text: 'Stake'
        }, {
            dataField: 'avgOddsMatched',
            text: 'Avg. Odds matched'
        }, {
            dataField: 'profitLoss',
            text: 'Profit/Loss'
        }];
        var columns = []
        column.map(function (obj) {
            if (obj.text !== '') {
                columns.push(obj)
            }
        })

        const expandRow = {
            renderer: row => (<td colspan="10">
                <div class="RDE-DB-table-accordianBody">
                    <div class="RDE-DB-table">
                        <table class="RDE-DB-table-default">
                            <thead>
                                <tr>
                                    <td width="30%">Bet taken</td>
                                    <th>Odds req.</th>
                                    <th>Stake</th>
                                    <th>Liability</th>
                                    <th class="text-right">Odds matched</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="30%">{row.betDetails.betTaken}</td>
                                    <td>{row.betDetails.oddsReq}</td>
                                    <td>{row.betDetails.stake}</td>
                                    <td><span className="RDE-DB-txtHighlight">{row.betDetails.liability}</span></td>
                                    <td className="text-right">{row.betDetails.oddsMatched}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>),
            onExpand: this.handleOnExpand
        };
        return (
            <div className="RDE-Admin-profileView__bettingHistory">
                <div className="RDE-sportsPage-leagueHeader">
                    Betting History
                </div>
                <div className="RDE-DB-myBets-filters">
                    <div className="RDE-DB-myBets-filters__left"><span
                        className="RDE-DB-myBets-filters__label">Bet status :</span>
                        <DropdownButton className="RDE-DB-dropdownDefault dropdown" id="dropdown-basic-button" title={bettingHistoryStore.selectedStatus.name}>
                            <ul>
                                {
                                    bettingHistoryStore.betStatusList && bettingHistoryStore.betStatusList.map((status, index) => {
                                        return <li onClick={bettingHistoryStore.selectStatus.bind(bettingHistoryStore, status)}>
                                            <Dropdown.Item eventKey={status.id}>{status.name}</Dropdown.Item>
                                        </li>
                                    })
                                }
                            </ul>
                        </DropdownButton>
                        <div className="RDE-space"></div><span
                            className="RDE-DB-myBets-filters__label">Period From
                            :</span>
                        <div className="RDE-DB-myBets-filters__datePicker">
                            <DatePicker selected={bettingHistoryStore.fromDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={bettingHistoryStore.selectFromDate.bind(bettingHistoryStore)}
                                className="RDE-DB-datePickerInput"
                                maxDate={new Date()} />
                            <span className="RDE-DB-myBets-filters__label">To :</span>
                            <div className="react-datepicker-wrapper">
                                <DatePicker selected={bettingHistoryStore.toDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={bettingHistoryStore.selectToDate.bind(bettingHistoryStore)}
                                    className="RDE-DB-datePickerInput"
                                    maxDate={new Date()} />
                            </div>
                            <span className="RDE-DB-datePickerErrorMessage">{bettingHistoryStore.dateErrMsg}</span>
                        </div>
                        <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={bettingHistoryStore.getHistory.bind(bettingHistoryStore)}>Get
                            History</button>
                    </div>
                    <div className="RDE-DB-myBets-filters__right">
                        <ul className="RDE-DB-myBets-filters__todayYesterdayList">
                            <li className={bettingHistoryStore.selectedOption === "today" ? "active" : ""}
                                onClick={bettingHistoryStore.selectTodayYesterday.bind(bettingHistoryStore, 'today')}>Today</li>
                            <li className={bettingHistoryStore.selectedOption === "yesterday" ? "active" : ""}
                                onClick={bettingHistoryStore.selectTodayYesterday.bind(bettingHistoryStore, 'yesterday')}>From Yesterday</li>
                        </ul>
                    </div>
                </div>
                <div className="space-5"></div>
                <div className="RDE-DB-myBets__betHistory">
                    <div className="RDE-DB-table RDE-betHistory-tableScroll">
                        <BootstrapTable id="RDE-DB-table-default" className="RDE-DB-table-default RDE-DB-table-cuurentBets settled" keyField="betId" data={bettingHistoryStore.bettingHistoryData} columns={columns} expandRow={expandRow} />
                        {(!bettingHistoryStore.bettingHistoryData || (bettingHistoryStore.bettingHistoryData && bettingHistoryStore.bettingHistoryData.length === 0)) ? <div className="RDE-noDataMessageBox">
                            Currently data is not available for your selection.
                        </div> : ''}
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(BettingHistory);