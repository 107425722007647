import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


class AddPointsToAdmin extends Component {
    componentWillMount() {
        this.props.addPointsToAdminStore.errorMsg = "";
    }
    componentDidMount() { }
    componentWillUnmount() {
this.props.addPointsToAdminStore.clearFields();
     }
    render() {
        const { addPointsToAdminStore } = this.props;
        return (
            <div className="RDE-DB-Admin-convertToOtherCurrencies">
                <div className="RDE-sportsPage-leagueHeader">
                    Add Points to Admin Base Currency
                </div>
                <div className="grid-center mb-10">
                    <div className="col-2">
                        <div>
                            <span>Amount in HKD</span>
                            <span className="RDE-DB-colon">:</span>
                        </div>
                        <div>
                            <input type="text" className="RDE-formControl"
                                placeholder=""
                                value={addPointsToAdminStore.amount}
                                onChange={addPointsToAdminStore.filedChange.bind(addPointsToAdminStore, 'amount')} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div>
                            <span>Rate in %</span>
                            <span className="RDE-DB-colon">:</span>
                        </div>
                        <div>
                            <input type="text" className="RDE-formControl"
                                placeholder=""
                                value={addPointsToAdminStore.rate}
                                onChange={addPointsToAdminStore.filedChange.bind(addPointsToAdminStore, 'rate')} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div>
                            <span>HKD Points</span>
                            <span className="RDE-DB-colon">:</span>
                        </div>
                        <div>
                            <input type="text" className="RDE-formControl disabled" placeholder=""
                                value={((addPointsToAdminStore.rate/ 100) * addPointsToAdminStore.amount)} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div>
                            <span>Reference</span>
                            <span className="RDE-DB-colon">:</span>
                        </div>
                        <div>
                            <input type="text" className="RDE-formControl"
                                placeholder=""
                                value={addPointsToAdminStore.reference}
                                onChange={addPointsToAdminStore.filedChange.bind(addPointsToAdminStore, 'reference')} />
                        </div>
                    </div>
                </div>
                <div className="grid-noGutter-center">
                    <div className="col-8 text-right">
                        <button className="btn RDE-btn-primary  RDE-btn-auto" onClick={addPointsToAdminStore.addPoints.bind(addPointsToAdminStore)}>
                            Add Points</button>
                    </div>
                </div>
                <div className="text-center RDE-DB-errorMessage">{addPointsToAdminStore.errorMsg}</div>
            </div>
        )
    }
}
export default observer(AddPointsToAdmin);