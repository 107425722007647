import React, { Component } from 'react';
import { observer } from 'mobx-react';
import dataLoader from '../../../assets/images/loader-orange.svg';

class ActivityLog extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        if (this.props.myAccountStore.userId && this.props.myAccountStore.userId !== null && (this.props.myAccountStore.userId !== this.props.activityLogStore.userId)) {
            this.props.activityLogStore.userId = this.props.myAccountStore.userId;
            this.props.activityLogStore.getactivityLog(this.props.fromComponent);
        }

    }
    componentWillUnmount() { }

    componentWillReceiveProps(nextProps) {
        if (this.props.myAccountStore.userId !== this.props.activityLogStore.userId) {
            this.props.activityLogStore.userId = this.props.myAccountStore.userId;
            this.props.activityLogStore.getactivityLog(this.props.fromComponent);
        }
    }
    render() {
        const { activityLogStore } = this.props;
        return (
            <div className="RDE-Admin-profileView__activityLog">
                <div className="RDE-sportsPage-leagueHeader">
                    Activity Log
                </div>
                <div className="RDE-DB-table mb-10 RDE-activityLog-tableScroll">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>Login Date/Time</th>
                                <th>Login Status</th>
                                <th>IP Address</th>
                                <th>ISP</th>
                                <th>City/State/Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activityLogStore.activityLog && activityLogStore.activityLog.map(function (item, index) {
                                return (
                                    <tr key={'activitylog_' + index}>
                                        <td>{item.loginDateTime}</td>
                                        <td>
                                            <span className="green">{item.loginStatus}</span>
                                        </td>
                                        <td>{item.ipAddress}</td>
                                        <td>{item.isp}</td>
                                        <td>{item.city}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {activityLogStore.showLoader ? <div className="RDE-loader">
                        <img src={dataLoader} alt="loading...!" />
                    </div> : ''}
                    {activityLogStore.showErr ? <div className="RDE-noDataMessageBox">
                        Currently data is not available for your selection.
                    </div> : ''}
                </div>
            </div>
        )
    }
}
export default observer(ActivityLog);