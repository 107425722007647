import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, Dropdown } from "react-bootstrap";
import close from '../../../assets/images/modal-close.svg';
import { getUserRole } from '../../../sharedfiles/helper';

class AddUser extends Component {
  componentWillMount() {
    this.props.addUserStore.mainLayoutStore = this.props.mainLayoutStore;
    this.props.addUserStore.downlineStore = this.props.downlineStore;
    this.props.addUserStore.resetFields();
    this.props.addUserStore.loggedInRole = getUserRole();
    if( this.props.addUserStore.loggedInRole==1){
      this.props.addUserStore.setDefaultTimezone()
    }
    
  }
  componentDidMount() { 
    if(this.props.mainLayoutStore.userData.timeZone!==this.props.addUserStore.selectedTimeZone && this.props.addUserStore.loggedInRole!==1 ){
    this.props.addUserStore.setDefaultTimezone()
  }
  }
  componentWillUnmount() { }
  componentWillReceiveProps(nextprops){
    if(this.props.mainLayoutStore.userData.timeZone!==this.props.addUserStore.selectedTimeZone && this.props.addUserStore.loggedInRole!==1 ){
    this.props.addUserStore.setDefaultTimezone()
    }
  }
  render() {
    const { addUserStore, mainLayoutStore } = this.props;
    return (
      <div className="RDE-addUser">
        <div className="modal-header">
          {addUserStore.showStep===1?<div className="RDE-modal-header__txt">{addUserStore.loggedInRole === 1? "Add Super Agent" : addUserStore.loggedInRole === 2 ? "Add Master Agent" : addUserStore.loggedInRole === 3 ? "Add Player" : ""}</div>:
          <div className="RDE-modal-header__txt">Deposit</div>}
          <img src={close} class="RDE-modal-closeIcon" onClick={mainLayoutStore.toggleAddUser.bind(mainLayoutStore)} />
        </div>
        <div className="modal-body">
          {addUserStore.showStep===1?<div className="">
              <div className="RDE-DB-infoBox">
                <div className="RDE-DB-infoBox__content RDE-DB-formBox">
                  <div className="grid-equalHeight">
                    <div className="col-6">
                      <div className="">
                        <div className="">
                          <span>First Name</span>
                          <span className="RDE-DB-colon">:</span>
                        </div>
                        <div className="">
                          <input type="text" className="RDE-formControl" value={addUserStore.addUserFields.fName} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'fName')} />
                        </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.fName}</div>
                      </div>
                    </div>
                    <div className="col-6">
                        <div className="">
                          <div className="">
                            <span>Last Name</span>
                            <span className="RDE-DB-colon">:</span>
                          </div>
                          <div className="">
                            <input type="text" className="RDE-formControl" value={addUserStore.addUserFields.lName} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'lName')} />
                          </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.lName}</div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-equalHeight">
                    <div className="col-6">
                        <div className="">
                          <div className="">
                            <span>E-mail</span>
                            <span className="RDE-DB-colon">:</span>
                          </div>
                          <div className="">
                            <input type="text" className="RDE-formControl" value={addUserStore.addUserFields.email} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'email')} />
                          </div>
                          <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.email}</div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="">
                          <div className="">
                            <span>Phone</span>
                            <span className="RDE-DB-colon">:</span>
                          </div>
                          <div className="">
                            <input type="text" className="RDE-formControl" value={addUserStore.addUserFields.phoneNumber} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'phoneNumber')} />
                          </div>
                          <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.phoneNumber}</div>
                        </div>
                    </div>
                  </div>
                  <div className="grid-equal-height">
                    <div className="col-6">
                      <div className="">
                        <div className="">
                          <span>Username</span>
                          <span className="RDE-DB-colon">:</span>
                        </div>
                        <div className=""><form autoComplete="off">
                          <input type="text" className="RDE-formControl" value={addUserStore.addUserFields.userName} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'userName')} />
                          </form> </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.userName}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="">
                          <span>Password</span>
                          <span className="RDE-DB-colon">:</span>
                        </div>
                        <div className=""><form autoComplete="off">
                          <input type="password" className="RDE-formControl" value={addUserStore.addUserFields.password} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'password')} />
                       </form> </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.password}</div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-equal-height">
                    <div class="col-6">
                      <div className="">
                        <div className="">
                          <span>Confirm Password</span>
                          <span className="RDE-DB-colon">:</span>
                        </div>
                        <div className=""><form autoComplete="off">
                          <input type="password" className="RDE-formControl" value={addUserStore.addUserFields.confirmPassword} placeholder="" onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'confirmPassword')} />
                       </form> </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.confirmPassword}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="">
                          <span>Time Zone</span>
                          <span className="RDE-DB-colon">:</span>
                                      </div>
                      <div className="">
                        <DropdownButton disabled={addUserStore.loggedInRole!==1} className="RDE-DB-dropdownDefault" id="dropdown-basic-button" title={
                        <span className="truncate">{addUserStore.selectedTimeZone}</span>
                        }>
                        <ul>
                          {
                          addUserStore.timeZoneValues.map((item, index) => {
                            return <li key={"timezones_" + index} onClick={addUserStore.selectTimeZone.bind(addUserStore, item)}><Dropdown.Item >{item.name}</Dropdown.Item></li>
                          })
                          }
                        </ul>
                        </DropdownButton>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.timeZone}</div>
                      </div>
                    </div>
                    </div>
                 </div>
                <div className="grid-equalHeight">
					        <div className="col-12">
                    <div className="">
                      <div className="">
                        <span>Commission
                          <small>(should be between 1 to 100 %)</small>
                          </span>
                        <span className="RDE-DB-colon">:</span>
                      </div>
                      <div className="">
                        <input type="text" className="RDE-formControl" placeholder="" value={addUserStore.addUserFields.commission} onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'commission')} />
                      </div>
                      <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.commission}</div>
                    </div>
                  </div>
					        <div className="col-12">
                  {addUserStore.loggedInRole ===1?<div className="">
                      <div className="">
                        <span>Currency</span>
                        <span className="RDE-DB-colon">:</span>
                      </div>
                      <div className="">
                        <DropdownButton className="RDE-DB-dropdownDefault" id="dropdown-basic-button" title={
                          <span className="truncate">{addUserStore.selectedCurrency.code}</span>
                        }>
                          <ul>
                            {
                              addUserStore.currencyList.map((item, index) => {
                                return <li key={"currency_" + index} onClick={addUserStore.selectCurrency.bind(addUserStore, item)}><Dropdown.Item >{item.code}</Dropdown.Item></li>
                              })
                            }
                          </ul>
                        </DropdownButton>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.currency}</div>
                      </div>
                    </div>:""}
                  </div>
                 
              </div>
                <div className="grid-equalHeight">
                    <div className="col-12">
                      <div className="">
                        <div className="">
                          <span>Fancy Market Commission(%)</span>
                          <span className="RDE-DB-colon">:</span>
                        </div>
                        <div className="">
                          <input type="text" className="RDE-formControl" placeholder="" value={addUserStore.addUserFields.fancyCommission} onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'fancyCommission')} />
                        </div>
                        <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.fancyCommission}</div>
                      </div>
                    </div>
                </div>
                <div className="grid-equalHeight">
				            <div className="col-6">
                    {false && addUserStore.loggedInRole === 3 ? 
                    <div className="">
                      <div className="">
                        <span>Exposure</span>
                        <span className="RDE-DB-colon">:</span>
                      </div>
                      <div className="">
                        <input type="text" className="RDE-formControl" placeholder="" value={addUserStore.addUserFields.exposure} onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'exposure')} />
                      </div>
                      <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.exposure}</div>
                    </div> : ""}
                  </div>
                </div>
                </div>
            </div>
          </div>:''}
          {addUserStore.showStep===2?<div class="grid-equalHeight ">
            <div class="col-6">
              <div class="RDE-DB-infoBox">
                  <div class="RDE-DB-infoBox__content RDE-DB-formBox">
                    <div class="">
                      <div class="">
                        <span>Deposit</span>
                        <span class="RDE-DB-colon">:</span>
                      </div>
                      <div class="">
                        <input type="text" class="RDE-formControl" value={addUserStore.addUserFields.depositAmount} onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'depositAmount')}/>
                      </div>
                      <div class="RDE-formControl-errorMessage">{addUserStore.addUserFieldsErrors.depositAmount}</div>
                    </div>
                    {/*<div class="">
                      <div class="">
                        <span>Password</span>
                        <span class="RDE-DB-colon">:</span>
                      </div>
                      <div class="">
                        <input type="password" class="RDE-formControl" />
                      </div>
                      <div class="RDE-formControl-errorMessage"></div>
                    </div>*/}
                  </div>
              </div>
            </div>
            <div class="col-6">
              <div class="RDE-DB-infoBox">
                  <div class="RDE-DB-infoBox__content RDE-DB-formBox">
                    <div class="">
                      <div class="">
                        <span>Exposure limit</span>
                        <span class="RDE-DB-colon">:</span>
                      </div>
                      <div class="">
                        <input type="text" class="RDE-formControl" value={addUserStore.addUserFields.exposureLimit} onChange={addUserStore.onAddUserFieldChange.bind(addUserStore, 'exposureLimit')}/>
                      </div>
                      <div class="RDE-formControl-errorMessage text-center"></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>:''}
          <div className="RDE-formControl-errorMessage text-center">{addUserStore.creationErr}</div>
        </div>
        <div className="RDE-modal-footer modal-footer ">
          {addUserStore.showStep===1?<div className="grid-center-noGutter w-100 ">
              <div className="col-3">
                <button className="btn RDE-btn-primary" onClick={addUserStore.goToNextStep.bind(addUserStore)}>Next</button>
              </div>
          </div>:
          <div className="grid-center-noGutter w-100">
            <div className="col-3 text-right RDE-btnSpace">
              <button className="btn RDE-btn-primary" onClick={mainLayoutStore.toggleAddUser.bind(mainLayoutStore)}>Cancel</button>
            </div>
            <div className="col-3 text-right RDE-btnSpace">
              <button className="btn RDE-btn-primary" onClick={addUserStore.prevClick.bind(addUserStore)}>prev</button>
            </div>
            <div className="col-4 text-left RDE-btnSpace">
              <button className="btn RDE-btn-primary" onClick={addUserStore.addUser.bind(addUserStore)}>Create & Deposit</button>
            </div>
          </div>}
        </div>
        
      </div>
    )
  }
}
export default observer(AddUser);
