import React, { Component } from 'react';
import { observer } from 'mobx-react';
import "react-datepicker/dist/react-datepicker.css";

class AddPointsToBase extends Component {
    componentWillMount() {
        this.props.addPointsToBaseStore.getCurrency();
        //  this.props.addPointsToBaseStore.showErr='';
    }
    componentDidMount() { }
    componentWillUnmount() { }
    render() {
        const { addPointsToBaseStore } = this.props;
        return (
            <div className="RDE-DB-Admin-addPointsToBaseCurrency">
                <div className="RDE-sportsPage-leagueHeader">
                    Add Points to Base Currency
                </div>
                <div className="grid-noGutter-center">
                    <div className="col-8">
                        <div className="grid-noGutter mb-10">
                            {/* <div className="col-4">
                                <span>Points in HKD:</span>
                                {/* <input type="text" className="RDE-formControl disabled" placeholder="" value="10000"/> 
                                <label><b>10000</b></label>
                            </div> */}
                        </div>

                        <div className="RDE-DB-table mb-10">
                            <table className="RDE-DB-table-default">
                                <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>Rate</th>
                                        <th>Previous Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        addPointsToBaseStore.currencyList.map((item, index) => {
                                            return <tr key={"addPointsToBase_" + index}>
                                                <td>{item.currencyCode}</td>
                                                <td><input type="text" className="RDE-formControl" value={item.rate} onChange={addPointsToBaseStore.rateChange.bind(addPointsToBaseStore, index)} /></td>
                                                <td>{item.conversionRate}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="grid-noGutter text-right">
                            <div className="col-12">
                                <button className="btn RDE-btn-primary RDE-btn-auto" onClick={addPointsToBaseStore.saveCurrencyRate.bind(addPointsToBaseStore)}>Save</button>
                            </div>
                        </div>
                        {addPointsToBaseStore.showErr && <div className= "RDE-DB-errorMessage text-center">{addPointsToBaseStore.showErr}</div>}
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(AddPointsToBase);