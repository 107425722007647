import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getUserData } from "../../../sharedfiles/helper";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from 'react-bootstrap-table-next';
class BettingProfitLoss extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: [0, 1], expandRowData: {} };
    }
    componentWillMount() {
        this.props.bettingProfitLossStore.resetFields();
        if (this.props.myAccountStore.userId && this.props.myAccountStore.userId !== null && (this.props.myAccountStore.userId !== this.props.bettingProfitLossStore.userId)) {
            this.props.bettingProfitLossStore.userId = this.props.myAccountStore.userId;
            this.props.bettingProfitLossStore.getBettingProfitLoss();
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.myAccountStore.userId !== this.props.bettingProfitLossStore.userId) {
            this.props.bettingProfitLossStore.userId = this.props.myAccountStore.userId;
            this.props.bettingProfitLossStore.getBettingProfitLoss();
        }
    }
    componentDidMount() { }
    componentWillUnmount() { }
    onChange() {

    }
    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.state.expandRowData = this.props.bettingProfitLossStore.bettingProfitLoss[rowIndex].betDetails;
        } else {
            this.state.expandRowData = {};
        }
        //  console.log(this.state.expandRowData,"rowData")
    }
    render() {
        const { bettingProfitLossStore } = this.props;
        const columns = [{
            text: 'Market',
            dataField: "market"
        }, {
            dataField: 'selection',
            text: 'Selection'
        }, {
            dataField: 'startTime',
            text: 'Start Time'
        }, {
            dataField: 'settledDate',
            text: 'Settled Date'
        }, {
            dataField: 'profitLoss',
            text: 'Profit/Loss'
        }];
        const expandRow = {
            renderer: row => (
                <div className="RDE-DB-table-accordianBody">
                    <div className="RDE-DB-table">
                        <table className="RDE-DB-table-default">
                            <thead>
                                <tr>
                                    {/* <td width="10%">&nbsp;</td> */}
                                    <th>Bet ID</th>
                                    <th>Selection</th>
                                    <th>Odds</th>
                                    <th>Stake</th>
                                    <th>Type</th>
                                    <th className="text-right">Placed</th>
                                    <th className="text-right">Profit/Loss</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <td width="10%">&nbsp;</td> */}
                                    <td>{row.betDetails.betId}</td>
                                    <td>{row.betDetails.selection}</td>
                                    <td>{row.betDetails.odds}</td>
                                    <td>{row.betDetails.stake}</td>
                                    <td>{row.betDetails.type}</td>
                                    <td className="text-right">{row.betDetails.betPlaced}</td>
                                    <td className="text-right">
                                        <span className={Math.sign(row.betDetails.profitLoss) == -1 ? "RDE-DB-txtHighlight RDE-DB-errorMessage" : "RDE-DB-txtHighlight RDE-DB-successMessage"}>{parseFloat(row.betDetails.profitLoss).toFixed(2)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td width="10%">&nbsp;</td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                        <div>Total Stakes</div>
                                        <div><b>Back</b> subtotal</div>
                                        <div><b>Lay</b> subtotal</div>
                                        <div>Market subtotal</div>
                                    </td>
                                    <td className="text-right">
                                        <div>{this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals.totalStake && parseFloat(this.state.expandRowData.profitLossSubTotals.totalStake).toFixed(2)}</div>
                                        <div><span className="RDE-DB-txtHighlight">{this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals.backSubTotal && this.state.expandRowData.profitLossSubTotals.backSubTotal ? parseFloat(this.state.expandRowData.profitLossSubTotals.backSubTotal).toFixed(2) : '---'}</span></div>
                                        <div>{this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals.laySubTotal ? parseFloat(this.state.expandRowData.profitLossSubTotals.laySubTotal).toFixed(2) : '---'}</div>
                                        <div><span className="RDE-DB-txtHighlight">{this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals.marketSubTotal ? parseFloat(this.state.expandRowData.profitLossSubTotals.marketSubTotal).toFixed(2) : '---'}</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td width="10%">&nbsp;</td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                        <div className="">Net Market Total</div>
                                    </td>
                                    <td className="text-right">
                                        <div className=""><span className="RDE-DB-txtHighlight">({this.state.expandRowData.profitLossSubTotals && this.state.expandRowData.profitLossSubTotals ? parseFloat(this.state.expandRowData.profitLossSubTotals.netMarketTotal).toFixed(2) : '---'})</span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>),
            onExpand: this.handleOnExpand
        };
        return (
            <div className="RDE-Admin-profileView__bettingProfitLoss">
                <div className="RDE-sports-nav-tabs">
                    <ul className="RDE-sports-nav-tabs-menu">
                        <li>
                            <div className="RDE-sports-nav-tabs-menu__item active">
                                <span className="RDE-skewTxt">Exchange</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="space-5"></div>
                <div className="RDE-DB-myBets-filters">
                    <div className="RDE-DB-myBets-filters__left"><span
                        className="RDE-DB-myBets-filters__label">Period From :</span>
                        {/* <div className="RDE-DB-myBets-filters__datePicker">
                            <div className="react-datepicker-wrapper">
                                <div className="react-datepicker__input-container"><input type="text"
                                    className="RDE-DB-datePickerInput" value="17/05/2019" /></div>
                            </div><span className="RDE-DB-myBets-filters__label">To :</span>
                            <div className="react-datepicker-wrapper">
                                <div className="react-datepicker__input-container"><input type="text"
                                    className="RDE-DB-datePickerInput" value="17/05/2019" /></div>
                            </div><span className="RDE-DB-datePickerErrorMessage"></span>
                        </div> */}
                        <div className="RDE-DB-myBets-filters__datePicker">
                            <DatePicker selected={bettingProfitLossStore.fromDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={bettingProfitLossStore.selectFromDate.bind(bettingProfitLossStore)}
                                className="RDE-DB-datePickerInput"
                                maxDate={new Date()} />
                            <span className="RDE-DB-myBets-filters__label">To :</span>
                            <div className="react-datepicker-wrapper">
                                <DatePicker selected={bettingProfitLossStore.toDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={bettingProfitLossStore.selectToDate.bind(bettingProfitLossStore)}
                                    className="RDE-DB-datePickerInput"
                                    maxDate={new Date()} />
                            </div>
                            <span className="RDE-DB-datePickerErrorMessage">{bettingProfitLossStore.dateErrMsg}</span>
                        </div>
                        <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={bettingProfitLossStore.getHistory.bind(bettingProfitLossStore)}>Get
                            P&amp;L</button>
                    </div>
                    <div className="RDE-DB-myBets-filters__right">
                        <ul className="RDE-DB-myBets-filters__todayYesterdayList">
                            {/* <li className="active">Just For Today</li>
                            <li className="">From Yesterday</li> */}
                            <li className={bettingProfitLossStore.selectedOption === "today" ? "active" : ""}
                                onClick={bettingProfitLossStore.selectTodayYesterday.bind(bettingProfitLossStore, 'today')}>Today</li>
                            <li className={bettingProfitLossStore.selectedOption === "yesterday" ? "active" : ""}
                                onClick={bettingProfitLossStore.selectTodayYesterday.bind(bettingProfitLossStore, 'yesterday')}>From Yesterday</li>
                        </ul>
                    </div>
                </div>
                <div className="space-10"></div>
                <div className="grid-middle-noGutter">
                    <div className="col-6">
                        <span><b>Total P/L: {bettingProfitLossStore.currency}</b> <span className="RDE-DB-txtHighlight"><b>({bettingProfitLossStore.totalProfitLoss})</b></span></span>
                    </div>
                    <div className="col-6 text-right">
                        <span className="RDE-DB-txtHighlight"><b>({bettingProfitLossStore.totalProfitLoss})</b></span>
                    </div>
                </div>
                <div className="RDE-DB-table RDE-bettingProfitLoss-tableScroll">
                    <BootstrapTable id="RDE-DB-table-default" className="RDE-DB-table-default RDE-DB-table-cuurentBets settled" keyField="betPLId" data={bettingProfitLossStore.bettingProfitLoss} columns={columns} expandRow={expandRow} />
                </div>

                {(!bettingProfitLossStore.bettingProfitLoss || (bettingProfitLossStore.bettingProfitLoss && bettingProfitLossStore.bettingProfitLoss.length === 0)) ? <div className="RDE-noDataMessageBox">Currently data is not available for your
                    selection.</div> : ''}
            </div>
        )
    }
}
export default observer(BettingProfitLoss);