import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class AccountSummaryStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole:"",
      userData : "",
      userId : "",
      userWallet : {},
      userProfileDetails : {},
      showChangePassword:false,
      showLoader:false,
      showChangeExposureLimit:false,
      showChangeCommission :false
    });
    //this.userId = this.userData.userId;
  }


  /**
  * This method is used to get the Account summary data.
  */
  getAccountSummary(){ 
  this.showLoader=true;
    apiCaller(myaccount.getAccountSummary+"?userToken="+getUserToken()+"&userId="+this.userId,'GET').then(response => {
      // apiCaller(myaccount.getAccountSummary+"?userToken=c42659d3-a0d8-42f0-8e85-2b312e749688&userId=209",'GET').then(response => {
      this.userProfileDetails=[];
      if(response && response.data){
        this.userWallet = response.data;
        this.showLoader=false;
        if(response.data.userProfileDetails){
          this.userProfileDetails = response.data.userProfileDetails;
        }
      }
    })
  }

  showHideChangePassword(val){
    this.showChangePassword = val;
  }

  showHideChangeExposureLimit(val){
    this.showChangeExposureLimit = val;
    if(val == false){
      this.getAccountSummary()
    }
  }
  
  showHideChangeCommission(val){
    this.showChangeCommission = val;
    if(val == false){
      this.getAccountSummary()
    }
  }
}

export default AccountSummaryStore;
