import { extendObservable } from "mobx";

import { betSettlement } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken, betResults } from '../../sharedfiles/helper'

class BetSettlementStore {
  constructor(props) {
    extendObservable(this, {
      betSettlementList: [],
      itemsPerPage: 10,
      toalPages: 0,
      startIndex: 0,
      endIndex: 10,
      betResultsList: betResults,
      showLoader: false,
      showPreview: false,
      betSettlementParam: []
    });
  }
  /**
  * This method is used to get the bet settlement
  */
  getBetSettlement() {
    this.showLoader = true;
    apiCaller(betSettlement.getAllBetSettlements, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data && response.data.length > 0) {
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].selectedResult = this.betResultsList[0];
        }
        this.betSettlementList = response.data;
        this.calculatePagination(response.data);
      }
      else {
        this.betSettlementList = [];
      }
    });
  }
  /**
  * This method is called when user clicks or selects a page number from pagination.
  * @param {Object} pageNumber contains the selected page number. 
  **/
  handlePageChange(pageNumber) {
    this.startIndex = pageNumber.selected * this.itemsPerPage;
    this.endIndex = this.startIndex + this.itemsPerPage;

  }
  calculatePagination(data) {
    if (data && data.length > 0) {
      this.toalPages = Math.ceil(data.length / this.itemsPerPage);
    }
  }
  /**
  * This method is called when user selects status
  * @param {Object} result contains the user selected status.
  * @param {Number} index contains the index number
  */
  selectResult(result, index) {
    this.betSettlementList[index].selectedResult = result;
  }
  /**
  * This method is used to update the bet results
  */
  UpdateBetSettlement() {
    var token = "";
    var cookieToken = getUserToken();
    if (cookieToken && cookieToken.length > 0) {
      token = cookieToken;
    }
    var params = {
      "userLoginToken": token,
      "betSettlementsList": this.betSettlementParam
    }
    this.showLoader = true;
    apiCaller(betSettlement.updateSettlements, 'POST', params).then(response => {
      this.betSettlementParam = [];
      if (response && response.data && response.data.isUpdated) {
        this.togglePerviewPopUp();
        this.getBetSettlement();
      } else {
        this.showLoader = false;
      }
    });
  }
  /**
  * This method is used to get the bets for settlement.
  */
  getSettlementBets() {
    var settlementBets = [];
    for (var bet of this.betSettlementList) {
      if (bet.selectedResult.id !== 0) {
        settlementBets.push({
          "betSettlementId": bet.betSettlementId,
          "eventId": bet.eventId,
          "eventName": bet.eventName,
          "marketName": bet.marketName,
          "submarketName": bet.submarketName,
          "outcomeName": bet.outcomeName,
          "betStatusId": bet.selectedResult.id
        });
      }
    }
    this.betSettlementParam = settlementBets;
  }
  /**
  * This method is used to show and hide the change status popup.
  * @param {Object} item contatins user information to which status should change.
  */
  togglePerviewPopUp() {
    this.getSettlementBets();
    if (this.showPreview) {
      this.showPreview = false;
    } else {
      if (this.betSettlementParam.length > 0) {
        this.showPreview = !this.showPreview;
      }
    }
  }
  /**
  * This method is used to get the status name based on result id.
  * @param {Number} id contains result id.
  */
  getResultName(id) {
    var resultName = ""
    for (var result of this.betResultsList) {
      if (result.id === id) {
        resultName = result.name;
      }
    }
    return resultName
  }
}

export default BetSettlementStore;