import React, { Component } from 'react';
import { observer } from 'mobx-react';
import close from '../../../../assets/images/modal-close.svg';

import { getUserData } from "../../../../sharedfiles/helper";

class ChangeCommission extends Component {
    constructor(props) {
        super(props);
        this.handleKeyPressName = this.handleKeyPressName.bind(this);
    }
    componentWillMount() {
        this.props.changeCommissionStore.accountSummaryStore = this.props.accountSummaryStore;
        this.props.changeCommissionStore.resetFields();
    }
    componentDidMount() { }
    componentWillUnmount() { }
    onChange() {

    }
    handleKeyPressName(event) {
        if (event.charCode != 46 && event.charCode > 31 && (event.charCode < 48 || event.charCode > 57)) {
          event.preventDefault();
        }
        // var k = event.which;
        // if (k <= 48 || k >= 58) 
        // {event.preventDefault()};  
      }
    render() {
        const { mainLayoutStore, accountSummaryStore, changeCommissionStore } = this.props;
        return (
            <div className="RDE-changeCommission">
                <div className="RDE-modal-content">
                    <div className="RDE-modal-header">
                        <div className="RDE-modal-header__txt">Change Commission</div>
                        <img src={close} class="RDE-modal-closeIcon" onClick={accountSummaryStore.showHideChangeCommission.bind(accountSummaryStore, false)}/>
                    </div>
                    <div className="RDE-modal-body">
                        <div className="grid-equalHeight">
                            <div className="col-12">
                                <div className="RDE-DB-infoBox m-0">
                                    <div className="RDE-DB-infoBox__content">
                                        <div className="RDE-DB-infoBox-item">
                                            <div className="RDE-DB-infoBox-item__left"><span>Commission (%)</span>
                                                <span className="RDE-DB-colon">:</span></div>
                                            <div className="RDE-DB-infoBox-item__right">
                                                <div className=""> <form autoComplete="off">
                                                    <input type="text" className="RDE-formControl" onKeyPress={this.handleKeyPressName} placeholder="Enter commission"
                                                        value={changeCommissionStore.changeCommissionFields.commissionPer} onChange={changeCommissionStore.onCommissionChange.bind(changeCommissionStore,'commissionPer')}/>
                                              </form>  </div>
                                               
                                            </div>
                                        </div>
                                        <div className="RDE-DB-errorMessage text-center">{changeCommissionStore.changeCommissionErrors.commissionPer}</div>
                                        <div className="space-5"></div>
                                        <div className="RDE-DB-infoBox-item">
                                            <div className="RDE-DB-infoBox-item__left"><span>Password</span><span
                                                className="RDE-DB-colon">:</span></div>
                                            <div className="RDE-DB-infoBox-item__right">
                                                <div className="">
                                                <form autoComplete="off"> <input type="password" className="RDE-formControl" placeholder="Enter password"
                                                        value={changeCommissionStore.changeCommissionFields.commissionPassword} onChange={changeCommissionStore.onCommissionChange.bind(changeCommissionStore,'commissionPassword')} />
                                              </form>  </div>
                                               
                                            </div>
                                        </div>
                                        <div className="RDE-DB-errorMessage text-center">{changeCommissionStore.changeCommissionErrors.commissionPassword}</div>
                                        <div className="space-5"></div>
                                        <div className={changeCommissionStore.updateCommission == "updated successfully" ? "RDE-DB-errorMessage text-center" : "RDE-DB-errorMessage text-center"}>{changeCommissionStore.updateCommission}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="RDE-modal-footer">
                        <div className="grid-noGutter-middle-center">
                            <div className="col-4"><button class="btn RDE-btn-primary" onClick={changeCommissionStore.updateCommissionCharges.bind(changeCommissionStore)}>Change</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(ChangeCommission);