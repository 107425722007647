import React, { Component } from "react";
import { observer } from "mobx-react";

import AccountSummary from "./accountsummary/accountSummary";
import AccountSummaryStore from "./accountsummary/accountSummaryStore";
import AccountStatement from "./accountstatement/accountStatement";
import AccountStatementStore from "./accountstatement/accountStatementStore";
import BettingHistory from "./bettinghistory/bettingHistory";
import BettingHistoryStore from "./bettinghistory/bettingHistoryStore";
import BettingProfitLoss from "./bettingprofitloss/bettingProfitLoss";
import BettingProfitLossStore from "./bettingprofitloss/bettingProfitLossStore";
import TransactionHistory from "./transactionhistory/transactionHistory";
import TransactionHistoryStore from "./transactionhistory/transactionHistoryStore";
import ActivityLog from "./activitylog/activityLog";
import ActivityLogStore from "./activitylog/activityLogStore";
import {
	getUserData,
	getDownlineUserData,
	getUserRole,
} from "../../sharedfiles/helper";

const accountSummaryStore = new AccountSummaryStore();
const accountStatementStore = new AccountStatementStore();
const activityLogStore = new ActivityLogStore();
const bettingHistoryStore = new BettingHistoryStore();
const bettingProfitLossStore = new BettingProfitLossStore();
const transactionHistoryStore = new TransactionHistoryStore();
class MyAccount extends Component {
	constructor(props) {
		super(props);
	}
	componentWillMount() {
		this.props.myAccountStore.downlineStore = this.props.downlineStore;
		var userData = getUserData();
		this.props.myAccountStore.userBreadCrumb = [];
		this.props.myAccountStore.activeBreadCrumb = {};
		this.props.myAccountStore.selectedTab = "accountsummary";
		if (this.props.fromComponent === "downline") {
			var downlineUserData = getDownlineUserData();
			this.props.myAccountStore.userId = this.props.userId;
			this.props.myAccountStore.userRole = this.props.userRole;
			this.props.myAccountStore.userBreadCrumb = [];
			this.props.myAccountStore.userBreadCrumb =
				this.props.downlineStore.userBreadCrumb;
			// this.props.myAccountStore.userBreadCrumb.push({
			// 	"userId": this.props.userId,
			// 	"roleId": this.props.userRole,
			// 	"userName": downlineUserData.userName
			// });
			this.props.myAccountStore.activeBreadCrumb =
				this.props.myAccountStore.userBreadCrumb[
				this.props.myAccountStore.userBreadCrumb.length - 1
				];
		} else {
			this.props.myAccountStore.userId = userData.userId;
			this.props.myAccountStore.userRole = getUserRole();
			this.props.myAccountStore.userBreadCrumb = [];
			this.props.myAccountStore.userBreadCrumb.push({
				userId: "",
				roleId: userData.roleId,
				userName: userData.userName,
			});
			this.props.myAccountStore.activeBreadCrumb =
				this.props.myAccountStore.userBreadCrumb[0];
		}

		// this.props.myAccountStore.userRole = userData.roleId;
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.fromComponent === "downline") {
			this.props.myAccountStore.userId = nextProps.userId;
			this.props.myAccountStore.userRole = nextProps.userRole;
		}
	}
	componentDidMount() { }
	componentWillUnmount() { }
	onChange() { }
	breadCrumbClick(index, userId) {
		this.props.history.push("/downline");
		this.props.myAccountStore.breadCrumbClick(index, userId);
	}
	render() {
		const { mainLayoutStore, myAccountStore, downlineStore, fromComponent } =
			this.props;
		return (
			<div className="RDE-DB-myProfile">
				{fromComponent !== "downline" ? (
					<div className="RDE-Admin-userBreadcrumb">
						<div className="grid">
							<div className="col">
								<div className="RDE-DB-userBreadcrumbNav">
									<ul>
										{myAccountStore.userBreadCrumb &&
											myAccountStore.userBreadCrumb.map((item, index) => {
												// return <li key={'userBreadCrumb_' + index} onClick={myAccountStore.breadCrumbClick.bind(myAccountStore, index, item.userId)}>
												return (
													<li
														key={"userBreadCrumb_" + index}
														onClick={this.breadCrumbClick.bind(
															this,
															index,
															item.userId
														)}
													>
														<span
															className={
																"RDE-badge " +
																(item.roleId === 1 || item.roleId === 2
																	? "green"
																	: item.roleId === 3
																		? "seaGreen"
																		: "darkYellow")
															}
														>
															{item.roleId === 1
																? "AD"
																: item.roleId === 2
																	? "SUP"
																	: item.roleId === 3
																		? "MA"
																		: "PL"}
														</span>
														<span className="text-underline">
															{item.userName}
														</span>
													</li>
												);
											})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<div className="space-5"></div>
				<div className="space-5"></div>
				<div className="RDE-Admin-profileView">
					{fromComponent !== "downline" ? (
						<div className="RDE-Admin-contentHeader text-center">
							<i className="RDE-icon-user-profile"></i>
							<span>
								<b>{mainLayoutStore.userData.userName}</b>
							</span>
						</div>
					) : (
						""
					)}
					<div className="space-5"></div>
					<div className="RDE-DB-myBets-tabs">
						<ul>
							{myAccountStore.userRole === 1 ||
								myAccountStore.userRole === 2 ||
								myAccountStore.userRole === 3 ||
								myAccountStore.userRole === 4 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"accountsummary"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "accountsummary"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">Account Summary</span>
										</a>
									</div>
								</li>
							) : (
								""
							)}
							{myAccountStore.userRole === 2 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"accountstatement"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "accountstatement"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">Account Statement</span>
										</a>
									</div>
								</li>
							) : (
								""
							)}
							{myAccountStore.userRole === 4 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"bettinghistory"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "bettinghistory"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">Betting History</span>{" "}
										</a>
									</div>
								</li>
							) : (
								""
							)}
							{myAccountStore.userRole === 4 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"profitloss"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "profitloss"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">
												Betting Profit &amp; Loss
											</span>
										</a>
									</div>
								</li>
							) : (
								""
							)}
							{myAccountStore.userRole === 3 ||
								myAccountStore.userRole === 4 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"transactionhistory"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "transactionhistory"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">Transaction History</span>
										</a>
									</div>
								</li>
							) : (
								""
							)}
							{myAccountStore.userRole === 1 ||
								myAccountStore.userRole === 2 ||
								myAccountStore.userRole === 3 ||
								myAccountStore.userRole === 4 ? (
								<li
									onClick={myAccountStore.selectMyBets.bind(
										myAccountStore,
										"activitylog"
									)}
								>
									<div className="RDE-DB-myBets-tabs-menu__item">
										<a
											className={
												myAccountStore.selectedTab === "activitylog"
													? "no-link active"
													: "no-link"
											}
										>
											<span className="RDE-skewTxt">Activity Log</span>{" "}
										</a>
									</div>
								</li>
							) : (
								""
							)}
						</ul>
					</div>
					<div className="RDE-DB-myBets-tabContent">
						{myAccountStore.selectedTab === "accountsummary" ? (
							<AccountSummary
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								accountSummaryStore={accountSummaryStore}
								fromComponent={fromComponent}
							/>
						) : myAccountStore.selectedTab === "accountstatement" ? (
							<AccountStatement
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								accountStatementStore={accountStatementStore}
								transactionHistoryStore={transactionHistoryStore}
							/>
						) : myAccountStore.selectedTab === "bettinghistory" ? (
							<BettingHistory
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								bettingHistoryStore={bettingHistoryStore}
							/>
						) : myAccountStore.selectedTab === "profitloss" ? (
							<BettingProfitLoss
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								bettingProfitLossStore={bettingProfitLossStore}
							/>
						) : myAccountStore.selectedTab === "transactionhistory" ? (
							<TransactionHistory
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								transactionHistoryStore={transactionHistoryStore}
							/>
						) : myAccountStore.selectedTab === "activitylog" ? (
							<ActivityLog
								mainLayoutStore={mainLayoutStore}
								myAccountStore={myAccountStore}
								activityLogStore={activityLogStore}
								fromComponent={fromComponent}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default observer(MyAccount);
