import { extendObservable } from "mobx";

import { reports } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken } from "../../../sharedfiles/helper";

class ProfitLossReportStore {
  constructor(props) {
    extendObservable(this, {
      fromDate: new Date(),
      toDate: new Date(),
      selectedOption: "",
      showLoader: false,
      showErrorMsg: false,
      dateErrMsg: "",
      selectedSport: {},
      component: "",
      data: {},
      userBreadCrumb: [],
      activeBreadCrumb: {},
      dataByMarket: {},
      sportsList: [],
      showLoader: false
    });
  }
  /**
  *	This method is called when user selects the from date.
  * @param {date} selectDate contatins the selected from date.
  */
  selectFromDate(selectedDate) {
    this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate) {
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  getPL() {
    this.selectedOption = "";
    if (this.component === 'bydownline') {
      this.setBreadcrumbToInitial();
    }
    this.getProfitLoss(0);
  }
  resetFields() {
    this.selectedOption = "";
    this.dateErrMsg = "";
    this.fromDate = new Date();
    this.toDate = new Date();
    this.getSports();
    this.getProfitLoss(0)
  }

  getProfitLoss(uid) {

    this.showErrorMsg = false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    this.data=[];
    var fDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
    var tDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate()
    if (new Date(fDate) > new Date(tDate)) {
      this.dateErrMsg = "From date should less than to date";
      this.showErrorMsg = true;
      return;
    }
    this.showLoader = true;
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    if (token.length > 0 && this.component === 'bydownline') {
      var param = {
        "fromDate": fDate,
        "toDate": tDate,
        "userId": uid ? uid : 0,
        "userToken": cookeTocken,
      }
      this.showLoader = true;
      apiCaller(reports.byDownline, 'POST', param).then(response => {
        this.showLoader = false;
        if (response && response.data) {
          this.data = response.data;
        }
      });
    } else if (token.length > 0 && this.component === 'bymarket') {
      this.dataByMarket=[];
      var param = {
        "fromDate": fDate,
        "toDate": tDate,
        "sportId": this.selectedSport.id,
        "userToken": cookeTocken,
      }
      this.showLoader = true;
      apiCaller(reports.bymarket, 'POST', param).then(response => {
        this.showLoader = false;
        if (response && response.data) {
          this.dataByMarket = response.data;
        }
      });
    }

  }
  /**
  * This method is used to call the get profit loss method for non player users.
  * @param {Number} uid contains userid to which need to get profit and loss data.
  * @param {Number} role contains role id.
  */
  getProfitLossOfUser(item) {
    if (item.immediateUserRoleid && item.immediateUserRoleid !== 4) {
      var actBreadCrumbItem = {
        "userId": item.id,
        "roleId": item.immediateUserRoleid,
        "userName": item.name
      }
      this.userBreadCrumb.push(actBreadCrumbItem);
      this.activeBreadCrumb = actBreadCrumbItem;
      this.getProfitLoss(item.id);
    }
  }
  /**
  * This method is called when user selects sport
  * @param {string} status contains the user selected sport name.
  */
  selectSport(sport,byMarketStore) {
    this.selectedSport = sport;
  }
  /**
  * This method is used to get the sports list
  */
  getSports() {
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    apiCaller(reports.getSports + "?userToken=" + token, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data && response.data && response.data.length > 0) {
        this.sportsList = response.data;
        this.selectedSport = this.sportsList[0];
      }
    });
  }
  /**
  * This method is called when user select today or yesterday
  * @param {String} option contains 'today' and 'yesterday' to get data
  */
  selectTodayYesterday(option) {
    this.dateErrMsg = "";
    this.selectedOption = option;
    if (option === "today") {
      this.fromDate = new Date();
      this.toDate = new Date();
      if (this.component === 'bydownline') {
        this.setBreadcrumbToInitial();
      }
      this.getProfitLoss(0);
    } else if (option === "yesterday") {
      var yDate = new Date();
      var yDay = (yDate.getDate()) - 1;
      var yMonth = yDate.getMonth() + 1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth + "/" + yDay + "/" + yYear);
      this.toDate = new Date();
      if (this.component === 'bydownline') {
        this.setBreadcrumbToInitial();
      }
      this.getProfitLoss(0);
    }
  }
  /**
  * This method is used to get the downline list data when user clicks on breadcrumb item.
  * @param {number} index- contains index.
  * @param {Number} userId contains userId.
  */
  breadCrumbClick(index, userId) {
    var breadCrumbItems = this.userBreadCrumb.slice(0, index + 1);
    this.userBreadCrumb = breadCrumbItems;
    this.activeBreadCrumb = this.userBreadCrumb[index];
    this.getProfitLoss(this.userBreadCrumb[index]['userId']);
  }
  /**
  * This method is used to set breadcrumb to intial state.
  */
  setBreadcrumbToInitial() {
    var breadCrumbItems = this.userBreadCrumb.slice(0, 1);
    this.userBreadCrumb = breadCrumbItems;
    this.activeBreadCrumb = this.userBreadCrumb[0];
  }
}

export default ProfitLossReportStore;
