import cookie from 'react-cookies';

export var betStatus=[
	{
		id:	5,
		name:"Matched"	
	},
	{
		id:	6,
		name:"Un Matched"	
	},{
		id:	9,
		name:"Settled"	
	},{
		id:	8,
		name:"Cancelled"	
	},{
		id:	4,
		name:"Voided"	
	}
];
export var betListLiveBetStatus=[
	{
		id:	5,
		name:"Matched"	
	},
	{
		id:	6,
		name:"Un Matched"	
	}
];
export var currencyTypes=[
	{
		id:	1,
		code:"PTS"	
	},{
		id:	2,
		code:"PTH"	
	},{
		id:	3,
		code:"PTU"	
	},{
		id:	4,
		code:"PTI"	
	}
];
export var betResults=[
	{
		id:	0,
		name:"Select"	
	},
	{
		id:	2,
		name:"Win"	
	},
	{
		id:	3,
		name:"Loss"	
	},
	{
		id:	4,
		name:"Void"	
	}
]
export var timeZones = [{
	id:1,
	name:'GMT (Greenwich Mean Time) (GMT)'
},{
	id:2,
	name:'ECT (European Central Time) (GMT +1hr)'
},{
	id:3,
	name:'EET (Eastern European Timee) (GMT +2hrs)'
},{
	id:4,
	name:'ART ((Arabic) Egypt Standard Time) (GMT +2hrs)'
},{
	id:5,
	name:'EAT (Eastern African Time) (GMT +3hrs)'
},{
	id:6,
	name:'MET (Middle East Time) (GMT +3.5hrs)'
},{
	id:7,
	name:'NET (Near East Time) (GMT +4hrs)'
},{
	id:8,
	name:'PLT (Pakistan Lahore Time) (GMT +5hrs)'
},{
	id:9,
	name:'IST (India Standard Time) (GMT +5.5hrs)'
},{
	id:10,
	name:'BST (Bangladesh Standard Time) (GMT +6hrs)'
},{
	id:11,
	name:'VST (Vietnam Standard Time) (GMT +7hrs)'
},{
	id:12,
	name:'CTT (China Taiwan Time) (GMT +8hrs)'
},{
	id:13,
	name:'ACT (Australia Central Time) (GMT +9.5hrs)'
},{
	id:14,
	name:'AET (Australia Eastern Time) (GMT +10hrs)'
},
{
	id:15,
	name:'SST (Solomon Standard Time) (GMT +11hrs)'
},{
	id:16,
	name:'NST (New Zealand Standard Time) (GMT +12hrs)'
},{
	id:17,
	name:'MIT (Midway Islands Time) (GMT -11hrs)'
},{
	id:18,
	name:'HST (Hawaii Standard Time) (GMT -10hrs)'
},{
	id:19,
	name:'AST (Alaska Standard Time) (GMT -9hrs)'
},{
	id:20,
	name:'PST (Pacific Standard Time) (GMT -8hrs)'
},{
	id:21,
	name:'PNT (Phoenix Standard Time) (GMT -7hrs)'
},{
	id:22,
	name:'MST (Mountain Standard Time) (GMT -7hrs)'
},{
	id:23,
	name:'CST (Central Standard Time) (GMT -6hrs)'
},{
	id:24,
	name:'EST (Eastern Standard Time) (GMT -5hrs)'
},{
	id:25,
	name:'IET (Indiana Eastern Standard Time) (GMT -5hrs)'
},{
	id:26,
	name:'PRT (Puerto Rico and US Virgin Islands Time) (GMT -4hrs)'
},{
	id:27,
	name:'CNT (Canada Newfoundland Time) (GMT -3.5hrs)'
},{
	id:28,
	name:'AGT (Argentina Standard Time) (GMT -3hrs)'
},{
	id:29,
	name:'BET (Brazil Eastern Time) (GMT -3hrs)'
},{
	id:30,
	name:'JST (Japan Standard Time) (GMT +9hrs)'
}]
export function getAuthenticationNumber(){
	return Math.floor((Math.random() * (9999-1000) )+ 1000);
}

export function getUserToken(){
	var token="";
    var cookeTocken = cookie.load('UserToken');
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    return token
}
export function getUserRole(){
	if(cookie.load('userData')){
	  var data = cookie.load('userData');
	  return data.roleId;
	}
	return '' ;
}
export function getUserData(){
	if(cookie.load('userData')){
	  return cookie.load('userData');
	}
	return '' ;
}
export function getDownlineUserData(){
	if(cookie.load('downlineUserInfo')){
	  return cookie.load('downlineUserInfo');
	}
	return '' ;
}
export function removeCookies(){
	cookie.remove('userData');
	cookie.remove('UserToken');
}