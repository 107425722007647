import { extendObservable } from "mobx";

import { reports, betlistlive } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betListLiveBetStatus, getUserToken } from '../../sharedfiles/helper'

class BetListLiveStore {
    constructor(props) {
        extendObservable(this, {
            balance: null,
            selectedStatus: betListLiveBetStatus[0],
            fromDate: new Date(),
            toDate: new Date(),
            dateErrMsg: "",
            selectedOption: "",
            betStatusList: betListLiveBetStatus,
            sportsList: [],
            selectedSport: {},
            data: [],
            betListLiveData: {},
            userRole: null,
            transactionsList: [{
                'id': 101,
                'key':50,
                'name': '50 Txn'
            }, {
                'id': 102,
                'key':25,
                'name': '25 Txn'
            }],
            autoRefreshTimeList: [{
                'id': 1001,
                'time': 'Stop'
            }, {
                'id': 1002,
                'time': '60'
            }, {
                'id': 1003,
                'time': '30'
            }, {
                'id': 1004,
                'time': '15'
            }, {
                'id': 1005,
                'time': '5'
            }, {
                'id': 1006,
                'time': '2'
            }],
            selectedRefTime: {},
            selectedNumTransaction: {},
        });
        this.selectedRefTime = this.autoRefreshTimeList[0];
        this.selectedNumTransaction = this.transactionsList[0];
    }
    /**
    * This method is called when user selects sport
    * @param {string} sport contains the user selected sport details.
    */
    selectSport(sport) {
        this.selectedSport = sport;
        this.getBetListLiveData();
    }
    /**
    * This method is called when user selects last numer of transactions
    * @param {string} transaction contains the user selected number of transactions.
    */
    selectNumTransactions(transaction) {
        this.selectedNumTransaction = transaction;
        this.getBetListLiveData();
    }
    /**
    * This method is called when user selects status
    * @param {string} status contains the user selected status.
    */
    selectStatus(status) {
        this.selectedStatus = status;
        this.getBetListLiveData();
    }

    /**
    * This method is called when user selects the auto refresh time
    * @param {string} reftime contains the user selected refreshseconds.
    */
    selectRefreshTime(refTime) {
        this.selectedRefTime = refTime;
        // this.getBetListLiveData();
        this.refreshData();
    }
    
    refreshData(){
        if(this.selectedRefTime.time !== 'Stop'){
            var intervalTime = Number(this.selectedRefTime.time) * 1000;
            if(this.interval && this.interval!==''){
                clearInterval(this.interval);
            }

            try {
                this.interval = setInterval(async () => {
                    this.getBetListLiveData()
                }, intervalTime);
            } catch (e) {
                console.log('error ==== ', e);
            }
        }else{
             clearInterval(this.interval);
        }
    }

    getBetListLiveData() {
        this.showLoader = true;
        this.showErrorMsg = false;
        var token = "";
        var cookeTocken = getUserToken();
        if (cookeTocken && cookeTocken.length > 0) {
            token = cookeTocken;
        }
        if (token.length > 0) {
              var param = {
                            "sportId":[this.selectedSport.id],
                            "betStatus":this.selectedStatus.id,
                            "userToken": token,         
                            "orderOfDisplay":1,
                            "order":1,
                            "transactionLimit": this.selectedNumTransaction.key
                          }
            // var param = {
            //     "userToken": "2ea5d5a8-eb73-41a8-a9a5-df3e3c109419",
            //     "orderOfDisplay": 1,
            //     "order": 1,
            //     "betStatus": 1,
            //     "sportId": [1, 2],
            //     "transactionLimit": 2
            // }
            apiCaller(betlistlive.getBetlistLiveDetails, 'POST', param).then(response => {
                this.showLoader = false;
                if (response && response.data) {
                    // this.data = response.data.betListDetails;
                    this.betListLiveData = response.data;
                }
            });
        }
    }

    /**
    * This method is used to get the sports list
    */
    getSports() {
        var token = "";
        var cookeTocken = getUserToken();
        if (cookeTocken && cookeTocken.length > 0) {
            token = cookeTocken;
        }
        apiCaller(reports.getSports + "?userToken=" + token, 'GET').then(response => {
            this.showLoader = false;
            if (response && response.data && response.data.length > 0) {
                this.sportsList = response.data;
                this.selectedSport = this.sportsList[0];
                this.getBetListLiveData();
            }
        });
    }
}

export default BetListLiveStore;
