import { extendObservable } from "mobx";

import { reports,betList } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../sharedfiles/helper'

class BetListStore {
  constructor(props) {
    extendObservable(this, {
      balance:null,
      selectedStatus:betStatus[0],
      fromDate:new Date(),
      toDate:new Date(),
      dateErrMsg:"",
      selectedOption:"",
      betStatusList:betStatus,
      sportsList:[],
      selectedSport:{},
      data:[],
      userRole:null,
      showLoader:false,
      itemsPerPage: 10,
      totalPages: 0,
      pageNumber:0,
      fromIndex:0,
      toIndex:0
    });
  }
  /**
  * This method is called when user selects sport
  * @param {string} sport contains the user selected sport details.
  */
  selectSport(sport){
      this.selectedSport = sport;
  }
  /**
  * This method is called when user selects status
  * @param {string} status contains the user selected status.
  */
  selectStatus(status){
      this.selectedStatus = status;
  }
  /**
  *	This method is called when user selects the from date.
  * @param {date} selectDate contatins the selected from date.
  */
  selectFromDate(selectedDate){
  	this.fromDate = new Date(selectedDate);
    this.dateErrMsg="";
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate){
    this.toDate = new Date(selectedDate);
    this.dateErrMsg="";
  }
  getHistory(){
  	this.selectedOption="";
    this.getBetHistory();
  }
  resetFields(){
    this.selectedOption="";
    this.dateErrMsg="";
    this.fromDate=new Date();
    this.toDate=new Date();
    this.getSports();


  }
  getBetHistory(){
    this.totalPages = 0;
    //this.showLoader=true;
    this.data=[];
    this.showErrorMsg=false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);
    
    var fDate = fromDate.getFullYear()+"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate();
    var tDate = toDate.getFullYear()+"-"+(toDate.getMonth()+1)+"-"+toDate.getDate()
    if(new Date(fDate) > new Date(tDate)){
      this.dateErrMsg="From date should less than to date";
      this.showErrorMsg=true;
      return;
    }
    this.showLoader=true;
    var token="";
    var cookeTocken = getUserToken();
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    if(token.length>0){
      var param = {
                    "fromDate": fDate,
                    "toDate": tDate,
                    "minutes":330,
                    "sportId":this.selectedSport.id,
                    "betStatus":this.selectedStatus.id,
                    "userToken": cookeTocken,
                  }
     // this.showLoader=true;
      apiCaller(betList.getBetList,'POST',param).then(response => {
        this.showLoader=false;
        if(response && response.data && 
           response.data.betListDetailsList && 
           response.data.betListDetailsList.length>0){
            this.data = response.data.betListDetailsList;
            this.totalPages = Math.ceil(this.data.length / this.itemsPerPage);
            this.pageNumber = 0;
            this.fromIndex = this.pageNumber * this.itemsPerPage;
            this.toIndex = this.fromIndex +this.itemsPerPage;
        }
      });
    }
  }
  /**
  * This method is called when user select today or yesterday
  * @param {String} option contains 'today' and 'yesterday' to get data
  */
  selectTodayYesterday(option){
    this.selectedOption = option;
    this.dateErrMsg="";
    if(option === "today"){
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getBetHistory();
    }else if(option === "yesterday"){
      var yDate = new Date();
      var yDay = yDate.getDate()-1;
      var yMonth = yDate.getMonth()+1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth+"/"+yDay+"/"+yYear);
      this.toDate = new Date();
      this.getBetHistory();
    }
  }
  /**
  * This method is used to get the sports list
  */
  getSports(){
    var token="";
    var cookeTocken = getUserToken();
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
    }
    apiCaller(reports.getSports+"?userToken="+token,'GET').then(response => {
      this.showLoader=false;
      if(response && response.data && response.data.length>0){
          this.sportsList = response.data;
          this.selectedSport = this.sportsList[0];
      }
    });
  }

  handlePageChange(pager) {
    //alert(pageNumber);
    this.pageNumber = pager.selected;
    this.fromIndex = this.pageNumber * this.itemsPerPage;
    this.toIndex = this.fromIndex +this.itemsPerPage;
    //this.getAccountStatement();
  }
}

export default BetListStore;
