import React from 'react';
// import './assets/css/RD-Exchange-styles.css';
import './assets/css/RD-Exchange-styles-blue-theme.scss';
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import Login from './components/login/Login';
import LoginStore from './components/login/LoginStore'
import MainLayout from './components/mainlayout/MainLayout';
import MainLayoutStore from './components/mainlayout/MainLayoutStore';

const loginStore = new LoginStore();
const mainLayoutStore = new MainLayoutStore();
function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route exact path="/login" render={(props) => <Login {...props} loginStore={loginStore} />} />
					<Route path="/" render={(props) => <MainLayout {...props} mainLayoutStore={mainLayoutStore} />} />

				</Switch>
			</Router>
		</div>
	);
}

export default App;
