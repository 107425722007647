import { extendObservable } from "mobx";

import { reports, riskmanagement } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../sharedfiles/helper'

class RiskManagementStore {
    constructor(props) {
        extendObservable(this, {
            userRole: null,
            selectedTab : 'matchedPlayer',
            playersList : []
        });
    }

    selectTab(val){
        this.selectedTab= val;
    }

    getRiskManagementPlayers() {
        this.showLoader = true;
        this.showErrorMsg = false;
        var token = "";
        var cookeTocken = getUserToken();
        if (cookeTocken && cookeTocken.length > 0) {
            token = cookeTocken;
        }
        if (token.length > 0) {
            apiCaller(riskmanagement.getPlayers+"?loginToken="+cookeTocken, 'GET').then(response => {
                this.showLoader = false;
                if (response && response.data) {
                    response = {"data":{"matchedList":[
                        {"uid":"bhaskar1693",
                        "exposure":21.00,
                        "matchedAmount":24.00}],"exposureList":[
                            {"uid":"bhaskar1670",
                            "exposure":22.00,
                            "matchedAmount":26.00}]}}
                    this.playersList = response.data;
                }
            });
        }
    }
}

export default RiskManagementStore;
