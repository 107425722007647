import { extendObservable } from "mobx";
import Validator from 'validatorjs';
import { profile, myaccount } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { getUserToken, getUserRole, getUserData } from "../../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class ChangeCommissionStore {
    constructor(props) {
        extendObservable(this, {
            loggedInUserRole: getUserRole(),
            changeCommissionFields: {
                commissionPer: "",
                commissionPassword: ""
            },
            changeCommissionErrors: {
                commissionPer: "",
                commissionPassword: ""
            },
            userData : getUserData(),
            updateCommission : '',
            accountSummaryStore:null
        });
    }

    /**
      * This method is used to reset the login and signup fields
      *
      */
    resetFields() {
        this.changeCommissionFields= {
            commissionPer: "",
            commissionPassword: ""
        }
        this.changeCommissionErrors= {
            commissionPer: "",
            commissionPassword: ""
        }
        this.updateCommission = ''; 
    }

    onCommissionChange(field, event) {
        switch (field) {
            case 'commissionPer':
                this.changeCommissionFields.commissionPer = event.target.value;
                this.changeCommissionErrors.commissionPer = "";
                break;
            case 'commissionPassword':
                this.changeCommissionFields.commissionPassword = event.target.value;
                this.changeCommissionErrors.commissionPassword = "";
                break;
            default:
                console.log("change commission");
        }
    }

    /**
    * This method is used to get the User profile data.
    */
    updateCommissionCharges() {

        var rules = {
            commissionPer: 'required',
            commissionPassword: 'required|min:8'
        };

        var validation = new Validator(this.changeCommissionFields, rules, {
            "required.commissionPer": "Please enter commission % ",
            "min.commissionPassword": "Password must be at least 8 characters.",
            "required.commissionPassword": "Please enter the password"
        });
        
        var param = {
            "loginToken":getUserToken(),
            "commission":this.changeCommissionFields.commissionPer,
            "userPassword":this.changeCommissionFields.commissionPassword,
            "userId":this.accountSummaryStore.userId?this.accountSummaryStore.userId:this.userData.userId
        }
        this.updateCommission="";

        if (validation.passes()) {
            apiCaller(myaccount.updateCommission, 'POST', param).then(response => {
                //   this.userProfileDetails=[];
                if (response && response.data) {
                     
                    if(response.data == 'updated successfully'){
                        this.accountSummaryStore.showHideChangeCommission(false);
                    }
                    else{
                        this.updateCommission = response.data;
                    }
                }
            })
        } else {
            if (validation.errors.has('commissionPer') && validation.errors.get("commissionPer").length > 0) {
                this.changeCommissionErrors.commissionPer = validation.errors.get("commissionPer")[0]
            }
            if (validation.errors.has('commissionPassword') && validation.errors.get("commissionPassword").length > 0) {
                this.changeCommissionErrors.commissionPassword = validation.errors.get("commissionPassword")[0]
            }
        }
    }

}

export default ChangeCommissionStore;
