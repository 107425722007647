import { extendObservable } from "mobx";

const re = /^[0-9\b]+$/;
class MyAccountStore {
  constructor(props) {
    extendObservable(this, {
      downlineStore: null,
      balance: null,
      totalAvailableBalance: null,
      totalBalance: null,
      totalExposure: null,
      userDetails: [],
      userBreadCrumb: [],
      activeBreadCrumb: {},
      // loggedInUserRole: getUserRole(),
      // userRole: getUserRole(),
      userRole: null,
      userId: null,
      selectedTab: 'accountsummary'
    });
  }


  /**
  * This method is used to get the downline list data when user clicks on breadcrumb item.
  * @param {number} index- contains index.
  */
  breadCrumbClick(index, userId) {
    var breadCrumbItems = this.userBreadCrumb.slice(0, index + 1);
    this.userBreadCrumb = breadCrumbItems;
    this.downlineStore.userBreadCrumb = this.userBreadCrumb;
    this.downlineStore.getDownlineListOfUser(this.userBreadCrumb[index], true);
  }

  selectMyBets(val) {
    this.selectedTab = val;
  }

}

export default MyAccountStore;
