import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

import { getUserRole } from '../../sharedfiles/helper'
import dataLoader from '../../assets/images/loader-orange.svg';

class BetList extends Component {
    componentWillMount() {
      this.props.betListStore.userRole = getUserRole();
      this.props.betListStore.resetFields();
    }
    componentDidMount() { }
    componentWillUnmount() { }
    render() {
        const { betListStore } = this.props;
        return (
            <div className="RDE-DB-betList">
                <div className="RDE-DB-myBets-filters mb-10">
                    <div className="RDE-DB-myBets-filters__left">
                        <span className="RDE-DB-myBets-filters__label">Sports:</span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {betListStore.selectedSport.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul>
                              {
                                betListStore.sportsList.map((sport,index)=>{
                                  return <li key={'betlistsport_'+index}
                                             onClick={betListStore.selectSport.bind(betListStore, sport)}>
                                              <Dropdown.Item eventKey={sport.id}>{sport.name}</Dropdown.Item>
                                        </li>
                                })
                              }
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                        <span className="RDE-DB-myBets-filters__label">Bet Status: </span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {betListStore.selectedStatus.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul>
                              {
                                betListStore.betStatusList.map((status,index)=>{
                                  return <li onClick={betListStore.selectStatus.bind(betListStore, status)}>
                                              <Dropdown.Item eventKey={status.id}>{status.name}</Dropdown.Item>
                                          </li>
                                })
                              }
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="RDE-space"></div><span className="RDE-DB-myBets-filters__label">Period From:</span>
                        <div className="RDE-DB-myBets-filters__datePicker">
                            <DatePicker selected={betListStore.fromDate}
                              dateFormat="dd/MM/yyyy"
                              onChange={betListStore.selectFromDate.bind(betListStore)}
                              className="RDE-DB-datePickerInput"
                              maxDate={new Date()} />
                            <span className="RDE-DB-myBets-filters__label">To :</span>
                            <div className="react-datepicker-wrapper">
                                <DatePicker selected={betListStore.toDate}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={betListStore.selectToDate.bind(betListStore)}
                                  className="RDE-DB-datePickerInput"
                                  maxDate={new Date()} />
                            </div>
                            <span className="RDE-DB-datePickerErrorMessage">{betListStore.dateErrMsg}</span>
                        </div>
                        <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto"
                                onClick={betListStore.getHistory.bind(betListStore)}>Get History</button>
                    </div>
                    <div className="RDE-DB-myBets-filters__right">
                        <ul className="RDE-DB-myBets-filters__todayYesterdayList">
                            <li className={betListStore.selectedOption === "today" ? "active" : ""} 
                            onClick={betListStore.selectTodayYesterday.bind(betListStore, 'today')}>Today</li>
                            <li className={betListStore.selectedOption === "yesterday" ? "active" : ""}
                            onClick={betListStore.selectTodayYesterday.bind(betListStore, 'yesterday')}>From Yesterday</li>
                        </ul>
                    </div>
                </div>
                <div className="RDE-DB-table">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>{betListStore.userRole ===1?'Sup ID':betListStore.userRole===2?'MA ID':'PL ID'}</th>
                                {betListStore.userRole !==3?<th>{betListStore.userRole ===1?'MA ID':betListStore.userRole===2?'PL ID':''}</th>:""}
                                <th>Bet ID</th>
                                <th>Bet taken</th>
                                <th>IP Address</th>
                                <th>Market</th>
                                <th>Selection</th>
                                <th>Type</th>
                                <th>Odds req.</th>
                                <th>Stake</th>      
                                <th>Liability</th>
                                <th>Profit/Loss</th>                                                                                
                            </tr>
                        </thead>
                        <tbody>
                          {
                            betListStore.data.map((item, index)=>{
                              if(index>=betListStore.fromIndex && index<betListStore.toIndex){
                                return <tr key={'betlistdata_'+index}>
                                        <td>{betListStore.userRole==1 ?item.superName :betListStore.userRole==2?item.masterName:item.playerName}</td>                                                                       
                                        {betListStore.userRole !==3?<td>{betListStore.userRole==1?item.masterName:item.playerName}</td>:""}
                                        <td>{item.betId}</td>
                                        <td>{item.betPlaced}</td>
                                        <td>{item.ipAddress?item.ipAddress:'--'}</td>
                                        <td>{item.sportName} &gt; {item.eventName} &gt; {item.marketName}</td>
                                        <td>{item.selection}</td>
                                        <td>{item.oddType}</td>
                                        <td>{item.oddsRequest}</td>
                                        <td>{item.stake}</td>
                                        <td>{item.liability && item.liability>0?item.liability:'--'}</td>
                                        <td>{item.profitLoss >0 ? item.profitLoss:`--` }</td>
                                    </tr>
                              }
                              
                            })
                          }
                        </tbody>
                    </table>
                    {!betListStore.showLoader && (!betListStore.data || (betListStore.data && betListStore.data.length===0)) ? <div className="RDE-noDataMessageBox">
                    Currently data is not available for your selection.
                                      </div> : ''}
                    {betListStore.showLoader?<div className="RDE-loader">
                                <img src={dataLoader} alt="loading...!" />
                            </div>:''}
                    {betListStore.totalPages > 0 && <div className="RDE-DB-pagination">
                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        // breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={betListStore.totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={betListStore.handlePageChange.bind(betListStore)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    </div>}
                </div>
            </div>
        )
    }
}
export default observer(BetList);