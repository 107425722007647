import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../../assets/images/loader-orange.svg';
import { getUserRole } from '../../../sharedfiles/helper'

class OtherMarkets extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        //   this.props.betListLiveStore.userRole = getUserRole();
        this.props.otherMarketsStore.getOtherMarkets();

    }
    componentDidMount(props) {

    }
    componentWillUnmount() {

    }
    render() {
        const { riskManagementStore,otherMarketsStore } = this.props;
        return (

            <div className="RDE-Admin-otherMarkets">
                    <div className="RDE-sports-nav-tabs">
                        <ul className="RDE-sports-nav-tabs-menu">
                            <li>
                                <div className="RDE-sports-nav-tabs-menu__item active">
                                    <span className="RDE-skewTxt">Other Markets</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="RDE-DB-table mb-10">
                        <table className="RDE-DB-table-default">
                            <thead>
                                <tr>
                                    <th>Sports</th>
                                    <th>Market Date </th>
                                    <th>Event/Market Name</th>
                                    <th>Matched Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sport Name</td>
                                    <td>2019-05-28</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Sport Name</td>
                                    <td>2019-05-28</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

        )
    }
}
export default observer(OtherMarkets);