import React, { Component } from 'react';
import { observer } from 'mobx-react';
import close from '../../../../assets/images/modal-close.svg';

import { getUserData } from "../../../../sharedfiles/helper";

class ChangeExposureLimit extends Component {
    constructor(props) {
        super(props);
        this.handleKeyPressName = this.handleKeyPressName.bind(this);
    }
    componentWillMount() {
        this.props.changeExposureLimitStore.accountSummaryStore = this.props.accountSummaryStore;
        this.props.changeExposureLimitStore.resetFields();
    }
    componentDidMount() { }
    componentWillUnmount() { }
    onChange() {

    }
    handleKeyPressName(event) {
        if (event.charCode != 46 && event.charCode > 31 && (event.charCode < 48 || event.charCode > 57)) {
            event.preventDefault();
        }
        // var k = event.which;
        // if (k <= 48 || k >= 58) 
        // {event.preventDefault()};  
    }
    render() {
        const { mainLayoutStore, myAccountStore, accountSummaryStore, changeExposureLimitStore } = this.props;
        return (
            <div className="RDE-changeExposureLimit">
                <div className="RDE-modal-content">
                    <div className="RDE-modal-header">
                        <div className="RDE-modal-header__txt">Change Exposure Limit</div>
                        <img src={close} className="RDE-modal-closeIcon" onClick={accountSummaryStore.showHideChangeExposureLimit.bind(accountSummaryStore, false)} />
                    </div>
                    <div className="RDE-modal-body">
                        <div className="grid-equalHeight">
                            <div className="col-12">
                                <div className="RDE-DB-infoBox m-0">
                                    <div className="RDE-DB-infoBox__content">
                                        <div className="RDE-DB-infoBox-item">
                                            <div className="RDE-DB-infoBox-item__left"><span>Current Exposure Limit</span><span
                                                className="RDE-DB-colon">:</span></div>
                                            <div className="RDE-DB-infoBox-item__right">
                                                <div className="">{accountSummaryStore.userProfileDetails && accountSummaryStore.userProfileDetails.exposureLimit && (accountSummaryStore.userProfileDetails.exposureLimit !== null) ? accountSummaryStore.userProfileDetails.exposureLimit : '---'}</div>
                                            </div>
                                        </div>
                                        <div className="RDE-DB-infoBox-item">
                                            <div className="RDE-DB-infoBox-item__left"><span>New Exposure</span><span
                                                className="RDE-DB-colon">:</span></div>
                                            <div className="RDE-DB-infoBox-item__right">
                                                <div className=""><form autoComplete="off">
                                                    <input type="text" className="RDE-formControl" onKeyPress={this.handleKeyPressName} placeholder="Enter exposure"
                                                        value={changeExposureLimitStore.changeExposureLimitFields.exposureVal} onChange={changeExposureLimitStore.onExposureLimitChange.bind(changeExposureLimitStore, 'exposureVal')} />
                                                </form> </div>


                                            </div>

                                        </div>
                                        <div className="RDE-DB-errorMessage text-center">{changeExposureLimitStore.changeExposureLimitErrors.exposureVal}</div>
                                        <div className="space-5"></div>
                                        <div className="RDE-DB-infoBox-item">
                                            <div className="RDE-DB-infoBox-item__left"><span>Password</span><span
                                                className="RDE-DB-colon">:</span></div>
                                            <div className="RDE-DB-infoBox-item__right">
                                                <div className=""><form autoComplete="off">
                                                    <input type="password" className="RDE-formControl" placeholder="Enter password"
                                                        value={changeExposureLimitStore.changeExposureLimitFields.exposurePassword} onChange={changeExposureLimitStore.onExposureLimitChange.bind(changeExposureLimitStore, 'exposurePassword')} />
                                                </form></div>


                                            </div>

                                        </div>

                                        <div className="RDE-DB-errorMessage text-center">{changeExposureLimitStore.changeExposureLimitErrors.exposurePassword}</div>
                                        <div className="space-5"></div>
                                        <div className={changeExposureLimitStore.updateExposureLimitVal == "updated successfully" ? "RDE-DB-success text-center" : "RDE-DB-errorMessage text-center"}>{changeExposureLimitStore.updateExposureLimitVal}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="RDE-modal-footer">
                        <div className="grid-noGutter-middle-center">
                            <div className="col-4"><button className="btn RDE-btn-primary" onClick={changeExposureLimitStore.updateExposureLimit.bind(changeExposureLimitStore)}>Change</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default observer(ChangeExposureLimit);