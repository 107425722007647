import { extendObservable } from "mobx";

import { adminMarkets } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../sharedfiles/helper';

const re = /^[0-9\b.]+$/;
class AddMarketsStore {
  constructor(props) {
    extendObservable(this, {
      userRole: null,
      showErrMsg:'',
      selectedSport: {},
      sportsList: [],
      leaguesList: [],
      selectedLeague: {},
      selectedEvent: {},
      eventsList: [],
      selectedMarket: "",
      addFancyInit: {
        "eventId": '',
        "marketName": "",
        "outcomeYes": '',
        "outcomeNo": '',
        "oddsYes": '',
        "oddsNo": '',
        "minStake": '',
        "maxStake": ''
      },
      addFancy: {
        "eventId": '',
        "marketName": "",
        "outcomeYes": '',
        "outcomeNo": '',
        "oddsYes": '',
        "oddsNo": '',
        "minStake": '',
        "maxStake": ''
      },
      addFancyMarkets: [],
      addFancyErr: "",
      addMatchOdds: [],
      showLoader: false,
   
    });
  }
  /**
  * This method is used to get the sports list
  */
  getSports() {
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    this.showLoader = true;
    this.leaguesList = [];
    this.selectedLeague = {};
    this.eventsList = [];
    this.selectedEvent = {};
    this.selectedMarket = "";
    this.addMatchOdds = [];
    this.addFancyMarkets = [];
    apiCaller(adminMarkets.getSports + "?userToken=" + token, 'GET').then(response => {
      if (response && response.data &&
        response.data.hasOwnProperty('sports') && response.data.sports.length > 0) {
        this.sportsList = response.data.sports;
        this.selectedSport = this.sportsList[0];
        this.getLeagues();
      } else {
        this.showLoader = false;
      }
    });
  }
  /**
  * This method is called when user selects sport
  * @param {string} status contains the user selected sport name.
  */
  selectSport(sport) {
    this.selectedSport = sport;
    this.leaguesList = [];
    this.selectedLeague = {};
    this.eventsList = [];
    this.selectedEvent = {};
    this.selectedMarket = "";
    this.addMatchOdds = [];
    this.addFancyMarkets = [];
    this.getLeagues();
  }
  /**
  * This method is used to get the league name based on selected sport.
  */
  getLeagues() {
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    this.showLoader = true;
    apiCaller(adminMarkets.getLeagues + "?userToken=" + token + "&sportId=" + this.selectedSport.sportId, 'GET').then(response => {
      if (response && response.data && response.data.hasOwnProperty('leagues')
        && response.data.leagues.length > 0) {
        this.leaguesList = response.data.leagues;
        this.selectedLeague = this.leaguesList[0];
        this.getEvents();
      } else {
        this.showLoader = false;
      }
    });
  }
  /**
  * This method is called when user selects sport
  * @param {string} status contains the user selected sport name.
  */
  selectLeague(league) {
    this.selectedLeague = league;
    this.eventsList = [];
    this.selectedEvent = {};
    this.selectedMarket = "";
    this.addMatchOdds = [];
    this.addFancyMarkets = [];
    this.getEvents();
  }
  /**
  * This method is used to get the league name based on selected sport.
  */
  getEvents() {
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    this.showLoader = true;
    apiCaller(adminMarkets.getEvents + "?userToken=" + token + "&leagueId=" + this.selectedLeague.leagueId, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data &&
        response.data.hasOwnProperty('events') && response.data.events.length > 0) {
        this.eventsList = response.data.events;
        this.selectedEvent = this.eventsList[0];
        this.selectedMarket = "Match Odds";
        this.setMatchOddsObj();
      }
    });
  }
  /**
  * This method is called when user selects sport
  * @param {string} status contains the user selected sport name.
  */
  selectEvent(event) {
    this.selectedEvent = event;
    this.addMatchOdds = [];
    this.addFancyMarkets = [];
    this.getMarkets();
    this.setMatchOddsObj();
  }
  /**
  *
  */
  setMatchOddsObj() {
    this.addMatchOdds = [];
    var outComes = this.selectedEvent.eventName.split(' v ')
    if (outComes.length > 1) {
      this.addMatchOdds.push(
        {
          "sportId": this.selectedSport.sportId,
          "eventId": this.selectedEvent.eventId,
          "backSize": "",
          "laySize": "",
          "minStake": "",
          "team": outComes[0],
          "backOdds": "",
          "layOdds": "",
          "maxStake": ""
        }
      )
      if (this.selectedSport.sportId === 1) {
        this.addMatchOdds.push(
          {
            "sportId": this.selectedSport.sportId,
            "eventId": this.selectedEvent.eventId,
            "backSize": "",
            "laySize": "",
            "minStake": "",
            "team": "Draw",
            "backOdds": "",
            "layOdds": "",
            "maxStake": ""
          }
        )
      }
      this.addMatchOdds.push(
        {
          "sportId": this.selectedSport.sportId,
          "eventId": this.selectedEvent.eventId,
          "backSize": "",
          "laySize": "",
          "minStake": "",
          "team": outComes[1],
          "backOdds": "",
          "layOdds": "",
          "maxStake": ""
        }
      )
    }
  }
  /**
  }
  * This method is called when user selects sport
  * @param {string} status contains the user selected market name.
  */
  selectMarket(marketName) {
    this.selectedMarket = marketName;
  }
  /**
  * This method is used to get the league name based on selected sport.
  */
  getMarkets() {
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    var param = {
      "sportId": this.selectedSport.sportId,
      "leagueId": this.selectedLeague.leagueId,
      "eventId": this.selectedEvent.eventId,
      "userToken": token
    }
    apiCaller(adminMarkets.getMarkets, 'POST', param).then(response => {
      this.showLoader = false;
      // if(response && response.data && 
      //    response.data.events && response.data.events.length>0){
      //     this.eventsList = response.data.events;
      //     this.selectedEvent = this.eventsList[0];
      // }
    });
  }
  /**
  * This method is called when user changes add fancy fields.
  * @param {String} field contains filed name which is edited.
  * @param {Object} e contains the traget object properties.
  */
  changeFancyField(field, e) {
    this.addFancyErr = "";
    switch (field) {
      case 'marketName':
        this.addFancy.marketName = e.target.value;
        this.assignValue(field, e.target.value);
        break;
      case 'outcomeYes':
      case 'outcomeNo':
      case 'oddsYes':
      case 'oddsNo':
      case 'minStake':
      case 'maxStake':
        if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
          this.assignValue(field, e.target.value);
        }
        break;
      default:
        console.log("default field change");
    }
  }
  /**
  * This method is called when user changes add match odds field.
  * @param {String} field contains filed name which is edited.
  * @param {Number} index contains number on which change fields.
  * @param {Object} e contains the traget object properties.
  */
  changeMOField(field, index, e) {
    this.showErrMsg="";
    switch (field) {
      case 'backSize':
      case 'laySize':
      case 'minStake':
      case 'backOdds':
      case 'layOdds':
      case 'maxStake':
        if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
          this.assignMOValue(field, e.target.value, index);
        }
        break;
      default:
        console.log("default field change");
    }
  }
  /**
  * This method is used to assign a value to field
  * @param {String} filed contain fild name to which value should addign.
  * @param {String} value contain value to be addign.
  * @param {Number} index contains number on which change fields.
  */
  assignMOValue(filed, value, index) {
    this.addMatchOdds[index][filed] = value
  }
  /**
  * This method is used to assign a value to field
  * @param {String} filed contain fild name to which value should addign.
  * @param {String} value contain value to be addign.
  */
  assignValue(filed, value) {
    this.addFancy[filed] = value
  }
  /**
  * This method is used to add a fancy market to table.
  */
  pushFancy() {
    if (this.addFancy.marketName !== "" &&
      this.addFancy.outcomeYes !== "" &&
      this.addFancy.outcomeNo !== "" &&
      this.addFancy.oddsYes !== "" &&
      this.addFancy.oddsNo !== "") {
      this.addFancy.eventId = this.selectedEvent.eventId;
      this.addFancyMarkets.push(this.addFancy);
      this.addFancy = Object.assign({}, this.addFancyInit);
    } else {
      this.addFancyErr = "Please enter all required fields."
    }

  }
  /**
  * This method is used to call the service for comfirm bets.
  */
  confirmFancy() {
    this.showErrMsg='';
    if (this.addFancyMarkets.length > 0) {
      apiCaller(adminMarkets.addFancy, 'POST', this.addFancyMarkets).then(response => {
        this.showLoader = false;
        if(response && response.data){
          this.showErrMsg=response.data;
        }
      });
    }
  }
  /**
  * This method is used to call the service for comfirm bets.
  */
  confirmMO() {
    const checkErr=this.checkMatchOddsErr(this.addMatchOdds);
    this.showErrMsg='';
    //console.log(checkErr);
    if(!checkErr){
    apiCaller(adminMarkets.addMo, 'POST', this.addMatchOdds).then(response => {
      this.showLoader = false;
      this.setMatchOddsObj()
      if(response && response.data){
        this.showErrMsg=response.data;
      }
    });
  }
  else{
    this.showErrMsg="Please fill all the required fields";
  }
  }
  checkMatchOddsErr(mo){
    let showErr=false
    mo.forEach((m)=>{
      if(!m.backOdds || !m.backSize || !m.layOdds || !m.laySize || !m.maxStake || !m.minStake){
       showErr=true
      }else{
        showErr=false
      }
       
    })
    return showErr

  }
}

export default AddMarketsStore;
