import { extendObservable } from "mobx";
import Validator from 'validatorjs';
import { myaccount } from "../../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../../sharedfiles/apiStore';
import { getUserToken, getUserRole, getUserData } from "../../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class ChangeExposureLimitStore {
    constructor(props) {
        extendObservable(this, {
            loggedInUserRole: getUserRole(),
            changeExposureLimitFields: {
                exposureVal: "",
                exposurePassword: ""
            },
            changeExposureLimitErrors: {
                exposureVal: "",
                exposurePassword: ""
            },
            userData: getUserData(),
            updateExposureLimitVal: '',
            accountSummaryStore: null

        });
    }

    /**
      * This method is used to reset the login and signup fields
      *
      */
    resetFields() {
        this.changeExposureLimitFields = {
            exposureVal: "",
            exposurePassword: ""
        };
        this.changeExposureLimitErrors = {
            exposureVal: "",
            exposurePassword: ""
        }
        this.updateExposureLimitVal = '';
    }

    onExposureLimitChange(field, event) {
        switch (field) {
            case 'exposureVal':
                this.changeExposureLimitFields.exposureVal = event.target.value;
                this.changeExposureLimitErrors.exposureVal = "";
                break;
            case 'exposurePassword':
                this.changeExposureLimitFields.exposurePassword = event.target.value;
                this.changeExposureLimitErrors.exposurePassword = "";
                break;
            default:
                console.log("change exposure limit");
        }
    }

    /**
    * This method is used to get the User profile data.
    */
    updateExposureLimit() {

        var rules = {
            exposureVal: 'required',
            exposurePassword: 'required|min:8'
        };

        var validation = new Validator(this.changeExposureLimitFields, rules, {
            "required.exposureVal": "Please enter exposure value",
            "min.exposurePassword": "Password must be at least 8 characters.",
            "required.exposurePassword": "Please enter new password"
        });
        // var param = {
        //     // userToken: getUserToken(),
        //     userToken: "6bbed1ad-84f3-4a2c-ac1e-c5449e88e662",

        // }
        var param = {
            "loginToken": getUserToken(),
            "exposure": this.changeExposureLimitFields.exposureVal,
            "userPassword": this.changeExposureLimitFields.exposurePassword,
            "userId": this.accountSummaryStore.userId ? this.accountSummaryStore.userId : this.userData.userId
        }

        if (validation.passes()) {
            apiCaller(myaccount.updateExposureLimit, 'POST', param).then(response => {
                //   this.userProfileDetails=[];
                if (response && response.data) {
                    if (response.data == 'Exposure limit updated successfully') {
                        this.accountSummaryStore.showHideChangeExposureLimit(false);
                    }
                    this.updateExposureLimitVal = response.data;
                }
            })
        } else {
            if (validation.errors.has('exposureVal') && validation.errors.get("exposureVal").length > 0) {
                this.changeExposureLimitErrors.exposureVal = validation.errors.get("exposureVal")[0]
            }
            if (validation.errors.has('exposurePassword') && validation.errors.get("exposurePassword").length > 0) {
                this.changeExposureLimitErrors.exposurePassword = validation.errors.get("exposurePassword")[0]
            }
        }
    }

}

export default ChangeExposureLimitStore;
