import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from "react-bootstrap";
import ChangeStatus from '../changestatus/ChangeStatus';
import ChangeStatusStore from '../changestatus/ChangeStatusStore';
import dataLoader from '../../../assets/images/loader-orange.svg';

const changeStatusStore = new ChangeStatusStore();
class DownlineListTable extends Component {

  render() {
    const { userDetails, downlineListTableStore, downlineStore, roleId, showLoader } = this.props
    return (
      <div className="RDE-DB-table">
        <table className="RDE-DB-table-default">
          <thead>
            <tr>
              <th>Account</th>
              <th>Credit Limit</th>
              <th>Exposure</th>
              <th>Avail. bal.</th>
              <th>Profit & Loss</th>
              <th>Settled commission</th>
              <th>Pending commission</th>
              <th>Mark as paid</th>
              <th>Status</th>
              <th>Actions</th>


            </tr>
          </thead>
          <tbody>
            {
              !showLoader && userDetails?.map((item, index) => {
                return <tr key={`index` + index}>
                  <td><span className={"RDE-badge " + (item?.roleId === 1 || item?.roleId === 2 ? "green" : item?.roleId === 3 ? "seaGreen" : "darkYellow")}>{item?.roleId === 1 ? "AD" : item.roleId === 2 ? "SUP" : item.roleId === 3 ? "MA" : "PL"}</span>{item.roleId === 4 ? item.account : <a className="cursor-pointer" onClick={this.props.downlineStore.getDownlineListOfUser.bind(this.props.downlineStore, item, false)}>{item.account}</a>}</td>
                  <td>{item?.creditLimmit?.toFixed(2)}</td>
                  <td>{item?.exposure?.toFixed(2)}</td>
                  <td>{item?.availableBalance?.toFixed(2)}</td>
                  <td>{item?.refProfitLoss?.toFixed(2)}</td>
                  <td>{item?.settledCommission?.toFixed(2)}</td>
                  <td>{item?.pendingCommission?.toFixed(2)}</td>
                  <td>
                    <div>
                    {item?.pendingCommission>0 && <button className="btn RDE-btn-primary RDE-btn-small" onClick={()=>this.props.downlineStore.handleMarkAsPaid(item)}>Mark as paid</button>}
                   {this.props.downlineStore.showCommissionMsg.userId===item.userId && <div className="text-center RDE-DB-errorMessage">{this.props.downlineStore.showCommissionMsg.msg}</div>}
                    </div>
                  </td>
                  <td><span className={"RDE-badge " + (item?.statusId === 1 ? 'green' : item?.statusId === 2 ? 'red' : 'grey')}>{item?.statusId === 1 ? 'Active' : item?.statusId === 2 ? 'Suspended' : 'Locked'}</span></td>
                  <td>
                    <button className="btn RDE-btn-white" onClick={downlineListTableStore.toggleChangeSatausPopUp.bind(downlineListTableStore, item)}><i className="RDE-icon-settings"></i></button>
                    <button className="btn RDE-btn-white" onClick={this.props.downlineStore.goToUserAccount.bind(this.props.downlineStore, item, false)}><i
                      className="RDE-icon-user-profile"></i></button>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
        {!showLoader && userDetails?.length === 0 ? <div className="RDE-noDataMessageBox">Currently data is not available.</div> : ''}
        {showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}

        <Modal
          show={downlineListTableStore?.showChangeStatus}
          onHide={downlineListTableStore?.toggleChangeSatausPopUp.bind(downlineListTableStore, {})}
          backdrop={true}
          backdropClassName="fade"
          centered
          dialogClassName="RDE-loginSignup-modal">
          <ChangeStatus userDetails={downlineListTableStore.changeStatusUser}
            changeStatusStore={changeStatusStore}
            downlineListTableStore={downlineListTableStore}
            downlineStore={downlineStore} />
        </Modal>
      </div>
    )
  }
}
export default observer(DownlineListTable);
