import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole,getUserData } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class TransactionTableStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole:getUserRole(),
      transactionTableResults:[],
      userId:"",
      showLoader:false
    });
  }
 

  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getTransactionResults() {
    let userData = getUserData();
    var userId =this.userId?this.userId:userData.userId
    this.showLoader=true;
    
     apiCaller(myaccount.getAccountStatement+"?userToken="+getUserToken() +"&userId="+userId,'GET').then(response => {
   // apiCaller(myaccount.getAccountStatement+"?userToken=7203dcfd-dde2-44ee-ae85-7218096b9579",'GET').then(response => {
      if(response && response.data && response.data.length>0){
        this.transactionTableResults = response.data;
        this.showLoader=false;
      }
    })
  }

}

export default TransactionTableStore;
