import React, { Component } from 'react';
import { observer } from 'mobx-react';
import close from '../../../../assets/images/modal-close.svg';

import { getUserData } from "../../../../sharedfiles/helper";

class ChangePassword extends Component {
	constructor(props) {
		super(props);
	}
	componentWillMount() {
        this.props.changepasswordStore.accountSummaryStore = this.props.accountSummaryStore;
        // this.props.accountSummaryStore.getUserProfile();
        this.props.changepasswordStore.resetFields();
        this.props.changepasswordStore.fromComponent=this.props.fromComponent;
        this.props.changepasswordStore.userDetails=this.props.accountSummaryStore.userProfileDetails
	}
	componentDidMount() { }
	componentWillUnmount() { }
	onChange() {

	}
	render() {
        const { mainLayoutStore, myAccountStore, accountSummaryStore,changepasswordStore,fromComponent } = this.props;
        return (
	
        <div className="RDE-changePassword">
        <div className="RDE-modal-content">
            <div className="RDE-modal-header">
                <div className="RDE-modal-header__txt">Change Password</div>
                <img src={close} className="RDE-modal-closeIcon"  onClick={()=>{accountSummaryStore.showChangePassword=false}}/>
            </div>
            <div className="RDE-modal-body">
                <div className="grid-noGutter">
                    <div className="col-12">
                        <div className="RDE-DB-infoBox m-0">
                            <div className="RDE-DB-infoBox__content">
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left"><span>{  fromComponent !=='downline'?'Old Password' :'Enter Your Password'}</span><span
                                            className="RDE-DB-colon">:</span></div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div className=""><input type="password" className="RDE-formControl"
                                                placeholder={  fromComponent !=='downline'?'Enter Old Password' :'Enter Your Password'} value={changepasswordStore.changePasswordFields.oldPassword} onChange={changepasswordStore.onPasswordFieldChange.bind(changepasswordStore, 'oldPassword')}/></div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="RDE-DB-errorMessage text-center">{changepasswordStore.changePasswordErrors.oldPassword}</div>
                                <div className="space-5"></div>
                                <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left"><span>Enter New Password</span><span
                                            className="RDE-DB-colon">:</span></div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div className=""><input type="password" className="RDE-formControl"
                                                placeholder="Enter new password"  value={changepasswordStore.changePasswordFields.newPassword} onChange={changepasswordStore.onPasswordFieldChange.bind(changepasswordStore, 'newPassword')}/></div>
                                    </div>
                                    

                                </div>
                                <div className="RDE-DB-errorMessage  text-center">{changepasswordStore.changePasswordErrors.newPassword}</div>
                                { fromComponent !=='downline'?<div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left"><span>Confirm password</span><span
                                            className="RDE-DB-colon">:</span></div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div className=""><input type="password" className="RDE-formControl"
                                                placeholder="Enter confirm password"  value={changepasswordStore.changePasswordFields.confirmPassword} onChange={changepasswordStore.onPasswordFieldChange.bind(changepasswordStore, 'confirmPassword')}/></div>
                                        
                                    </div>
                                    
                                </div>:''}
                               {changepasswordStore.changePasswordErrors.confirmPassword &&<div className="RDE-DB-errorMessage text-center">{changepasswordStore.changePasswordErrors.confirmPassword}</div>}
                               {/* { fromComponent =='downline'?  <div className="RDE-DB-infoBox-item">
                                    <div className="RDE-DB-infoBox-item__left"><span>Email</span><span
                                            className="RDE-DB-colon">:</span></div>
                                    <div className="RDE-DB-infoBox-item__right">
                                        <div className=""><input type="text" className="RDE-formControl"
                                                placeholder="Enter emailId"   onChange={changepasswordStore.onPasswordFieldChange.bind(changepasswordStore, 'email')}/></div>
                                        <div className="RDE-DB-errorMessage">{changepasswordStore.changePasswordErrors.email}</div>
                                    </div>
                                </div>:''} */}
                                <div className="space-5"></div>
                            <div className="RDE-DB-errorMessage text-center">{changepasswordStore.responseMsg}</div>
                                {/* <div className="space-5"></div>
                                <div className="RDE-DB-successMessage text-center"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="RDE-modal-footer">
                <div className="grid-noGutter-middle-center">
                    <div className="col-4"><button className="btn RDE-btn-primary" onClick={changepasswordStore.updatePassword.bind(changepasswordStore)}>Change</button></div>
                </div>
            </div>
        </div>
    </div>
		)
	}
}
export default observer(ChangePassword);