import { extendObservable } from "mobx";

class LogsStore {
  constructor(props) {
    extendObservable(this, {
      balance:null,
    });
  }
}

export default LogsStore;
