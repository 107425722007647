import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "react-bootstrap";

import ByMarket from './bymarket/ByMarket';
import ByMarketStore from './bymarket/ByMarketStore';
import ByDownline from './bydownline/ByDownline';
import ByDownlineStore from './bydownline/ByDownlineStore';
import { getUserData } from "../../../sharedfiles/helper";
import dataLoader from '../../../assets/images/loader-orange.svg';

const byDownlineStore = new ByDownlineStore();
const byMarketStore = new ByMarketStore();
class ProfitLossReport extends Component {
  componentWillMount() {
    this.props.profitLossReportStore.component = this.props.component;
    var userData = getUserData();
    this.props.profitLossReportStore.userBreadCrumb = [];
    this.props.profitLossReportStore.userBreadCrumb.push({
      "userId": 0,
      "roleId": userData.roleId,
      "userName": userData.userName
    });
    this.props.profitLossReportStore.resetFields();
    this.props.profitLossReportStore.activeBreadCrumb = this.props.profitLossReportStore.userBreadCrumb[0];
  }

  render() {
    const { profitLossReportStore, component } = this.props;
    return (
      <div className="RDE-DB-myBets__reportbyDownline">
        <div className="RDE-DB-myBets-filters mb-10">
          <div className="RDE-DB-myBets-filters__left">
            {component === "bymarket" ? <><span className="RDE-DB-myBets-filters__label">Sports :</span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {profitLossReportStore.selectedSport.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {profitLossReportStore.sportsList.map((sport, index) => {
                      return <li key={"bymarketsports_" + index}
                        onClick={profitLossReportStore.selectSport.bind(profitLossReportStore, sport)}><Dropdown.Item eventKey={sport.name}>{sport.name}</Dropdown.Item></li>
                    })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown></> : ""}
            <span className="RDE-DB-myBets-filters__label">Period From :</span>
            <div className="RDE-DB-myBets-filters__datePicker">
              <div className="react-datepicker-wrapper">
                <DatePicker selected={profitLossReportStore.fromDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={profitLossReportStore.selectFromDate.bind(profitLossReportStore)}
                  className="RDE-DB-datePickerInput"
                  maxDate={new Date()} />
              </div>
              <span className="RDE-DB-myBets-filters__label">To :</span>
              <div className="react-datepicker-wrapper">
                <DatePicker selected={profitLossReportStore.toDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={profitLossReportStore.selectToDate.bind(profitLossReportStore)}
                  className="RDE-DB-datePickerInput"
                  maxDate={new Date()} />
              </div>
              <span class="RDE-DB-datePickerErrorMessage">{profitLossReportStore.dateErrMsg}</span>
            </div>

            <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={profitLossReportStore.getPL.bind(profitLossReportStore)}>Get P&amp;L</button>
          </div>
          <div className="RDE-DB-myBets-filters__right">
            <ul className="RDE-DB-myBets-filters__todayYesterdayList">
              <li className={profitLossReportStore.selectedOption === "today" ? "active" : ""}
                onClick={profitLossReportStore.selectTodayYesterday.bind(profitLossReportStore, 'today')}>Today</li>
              <li className={profitLossReportStore.selectedOption === "yesterday" ? "active" : ""}
                onClick={profitLossReportStore.selectTodayYesterday.bind(profitLossReportStore, 'yesterday')}>From Yesterday</li>
            </ul>
          </div>
        </div>
        {component === "bydownline" ? <div className="col">
          <div className="RDE-DB-userBreadcrumbNav">
            <ul>
              {
                profitLossReportStore.userBreadCrumb.map((item, index) => {
                  return <li key={'userBreadCrumb_' + index} onClick={profitLossReportStore.breadCrumbClick.bind(profitLossReportStore, index, item.userId)}>
                    <span className={"RDE-badge " + (item.roleId === 1 || item.roleId === 2 ? "green" : item.roleId === 3 ? "seaGreen" : "darkYellow")}>{item.roleId === 1 ? "AD" : item.roleId === 2 ? "SUP" : item.roleId === 3 ? "MA" : "PL"}</span><span
                      className="text-underline">{item.userName}</span>
                  </li>
                })
              }
            </ul>
          </div>
        </div> : ""}
        <div className="RDE-DB-table mb-10">
          {component === "bydownline" ? <ByDownline byDownlineStore={byDownlineStore} data={profitLossReportStore.data} profitLossReportStore={profitLossReportStore} /> :
            component === "bymarket" ? <ByMarket data={profitLossReportStore.dataByMarket} byMarketStore={byMarketStore} profitLossReportStore={profitLossReportStore} /> : ""}
          {/* </table> */}
        </div>
        {component === "bydownline" &&
          !profitLossReportStore.showLoader &&
          (!profitLossReportStore.data.myImmediateUserDetailsList || (profitLossReportStore.data.myImmediateUserDetailsList
            && profitLossReportStore.data.myImmediateUserDetailsList.length === 0)) ? <div className="RDE-noDataMessageBox">
          Currently data is not available for your selection.
        </div> : ''}
        {profitLossReportStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
        {component === "bymarket" &&
          !profitLossReportStore.showLoader &&
          (!profitLossReportStore.dataByMarket.myReportEventDetails || (profitLossReportStore.dataByMarket.myReportEventDetails
            && profitLossReportStore.dataByMarket.myReportEventDetails.length === 0)) ? <div className="RDE-noDataMessageBox">
          Currently data is not available for your selection.
        </div> : ''}
      </div>
    )
  }
}
export default observer(ProfitLossReport);