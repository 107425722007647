import { extendObservable } from "mobx";
import Validator from 'validatorjs';
import cookie from 'react-cookies';

import { apiCaller } from '../../../sharedfiles/apiStore';
import {timeZones,getUserData, getUserRole, getUserToken, currencyTypes} from '../../../sharedfiles/helper';
import { downline } from "../../../sharedfiles/EndpointConfig";

const re = /^[0-9\b]+$/;
const commre = /^[0-9.\b]+$/;
const userRe = /^[A-Za-z ]+$/;
class AddUserStore {
  constructor(props) {
    extendObservable(this, {
      addUserFields:{
        email:"",
        userName:"",
        password:"",
        confirmPassword:"",
        fName:"",
        lName:"",
        phoneNumber:"",
        exposure:"",
        exposureLimit:"",
        depositAmount:"",
        commission:2,
        fancyCommission:2,
        timeZone:timeZones[0].id,
        currency:currencyTypes[0].id
      },
      addUserFieldsErrors:{
        email:"",
        userName:"",
        password:"",
        confirmPassword:"",
        fName:"",
        lName:"",
        phoneNumber:"",
        exposure:"",
        exposureLimit:"",
        depositAmount:"",
        commission:"",
        fancyCommission:"",
        timeZone:"",
        currency:""
      },
      selectedTimeZone:'',
      timeZoneValues:timeZones,
      loggedInRole:null,
      mainLayoutStore:{},
      downlineStore:{},
      currencyList:currencyTypes,
      selectedCurrency:currencyTypes[0],
      creationErr:"",
      showStep:1
    });
  }
  /**
  * This method is used to reset the add user fields
  */
  resetFields(){
    this.addUserFields = {
        email:"",
        userName:"",
        password:"",
        confirmPassword:"",
        fName:"",
        lName:"",
        phoneNumber:"",
        exposure:"",
        exposureLimit:"",
        depositAmount:"",
        commission:2,
        fancyCommission:2,
        timeZone:this.loggedInRole ===1 ?timeZones[0].id:this.addUserFields.timeZone,
        currency:currencyTypes[0].id
      }
    this.addUserFieldsErrors = {
        email:"",
        userName:"",
        password:"",
        confirmPassword:"",
        fName:"",
        lName:"",
        phoneNumber:"",
        exposure:"",
        exposureLimit:"",
        depositAmount:"",
        commission:"",
        fancyCommission:"",
        timeZone:"",
        currency:""
      }
      this.creationErr='';
      this.showStep=1;
  }
  setDefaultTimezone(){
    let userData=getUserData()
// if(userData){
//   this.selectedTimeZone= userData.timeZone?userData.timeZone:timeZones[0].name
// }
// else{
  //setTimeout(()=>{
    
      this.selectedTimeZone= userData.timeZone?userData.timeZone:timeZones[0].name
   // }

 // },3000)
//}
   
   timeZones.map((zone,i)=>{
     if(zone.name.split(" ")[0].match(userData.timeZone)){
       this.addUserFields.timeZone=zone.id
   //console.log(this.timeZone,"timeZone")
     }
   })
  }
  addUser(){
     // var rules={
     //    email:'required|email',
     //    userName:'required',
     //    password:'required|min:8',
     //    confirmPassword:'required|same:password',
     //    fName:'required',
     //    lName:'required',
     //    phoneNumber:'max:16|min:8',
     //    exposure:this.loggedInRole===3?'required':'',
     //    commission:'required|min:0|max:3',
     //    fancyCommission:'required|min:0|max:3',
     //    timeZone:'required',
     //    currency:'required'
     //  };
     //  var validation = new Validator(this.addUserFields, rules,{
     //    "required.email":"Please enter Email ID",
     //    "email.email":"Email format is invalid.",
     //    "required.fName":"Please enter First name",
     //    "required.password":"Please enter Password",
     //    "required.commission":"Please enter commission.",
     //    "required.fancyCommission":"Please enter fancy commission.",
     //    "required.timeZone":"Please select timezone.",
     //    "required.confirmPassword":"Please enter confirm Password",
     //    "same.confirmPassword":"Password and Confirm Password should match",
     //    "min.password":"Password must be at least 8 characters.",
     //    "min.phoneNumber":"Phone number must be at least 8 characters.",
     //    "required.lName":"Please enter Last name",
     //    "required.userName":"Please enter User name",
     //    "required.currency":"Please select currency",
     //  });
      var param={
                  "userName": this.addUserFields.userName,
                  "password": this.addUserFields.password,
                  "emailId": this.addUserFields.email,
                  "phoneNumber": this.addUserFields.phoneNumber,
                  "firstName": this.addUserFields.fName,
                  "lastName": this.addUserFields.lName,
                  "exposure":Number(this.addUserFields.exposureLimit)>0?Number(this.addUserFields.exposureLimit):0,//Number(this.addUserFields.exposure) > 0?this.addUserFields.exposure:0,
                  "commission": Number(this.addUserFields.commission),
                  "fancyCommission": Number(this.addUserFields.fancyCommission),
                  "depositedAmount":Number(this.addUserFields.depositAmount),
                  "roleId": this.loggedInRole === 1?2:this.loggedInRole === 2?3:this.loggedInRole === 3?4:'',
                  "timezoneId": this.addUserFields.timeZone,
                  "userToken": getUserToken(),
                  "currencyId": this.loggedInRole === 1?this.addUserFields.currency:0
                }
      apiCaller(downline.addUser,'POST', param).then(response => {
        if(response.data && response.data.hasOwnProperty('isCreated') && response.data.isCreated){
          this.downlineStore.getDownlineList();
          this.mainLayoutStore.toggleAddUser();
          this.showStep = 2;
          this.resetFields();

        }else{
           if(response && response.data && response.data.reasonCode ===1){
              this.creationErr = response.data.reason;
           }else{
            this.creationErr = "Unable to crete user, plese try after some time."
           }
            
        }
      });
  }
  /**
  * This method is used to navigate to next step
  *
  **/
  goToNextStep(){
      var rules={
        email:'required|email',
        userName:'required',
        password:'required|min:8',
        confirmPassword:'required|same:password',
        fName:'required',
        lName:'required',
        phoneNumber:'max:16|min:8',
       // exposure:this.loggedInRole===3?'required':'',
        commission:'required|min:0|max:3',
        fancyCommission:'required|min:0|max:3',
        timeZone:'required',
        currency:'required'
      };
      var validation = new Validator(this.addUserFields, rules,{
        "required.email":"Please enter Email ID",
        "email.email":"Email format is invalid.",
        "required.fName":"Please enter First name",
        "required.password":"Please enter Password",
        "required.commission":"Please enter commission.",
        "required.fancyCommission":"Please enter fancy commission.",
        "required.timeZone":"Please select timezone.",
        "required.confirmPassword":"Please enter confirm Password",
        "same.confirmPassword":"Password and Confirm Password should match",
        "min.password":"Password must be at least 8 characters.",
        "min.phoneNumber":"Phone number must be at least 8 characters.",
        "required.lName":"Please enter Last name",
        "required.userName":"Please enter User name",
        "required.currency":"Please select currency",
      });
      if(validation.passes()){
        this.showStep = 2;
      }else{
        this.checkError('email',validation);
        this.checkError('userName',validation);
        this.checkError('password',validation);
        this.checkError('confirmPassword',validation);
        this.checkError('fName',validation);
        this.checkError('lName',validation);
        this.checkError('phoneNumber',validation);
        this.checkError('exposure',validation);
        this.checkError('commission',validation);
        this.checkError('timeZone',validation);
        this.checkError('currency',validation);
      }
  }
  prevClick(){
    this.showStep = 1;
  }
  /**
  * This method is used to check the error message for field.
  * @param {field} string contains the field name to check for errors.
  */
  checkError(field,validation){
    if(validation.errors.has(field) && validation.errors.get(field).length>0){
      this.addUserFieldsErrors[field] = validation.errors.get(field)[0];
    }
  }
  onAddUserFieldChange(field, event){
    this.creationErr='';
    switch(field){
      case 'email':
      case 'password':
      case 'confirmPassword':
      case 'userName':
      case 'timeZone':
        this.assignFieldValue(field,event.target.value);
        break;
      case 'fName':
      case 'lName':
        if(event.target.value === '' || userRe.test(event.target.value)){
          this.assignFieldValue(field,event.target.value);
        }
        break;
      case 'exposure':
      case 'phoneNumber':
     
      case 'exposureLimit':
        if(event.target.value === '' || re.test(event.target.value)){
          this.assignFieldValue(field,event.target.value);
        }
        break;
      case 'depositAmount':
        if(event.target.value === '' || re.test(event.target.value)){
          this.assignFieldValue(field,event.target.value);
          this.calculateExposureLimit();
        }
        break;
      case 'commission':
      case 'fancyCommission':
        if(event.target.value === '' || commre.test(event.target.value) && (Number(event.target.value)>=0 && Number(event.target.value)<=100)){
          this.assignFieldValue(field,event.target.value);
        }
      break;
      default:
        console.log("signup default");
    }
  }
  /**
  * this method is used to set the value of a field to variable.
  * @param {field} String contains the field name to assign value.
  * @param {value} String contains the value to assign.
  */
  assignFieldValue(field,value){
    this.addUserFields[field] = value;
    this.addUserFieldsErrors[field] = "";
  }
  calculateExposureLimit(){
    if(this.addUserFields.depositAmount && this.addUserFields.depositAmount>0){
      this.addUserFields.exposureLimit = this.addUserFields.depositAmount * 2;
    }
  }
  /**
  * This method is called when user selects timezone in add user
  * @param {Object} item contains the selected timezone id and values.
  */
  selectTimeZone(item){
    this.selectedTimeZone = item.name;
    this.addUserFields.timeZone = item.id;
  }
  /**
  * This method is called when user selects currency in add user
  * @param {Object} item contains the selected currency id and code.
  */
  selectCurrency(item){
    this.selectedCurrency = item;
    this.addUserFields.currency = item.id;
  }
}

export default AddUserStore;
