import React, { Component } from 'react';
import { observer } from 'mobx-react';

class BetFairPercentages extends Component {
    componentDidMount() {
        this.props.betFairPercentagesStore.getCurrency();
    }
    render() {
        const { betFairPercentagesStore } = this.props;
        return (
            <div className="RDE-DB-Admin-addPointsToBaseCurrency">
                <div className="RDE-sportsPage-leagueHeader">
                    Add Points to Base Currency
                </div>
                <div className="grid-noGutter-center">
                    <div className="col-8">
                        <div className="grid-noGutter mb-10">
                            {/* <div className="col-4"> */}
                            <div className="text-center">Please note the betfair percentage should be in between 10 to 100</div>
                            {/* <input type="text" className="RDE-formControl disabled" placeholder="" value="10000"/> 
                    <label><b>10000</b></label>*/}
                            {/* </div>  */}
                        </div>

                        <div className="RDE-DB-table mb-10">
                            <table className="RDE-DB-table-default">
                                <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>Betfair percentage</th>
                                        <th>Exchange percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        betFairPercentagesStore.currencyList.map((item, index) => {
                                            return <tr key={"addPointsToBase_" + index}>
                                                <td>{item.currencyCode}</td>
                                                <td>
                                                    <div className='RDE-banking-depositWithdraw__formControl'>
                                                        <ul>
                                                            <li><input type="text" className="RDE-formControl" defaultValue={item.rate} onChange={(e) => betFairPercentagesStore.rateChange(index, e)} /></li>
                                                            <li> <button onClick={() => betFairPercentagesStore.updateBP(item, index)} className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto mr-5"
                                                            >Update</button></li>
                                                        </ul>
                                                    </div>
                                                    <div className="text-center RDE-DB-errorMessage">{item.errorMessage}</div>
                                                </td>
                                                <td>{betFairPercentagesStore.totalPercentage - item.rate}%</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
export default observer(BetFairPercentages);