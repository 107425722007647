import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ProfitLossReport from './profitlossreport/ProfitLossReport';
import ProfitLossReportStore from './profitlossreport/ProfitLossReportStore';

const profitLossReportStore = new ProfitLossReportStore();
class MyReports extends Component {
    componentWillMount() {
        profitLossReportStore.component = 'bydownline';
    }
    componentDidMount() { }
    componentWillUnmount() { }
    navigateTo(page) {
        // console.log("navigate to===",page)
        profitLossReportStore.resetFields();
        if (page === 'bydownline') {
            profitLossReportStore.component = page;
            //this.props.history.push('/myreports/reportbydownline');
        } else if (page === 'bymarket') {
            profitLossReportStore.component = page;
            //this.props.history.push('/myreports/reportbymarket');
        }

    }
    render() {
        // var isDownline= false;
        // if(!window.location.pathname.includes('reportbymarket')){
        //     isDownline= true;
        //  }
        return (
            <div>
                <div className="RDE-DB-myReport">
                    <div className="RDE-DB-myBets-tabs">
                        <ul>
                            <li>
                                <div className="RDE-DB-myBets-tabs-menu__item">
                                    {/*<NavLink  className='no-link' to={"/myreports/reportbydownline"}  activeClassName="active" exact={true}><span className="RDE-skewTxt">Profit/Loss Report by Downline</span></NavLink>*/}
                                    <a className={"no-link" + (profitLossReportStore.component === 'bydownline' ? " active" : "")} onClick={this.navigateTo.bind(this, 'bydownline')}>
                                        <span className="RDE-skewTxt">Profit/Loss Report by Downline</span>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="RDE-DB-myBets-tabs-menu__item">
                                    {/*<NavLink  className='no-link' to="/myreports/reportbymarket"  activeClassName="active" exact={true}><span className="RDE-skewTxt">Profit/Loss Report by Market</span></NavLink>*/}
                                    <a className={"no-link" + (profitLossReportStore.component === 'bymarket' ? " active" : "")} onClick={this.navigateTo.bind(this, 'bymarket')}>
                                        <span className="RDE-skewTxt">Profit/Loss Report by Market</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {profitLossReportStore.component === 'bydownline' ? <ProfitLossReport profitLossReportStore={profitLossReportStore} component='bydownline' /> :
                        profitLossReportStore.component === 'bymarket' ? <ProfitLossReport profitLossReportStore={profitLossReportStore} component='bymarket' /> : ''}
                    {/*<Switch>
                            <Route exact path="/myreports" render={(props)=> <ProfitLossReport profitLossReportStore={profitLossReportStore} component='bydownline'/>} />
                            <Route exact path="/myreports/reportbydownline" render={(props)=> <ProfitLossReport profitLossReportStore={profitLossReportStore} component='bydownline'/>} />
                            <Route exact path="/myreports/reportbymarket" render={(props)=> <ProfitLossReport profitLossReportStore={profitLossReportStore} component='bymarket'/>} />
                    </Switch>*/}

                </div>
            </div>
        )
    }
}
export default observer(MyReports);