import React, { Component } from 'react';
import { observer } from 'mobx-react';
import close from '../../../assets/images/modal-close.svg';

class Logs extends Component {
  componentWillMount() {
  }
  componentWillReceiveProps(nextProps) {
    //console.log("logsjs=", nextProps.data)
  }
  componentDidMount() { }
  componentWillUnmount() { }
  render() {
    const { data, bankingStore, logsUser } = this.props;
   // console.log(data)
    return (
      <div className="RDE-logInfo">
        <div className="RDE-modal-content">
          <div className="modal-header">
            <div className="RDE-modal-header__txt">
              <div className='RDE-modal-header__title'>Banking Logs</div>
              <span className="RDE-badge grey">{logsUser}</span></div>
            <img src={close} className="RDE-modal-closeIcon" onClick={bankingStore.hideLogs.bind(bankingStore)}/>
          </div>
          <div className="modal-body">
            <div className="grid-noGutter">
              <div className="col-12 text-center">
                <div className="RDE-DB-table">
                  <table className="RDE-DB-table-default">
                    <thead>
                      <tr>
                        <th>Date/Time</th>
                        <th>Deposit</th>
                        <th>Withdraw</th>
                        <th>Balance</th>
                        <th>Remark</th>
                        <th>From/To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data && data.map((item, index) => {
                          return <tr>
                            <td>{item.createdDate}</td>
                            <td>{item.deposit > 0 ? item.deposit : '-'}</td>
                            <td>{item.withdraw > 0 ? item.withdraw : '-'}</td>
                            <td>{item.balance}</td>
                            <td>{item.remark}</td>
                            {item.from && item.to &&<td>{item.from}{'>'}{item.to}</td>}
                          </tr>
                        })
                      }
                    </tbody>

                  </table>
                  {data.length ===0?<div className="RDE-noDataMessageBox">
                    Currently data is not available.
                  </div>:''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default observer(Logs);