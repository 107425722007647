import { extendObservable } from "mobx";

class ByDownlineStore {
  constructor(props) {
    extendObservable(this, {
      accordionOpenIndex:[],
      itemsPerPage:4,
      toalPages:0,
      startIndex:0,
      endIndex:4
    });
  }
  /**
  * this method is used to open the accordion.
  * @param {Number} index contains index to open the accordion.
  */
  openAccordion(index){
      if(index>=0){
        var eventIndex = this.accordionOpenIndex.indexOf(index);
        if(eventIndex===-1){
            this.accordionOpenIndex.push(index);
        }else{
            this.accordionOpenIndex.splice(eventIndex,1);
        }
        
      }
  }
  /**
  *
  *
  **/
  handlePageChange(pageNumber){
    //console.log(pageNumber);
    this.startIndex = pageNumber.selected * this.itemsPerPage;
    this.endIndex = this.startIndex + this.itemsPerPage;

  }
  calculatePagination(data){
    if(data && data.length>0){
      this.toalPages = Math.ceil(data.length/this.itemsPerPage);
    }
  }
  
}

export default ByDownlineStore;
