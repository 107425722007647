import { extendObservable } from "mobx";
import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken,getUserData } from '../../sharedfiles/helper';
import { usersServices } from "../../sharedfiles/EndpointConfig";
import cookie from 'react-cookies';

class MainLayoutStore {
  constructor(props) {
    extendObservable(this, {
      showAddUser:false,
      userData: {},
      balanceUpdate:false,
      showLogoutPopup:false,
      isLoggedIn:false,
    });
  }
  /**
  * This method is used to toggle the add user popup
  *
  */
  toggleAddUser(){
    this.showAddUser =! this.showAddUser;
  }
  /**
  * This method is used to get the user balance.
  */
  getUserBalance(){
    var token="";
    var cookeTocken = getUserToken();
    if(cookeTocken && cookeTocken.length>0){
      token = cookeTocken;
      apiCaller(usersServices.getUserBalance+"?loginToken="+token,'GET').then(response => {
        if(response && response.data && response.data.hasOwnProperty('userBalance') && response.data.hasOwnProperty('timeZone')){
            this.userData.userAccountBalance = response.data.userBalance;
            this.userData.timeZone=response.data.timeZone

            cookie.save("userData",this.userData,{path:'/'});
            this.isLoggedIn= true;
            // this.balanceUpdate = !this.balanceUpdate;
            if(this.balanceUpdate){
              this.balanceUpdate = false;
            }else{
              this.balanceUpdate = true;
            }
        }else {
          this.showLogoutPopup = true;
        }
      });
    }
  }

}

export default MainLayoutStore;
