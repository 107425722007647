import { extendObservable } from "mobx";

import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken,getUserData } from "../../sharedfiles/helper";
import { currency, } from "../../sharedfiles/EndpointConfig";
const re = /^[0-9\b.]+$/;
class BetFairPercentagesStore {
    constructor(props) {
        extendObservable(this, {
            currencyList: [],
            showErr: '',
            totalPercentage:100
        });
    }

    /**
* This method is used to get the currency list.
*/
    getCurrency() {
        
        this.showErr = "";
        apiCaller(currency.getCurrency, 'GET').then(response => {
            this.showLoader = false;
            if (response && response.data && response.data.length > 0) {
                this.getBetfairCurrency(response.data);

            }
        });
    }
    async getBetfairCurrency(data) {
        var cookieToken = getUserToken();
        await apiCaller(currency.getBetfairPercentage + "?userLoginToken=" + cookieToken, 'GET').then(response => {
            if (response && response.data && response.data.length > 0) {
                let existingData = response.data;
                existingData.forEach((item) => {
                    data.forEach((eitem) => {
                        if (item.currencyId === eitem.currencyId) {
                            eitem.rate = item.betfairPercentage;
                            eitem.id=item.id;
                        }

                    })
                })

            }
            this.getCurrencyFilterData(data);
        }).catch(err => {
            this.getCurrencyFilterData(data);
        })
    }
    getCurrencyFilterData(data) {
        for (var currency of data) {
            currency.rate = currency.rate? currency.rate: 10;
            currency.errorMessage='';
           
        }
        this.currencyList = data;
    }

    rateChange(index, e) {
        if (e.target.value === '' || (re.test(e.target.value) )) {
            if(Number(e.target.value) > 10 || Number(e.target.value) <= 100){
            this.currencyList[index].rate = e.target.value;
            }else{
                this.currencyList[index].errorMessage ="The value should be minimum 10 and maximum 100";
            }
        }
    }
    updateBP(item,index) {
        this.currencyList[index].errorMessage ="";
        var cookieToken = getUserToken();
        const params = {
            "userLoginToken": cookieToken,
         //   "password":null,
            "id": item.id?item.id:0,
            "currencyId":item.currencyId,
            "betfairPercentage": this.currencyList[index].rate
        }
        apiCaller(currency.updateBetfairPercentages, 'POST',params).then(response => {
            if(response && response.hasOwnProperty('success') && response.success===true){
                this.currencyList[index].errorMessage ="Changes updated successfully";
                setTimeout(()=>{
                    this.currencyList[index].errorMessage ="";
                    this.getBetfairCurrency(this.currencyList);
                },3000)
               
               

            }else{
                if(response && response.hasOwnProperty('description')){
                this.currencyList[index].errorMessage =response.description
                }else{
                    this.currencyList[index].errorMessage ="There seems to be an error in your request please check the above and try again";
                }
            }
        })

    }
}
export default BetFairPercentagesStore;