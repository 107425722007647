import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

class ByDownline extends Component {
    componentWillMount() {
        this.props.byDownlineStore.calculatePagination(this.props.data.myImmediateUserDetailsList);
    }
    componentWillReceiveProps(nextProps) {
        this.props.byDownlineStore.calculatePagination(nextProps.data.myImmediateUserDetailsList);
    }
    render() {
        const { byDownlineStore, data, profitLossReportStore } = this.props;
        return (<>
            <table className="RDE-DB-table-default">
                <thead>
                    <tr>
                        <th>UID</th>
                        <th>Stake</th>
                        <th>Player P/L</th>
                        {profitLossReportStore.activeBreadCrumb.roleId === 2 ? <th>Super Comm.</th> :
                            profitLossReportStore.activeBreadCrumb.roleId === 3 ? <th>Master Comm.</th> :
                                profitLossReportStore.activeBreadCrumb.roleId === 1 ? <th>Admin Comm.</th> : ''}
                        <th>Upline P/L</th>
                        {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <th>Downline P/L</th> : ""}
                    </tr>
                </thead>
                {
                    data.myImmediateUserDetailsList &&
                    data.myImmediateUserDetailsList.map((item, index) => {
                        if (index >= byDownlineStore.startIndex && index < byDownlineStore.endIndex) {
                            return <>
                                <tbody>
                                    <tr>
                                        <td>
                                            {item.immediateUserRoleid !== 4 ? <i className="RDE-icon-right-arrow" onClick={byDownlineStore.openAccordion.bind(byDownlineStore, index)}></i> : ''}
                                            <span className={"RDE-badge " + (item.immediateUserRoleid === 1 || item.immediateUserRoleid === 2 ? "green" : item.immediateUserRoleid === 3 ? "seaGreen" : "darkYellow")}>
                                                {item.immediateUserRoleid === 1 ? "AD" : item.immediateUserRoleid === 2 ? "SUP" : item.immediateUserRoleid === 3 ? "MA" : "PL"}</span>
                                            <a className="text-underline cursor-pointer" onClick={profitLossReportStore.getProfitLossOfUser.bind(profitLossReportStore, item)}>
                                                {item.name}
                                            </a>
                                        </td>
                                        <td>{Number(item.totStake).toFixed(2)}</td>
                                        <td><span className={"RDE-DB-txtHighlight" + (item.totalPlyrprofitlossSport < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>({Math.abs(Number(item.totalPlyrprofitlossSport).toFixed(2))})</span></td>
                                        <td >{Number(item.totalCommisonSport).toFixed(2)}</td>
                                        <td className={"RDE-DB-txtHighlight" + (item.totalUpProfitlosssport < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>{Math.abs(Number(item.totalUpProfitlosssport).toFixed(2))}</td>
                                        {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className={"RDE-DB-txtHighlight" + (item.totalDwnprofitlosssport < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>{Math.abs(Number(item.totalDwnprofitlosssport).toFixed(2))}</td> : ""}
                                    </tr>
                                </tbody>
                                <Collapse in={byDownlineStore.accordionOpenIndex.indexOf(index) !== -1}>
                                    <tbody>
                                        {
                                            item.sportList && item.sportList.map((sport, sportIndex) => {
                                                return <tr>
                                                    <td>{sport.name}</td>
                                                    <td>{Number(sport.stake).toFixed(2)}</td>
                                                    <td><span className={sport.plyrPLSprt < 0 ? "RDE-DB-txtHighlight" : ""}>({Math.abs(Number(sport.plyrPLSprt).toFixed(2))})</span>
                                                    </td>
                                                    <td className="">{Number(sport.commSprt).toFixed(2)}</td>
                                                    <td className={(sport.upPLSprt < 0 ? " RDE-DB-txtHighlight" : "")}>{Math.abs(Number(sport.upPLSprt).toFixed(2))}</td>
                                                    {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className={(sport.dwnPLsprt < 0 ? " RDE-DB-txtHighlight" : "")}>{Math.abs(Number(sport.dwnPLsprt).toFixed(2))}</td> : ""}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Collapse>
                            </>
                        }
                    })
                }
                <tbody className="RDE-DB-table__total">
                    {data.myImmediateUserDetailsList && data.myImmediateUserDetailsList.length > 0 ? <tr>
                        <td>Total</td>
                        <td>{Number(data.totalStakeImmediate).toFixed(2)}</td>
                        <td><span className={data.totalPlyrProfitLossImmediate < 0 ? "RDE-DB-txtHighlight RDE-DB-loss" : "RDE-DB-txtHighlight RDE-DB-profit"}>({Math.abs(Number(data.totalPlyrProfitLossImmediate).toFixed(2))})</span></td>
                        <td>{Number(data.totalCommisionImmediate).toFixed(2)}</td>
                        <td className={data.totalUpLineProfitLossImmediate < 0 ? "RDE-DB-txtHighlight RDE-DB-loss" : "RDE-DB-txtHighlight RDE-DB-profit"}>{Math.abs(Number(data.totalUpLineProfitLossImmediate).toFixed(2))}</td>
                        {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className={data.totalDwnLineProfitLossImmediate < 0 ? "RDE-DB-txtHighlight RDE-DB-loss" : "RDE-DB-txtHighlight RDE-DB-profit"}>{Math.abs(Number(data.totalDwnLineProfitLossImmediate).toFixed(2))}</td> : ""}
                    </tr> : ''}
                </tbody>
            </table>
            {data.myImmediateUserDetailsList && data.myImmediateUserDetailsList.length > 0 ? <div className="RDE-DB-pagination">
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={byDownlineStore.toalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={byDownlineStore.handlePageChange.bind(byDownlineStore)}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div> : ""}</>
        )
    }
}
export default observer(ByDownline);