import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from "react-bootstrap";
import { getUserRole } from "../../sharedfiles/helper";
import Logs from './logs/Logs';
import LogsStore from './logs/LogsStore';
import dataLoader from '../../assets/images/loader-orange.svg';

const logsStore = new LogsStore();
class Banking extends Component {
    componentWillMount() {
        this.props.bankingStore.getBankingData();
        this.props.bankingStore.getBalance();
        this.props.bankingStore.failedData = [];
        this.props.bankingStore.mainLayoutStore = this.props.mainLayoutStore;
        this.props.bankingStore.userRole=getUserRole();
        
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.balanceUpdate !== nextProps.balanceUpdate) {
            this.props.bankingStore.getBalance();
        }
    }
    render() {
        const { bankingStore } = this.props;
        return (
            <div className="RDE-DB-banking">
                <div className="grid-noGutter-center">
                    <div className="col-center">
                        <div className="RDE-DB-balanceOverview-myBalance">
                            <div>Your Balance</div>
                            <div className="RDE-DB-myBalance__value">PTH {bankingStore.userData.userAccountBalance}</div>
                        </div>
                    </div>
                </div>
                <div className="space-5"></div>
                {!bankingStore.showLoader ? <div className="RDE-DB-table mb-10">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>Balance</th>
                                <th>Available D/W</th>
                                {bankingStore.userRole===1 && <th>Currency</th>}
                                <th>Exposure</th>
                                <th>Deposit / Withdraw</th>
                                <th>Credit Limit</th>
                                <th>Exposure Limit</th>
                                <th>Remark</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bankingStore.bankingList.map((item, index) => {
                                    return <tr key={'bankinglist_' + index}>
                                        <td>{item.name}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.availableDW}</td>
                                        {bankingStore.userRole===1 && <td>{item.currency}</td>}
                                        <td>({item.exposure})</td>
                                        <td>
                                            <div className="RDE-banking-depositWithdraw">
                                                <div className="mb-10">
                                                    <div className="RDE-checkbox deposit" onClick={bankingStore.selectDW.bind(bankingStore, index, 'D')}>
                                                        <input type="checkbox" name={"checkD"} checked={item.tranType === "D"} />
                                                        <label for={"depositHighlights" + index}></label>
                                                        <span className="RDE-checkboxLabel" >Deposit</span>
                                                    </div>
                                                    <span className="RDE-space"></span>
                                                    <div className="RDE-checkbox withdraw" onClick={bankingStore.selectDW.bind(bankingStore, index, 'W')}>
                                                        <input type="checkbox" name="checkW" checked={item.tranType === "W"} data-ind={index} />
                                                        <label for="withdrawHighlights"></label>
                                                        <span className="RDE-checkboxLabel" >Withdraw</span>
                                                    </div>
                                                </div>
                                                <div className="RDE-banking-depositWithdraw__formControl">
                                                    <ul>
                                                        <li><input type="text" className="RDE-formControl" value={item.amount} onChange={bankingStore.amountChange.bind(bankingStore, index)} /></li>
                                                        <li><button disabled={item.tranType !== 'W'} className={"btn RDE-btn-primary RDE-btn-small" + (item.tranType === 'W' ? '' : " disabled")}
                                                            onClick={bankingStore.clickFull.bind(bankingStore, index)}>Full</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center RDE-DB-errorMessage">{item.transError}</div>
                                        </td>

                                        <td>
                                            <ul>
                                                <li>{!item.isEditCredit ? <span>{item.creditLimit}</span> : <input type="text"
                                                    class="RDE-formControl"
                                                    value={item.editCreditVal}
                                                    onChange={bankingStore.changeCredit.bind(bankingStore, index)} />}</li>
                                                <li className="mt-5">{!item.isEditCredit ? <button class="btn RDE-btn-primary RDE-btn-small"
                                                    onClick={bankingStore.editCredit.bind(bankingStore, index)}>Edit</button> :
                                                    <>
                                                        {/*<button class="btn RDE-btn-primary RDE-btn-small RDE-btn-auto mr-5"
                                                            onClick={bankingStore.saveExpo.bind(bankingStore, item, index)}>Save</button>*/}
                                                        <button class="btn RDE-btn-primary RDE-btn-small RDE-btn-auto"
                                                            onClick={bankingStore.cancelCredit.bind(bankingStore, index)}>Cancel</button>
                                                    </>}</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>{!item.isEdit ? <span>{item.exposureLimit}</span> : <input type="text"
                                                    class="RDE-formControl"
                                                    value={item.editExpoVal}
                                                    onChange={bankingStore.changeExpo.bind(bankingStore, index)} />}</li>
                                                <li className="mt-5">{!item.isEdit ? <button class="btn RDE-btn-primary RDE-btn-small"
                                                    onClick={bankingStore.editEposure.bind(bankingStore, index)}>Edit</button> :
                                                    <>
                                                        <button class="btn RDE-btn-primary RDE-btn-small RDE-btn-auto mr-5"
                                                            onClick={bankingStore.saveExpo.bind(bankingStore, item, index)}>Save</button>
                                                        <button class="btn RDE-btn-primary RDE-btn-small RDE-btn-auto"
                                                            onClick={bankingStore.cancelExpo.bind(bankingStore, index)}>Cancel</button>
                                                    </>}</li>
                                                {item.showexpoErr && <li className="mt-5 text-center RDE-DB-errorMessage">
                                                    {item.showexpoErr}
                                                </li>}
                                            </ul>
                                        </td>
                                        <td><input type="text" className="RDE-formControl" value={item.remark} onChange={bankingStore.remarkChange.bind(bankingStore, index)} /></td>
                                        <td><button className="btn RDE-btn-primary RDE-btn-small" onClick={bankingStore.openLogs.bind(bankingStore, item.userId)}>Log</button></td>
                                        {/*  */}
                                    </tr>

                                })
                            }
                            {bankingStore.bankingList && bankingStore.bankingList.length > 0 ? <tr>
                                <td>Total</td>
                                <td>{bankingStore.totalBalance.toFixed(2)}</td>
                                <td>{bankingStore.totalAvailableDW.toFixed(2)}</td>
                                <td>({bankingStore.totalExposure.toFixed(2)})</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> : ""}
                        </tbody>
                    </table>
                </div> : ""}

                <div className="RDE-DB-table mb-10 d-none">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>UID</th>
                                <th>Super Currency</th>
                                <th>Avail. Points in Supper Curr.</th>
                                <th>Balance</th>
                                <th>Available Withdraw</th>
                                <th>Exposure</th>
                                <th>Deposit / Withdraw</th>
                                <th>Remark</th>
                                <th><button className="btn RDE-btn-primary RDE-btn-small"><b>All logs</b></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>dlink123</td><td>179.10</td>
                                <td>55.54</td>
                                <td>179.10</td>
                                <td>55.54</td>
                                <td>(20.00)</td>
                                <td>
                                    <div className="RDE-banking-depositWithdraw">
                                        <div className="mb-10">
                                            <div className="RDE-checkbox deposit">
                                                <input id="depositHighlights" type="checkbox" name="check" />
                                                <label for="depositHighlights"></label>
                                                <span className="RDE-checkboxLabel">Deposit</span>
                                            </div>
                                            <span className="RDE-space"></span>
                                            <div className="RDE-checkbox withdraw">
                                                <input id="withdrawHighlights" type="checkbox" name="check" />
                                                <label for="withdrawHighlights"></label>
                                                <span className="RDE-checkboxLabel">Withdraw</span>
                                            </div>
                                        </div>
                                        <div className="RDE-banking-depositWithdraw__formControl">
                                            <ul>
                                                <li><input type="text" className="RDE-formControl" /></li>
                                                <li><button className="btn RDE-btn-primary RDE-btn-small">Full</button></li>
                                            </ul>
                                        </div>
                                    </div>

                                </td>
                                <td><input type="text" className="RDE-formControl" /></td>
                                <td><button className="btn RDE-btn-primary RDE-btn-small">Log</button></td>
                            </tr>
                            <tr>
                                <td>dlink123</td><td>179.10</td>
                                <td>55.54</td>
                                <td>179.10</td>
                                <td>55.54</td>
                                <td>(20.00)</td>
                                <td>
                                    <div className="RDE-banking-depositWithdraw">
                                        <div className="mb-10">
                                            <div className="RDE-checkbox deposit">
                                                <input id="depositHighlights" type="checkbox" name="check" />
                                                <label for="depositHighlights"></label>
                                                <span className="RDE-checkboxLabel">Deposit</span>
                                            </div>
                                            <span className="RDE-space"></span>
                                            <div className="RDE-checkbox withdraw">
                                                <input id="withdrawHighlights" type="checkbox" name="check" />
                                                <label for="withdrawHighlights"></label>
                                                <span className="RDE-checkboxLabel">Withdraw</span>
                                            </div>
                                        </div>
                                        <div className="RDE-banking-depositWithdraw__formControl">
                                            <ul>
                                                <li><input type="text" className="RDE-formControl" /></li>
                                                <li><button className="btn RDE-btn-primary RDE-btn-small">Full</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" className="RDE-formControl" /></td>
                                <td><button className="btn RDE-btn-primary RDE-btn-small">Log</button></td>
                            </tr>
                            <tr>
                                <td>dlink123</td><td>179.10</td>
                                <td>55.54</td>
                                <td>179.10</td>
                                <td>55.54</td>
                                <td>(20.00)</td>
                                <td>
                                    <div className="RDE-banking-depositWithdraw">
                                        <div className="mb-10">
                                            <div className="RDE-checkbox deposit">
                                                <input id="depositHighlights" type="checkbox" name="check" />
                                                <label for="depositHighlights"></label>
                                                <span className="RDE-checkboxLabel">Deposit</span>
                                            </div>
                                            <span className="RDE-space"></span>
                                            <div className="RDE-checkbox withdraw">
                                                <input id="withdrawHighlights" type="checkbox" name="check" />
                                                <label for="withdrawHighlights"></label>
                                                <span className="RDE-checkboxLabel">Withdraw</span>
                                            </div>
                                        </div>
                                        <div className="RDE-banking-depositWithdraw__formControl">
                                            <ul>
                                                <li><input type="text" className="RDE-formControl" /></li>
                                                <li><button className="btn RDE-btn-primary RDE-btn-small">Full</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td><input type="text" className="RDE-formControl" /></td>
                                <td><button className="btn RDE-btn-primary RDE-btn-small">Log</button></td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>537.3</td>
                                <td>166.62</td>
                                <td>537.3</td>
                                <td>166.62</td>
                                <td>(60.00)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="space-10"></div>
                {bankingStore.transError && bankingStore.transError.split(',').map((item) => <div className="text-center RDE-DB-errorMessage">{item}</div>)}
                {!bankingStore.showLoader && (bankingStore.bankingList && bankingStore.bankingList.length > 0) ? <div className="grid-noGutter-center">
                    <div className="col-6">
                        <div className="RDE-Admin-banking-paymentSubmit">
                            <ul>
                                <li><button className="btn RDE-btn-default" onClick={bankingStore.clearAll.bind(bankingStore)}>Clear All</button></li>
                                <li><span>Password: </span><form autoComplete="off"><input type="password" onChange={bankingStore.passwordChange.bind(bankingStore)} className="RDE-formControl" placeholder="" autocomplete="false" /></form></li>
                                <li><button className="btn RDE-btn-primary" onClick={bankingStore.doPayment.bind(bankingStore)}>Submit {/*Payment <span className="RDE-badge green">{bankingStore.payments.length}</span>*/} </button></li>
                            </ul>
                        </div>

                        <div className="space-10"></div>
                    </div>
                </div> : ''}

                {!bankingStore.showLoader && (!bankingStore.bankingList || (bankingStore.bankingList && bankingStore.bankingList.length === 0)) ? <div className="RDE-noDataMessageBox">
                    Currently data is not available.
                </div> : ''}
                {bankingStore.showLoader ? <div className="RDE-loader">
                    <img src={dataLoader} alt="loading...!" />
                </div> : ''}
                <Modal
                    show={bankingStore.showLogs}
                    backdrop={true}
                    backdropClassName="fade"
                    centered
                    onHide={bankingStore.hideLogs.bind(bankingStore)}
                    dialogClassName="modal-dialog-lg">
                    <Logs logsStore={logsStore} data={bankingStore.bankingLogs}
                        bankingStore={bankingStore}
                        logsUser={bankingStore.logsUser} />
                </Modal>
            </div>
        )
    }
}
export default observer(Banking);