import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, NavLink, Switch } from "react-router-dom";
import { Modal } from "react-bootstrap";
import cookie from 'react-cookies';
import Clock from 'react-live-clock';
import { DropdownButton, Dropdown } from "react-bootstrap";

import Downline from '../downline/Downline';
import DownlineStore from '../downline/DownlineStore';
import MyReports from '../myreports/MyReports';
import MyReportsStore from '../myreports/MyReportsStore';
import AddUser from '../downline/adduser/AddUser';
import AddUserStore from '../downline/adduser/AddUserStore';
// import logo from '../../assets/images/american-exchange-logo.svg';
import logo from '../../assets/images/xchange-logo.svg';
import { removeCookies } from '../../sharedfiles/helper';
import { getUserData } from "../../sharedfiles/helper";
import BetList from '../betlist/BetList';
import BetListStore from '../betlist/BetListStore';
import AddPointsToBase from '../addpointstobase/AddPointsToBase';
import AddPointsToBaseStore from '../addpointstobase/AddPointsToBaseStore';
import AddPointsToAdmin from '../addpointstoadmin/AddPointsToAdmin';
import AddPointsToAdminStore from '../addpointstoadmin/AddPointsToAdminStore';
import MyAccount from '../myaccount/myAccount';
import MyAccountStore from '../myaccount/myAccountStore';
import AddMarkets from '../addmarkets/AddMarkets';
import AddMarketsStore from '../addmarkets/AddMarketsStore';
import ViewMarkets from '../viewmarkets/ViewMarkets';
import ViewMarketsStore from '../viewmarkets/ViewMarketsStore';
import Banking from '../banking/Banking';
import BankingStore from '../banking/BankingStore';
import BetSettlement from '../betsettlement/BetSettlement';
import BetSettlementStore from '../betsettlement/BetSettlementStore';
import BetListLive from '../betlistlive/betListLive';
import BetListLiveStore from '../betlistlive/betListLiveStore';
import RiskManagement from '../riskmanagement/riskManagement';
import RiskManagementStore from '../riskmanagement/riskManagementStore';
import ViewPoints from '../viewpoints/ViewPoints';
import ViewPointsStore from '../viewpoints/ViewPointsStore';
import BetFairPercentages from '../betFairPercentages/BetFairPercentages';
import BetFairPercentagesStore from '../betFairPercentages/BetFairPercentagesStore';
const downlineStore = new DownlineStore();
const addUserStore = new AddUserStore();
const myReportsStore = new MyReportsStore();
const betListStore = new BetListStore();
const myAccountStore = new MyAccountStore();
const addMarketsStore = new AddMarketsStore();
const bankingStore = new BankingStore();
const viewMarketsStore = new ViewMarketsStore();
const betSettlementStore = new BetSettlementStore();
const betListLiveStore = new BetListLiveStore();
const riskManagementStore = new RiskManagementStore();
const addPointsToBaseStore = new AddPointsToBaseStore();
const addPointsToAdminStore = new AddPointsToAdminStore();
const viewPointsStore = new ViewPointsStore();
const betFairPercentagesStore=new BetFairPercentagesStore();
class MainLayout extends Component {
  componentWillMount() {
    if (cookie.load('UserToken') && cookie.load('UserToken').length > 0) {
      this.props.mainLayoutStore.userData = getUserData();
      this.props.mainLayoutStore.isLoggedIn = true;
    } else {
      this.props.history.push('/login');
    }
  }
  componentDidMount() {
    var self = this;
    setTimeout(function reCallService() {
      self.props.mainLayoutStore.getUserBalance();
      setTimeout(reCallService, 3000);
    }, 3000);
  }
  componentWillUnmount() { }
  logOut() {
    removeCookies();
    this.props.mainLayoutStore.isLoggedIn = false;
    window.location.href = '/login'
  }
  render() {
    const { mainLayoutStore } = this.props;
    var balUpdated = mainLayoutStore.balanceUpdate;
    return (
      <div>
        <div className="RD-exchange-sportsBook">
          <div className="RDE-mainHeader">
            <div className="grid-middle-noGutter RDE-mainHeaderInner">
              <div className="col-6">
                <img src={logo} alt="" className="RDE-logo" />
                <div className="RDE-mainHeader__time">
                  <div><Clock format={'HH:mm:ss'} ticking={true} /></div>
                  <div>GMT <Clock format={'Z'} ticking={true} /></div>
                </div>
              </div>
              <div className="col-6">
                <div className="RDE-mainHeader-right">
                  <div className="RDE-afterLogin grid-middle-noGutter">
                    <div className="RDE-afterLogin-balanceSection">
                      <i className="RDE-icon-reload" onClick={mainLayoutStore.getUserBalance.bind(mainLayoutStore)}></i>
                      <div className="RDE-afterLogin-balanceInfo">
                        <div className="">
                          <span className="RDE-afterLogin-balanceInfo__label">Main</span>
                          <span>: {mainLayoutStore.userData.currency} {mainLayoutStore.userData.userAccountBalance}</span>
                        </div>
                      </div>
                    </div>

                    <DropdownButton className="RDE-userProfileDropdown RDE-arrow" id="dropdown-basic-button" alignRight="true" title={
                      <div className="RDE-afterLogin-playerSection">
                        <span className={"RDE-badge " + (mainLayoutStore.userData.roleId === 1 || mainLayoutStore.userData.roleId === 2 ? "green" : mainLayoutStore.userData.roleId === 3 ? "seaGreen" : "darkYellow")}>
                          {mainLayoutStore.userData.roleId === 1 ? "AD" : mainLayoutStore.userData.roleId === 2 ? "SUP" : mainLayoutStore.userData.roleId === 3 ? "MA" : "PL"}</span>
                        {mainLayoutStore.userData.userName}
                        <i className="RDE-icon-user-profile"></i>
                      </div>
                    }>
                      <ul className="RDE-userProfileDropdownMenu ">
                        <li><Dropdown.Item onClick={this.logOut.bind(this)}>Log Out</Dropdown.Item></li>
                      </ul>
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="RDE-subHeader">
              <div className="grid-middle-noGutter">
                <div className="col text-right">
                </div>
              </div>
            </div> */}
          <div className="RDE-dashboard-container">
            <div className="RDE-dashboard-content">
              <div className="RDE-dashboard-leftSideBar">
                <div className="RDE-DB-leftSideBar-title"><span className={"RDE-badge " + (mainLayoutStore.userData.roleId === 1 || mainLayoutStore.userData.roleId === 2 ? "green" : mainLayoutStore.userData.roleId === 3 ? "seaGreen" : "darkYellow")}>{mainLayoutStore.userData.roleId === 1 ? "AD" : mainLayoutStore.userData.roleId === 2 ? "SUP" : mainLayoutStore.userData.roleId === 3 ? "MA" : "PL"}</span><span title="" className="truncate">{mainLayoutStore.userData.userName}</span></div>
                <ul className="RDE-DB-leftMenu">
                  <li ><NavLink className='no-link' to="/downline" activeClassName="active" exact={true}><span>Downline List</span></NavLink></li>
                  <li><NavLink className='no-link' to="/myaccount" activeClassName="active" exact={true}><span>My Account</span></NavLink></li>
                  <li><NavLink className='no-link' to="/myreports" activeClassName="active" exact={true}><span>My Report</span></NavLink></li>
                  <li><NavLink className='no-link' to="/betlist" activeClassName="active" exact={true}><span>Bet List</span></NavLink></li>
                  {(mainLayoutStore.userData.roleId === 1 || mainLayoutStore.userData.roleId === 2) && <li><NavLink className='no-link' to="/betlistlive" activeClassName="active" exact={true}><span>Bet List Live</span></NavLink></li>}
                  <li><NavLink className='no-link d-none' to="/riskmanagement" activeClassName="active" exact={true}><span>Risk Management</span></NavLink></li>
                  <li><NavLink className='no-link' to="/banking" activeClassName="active" exact={true}><span>Banking</span></NavLink></li>
                  {mainLayoutStore.userData.roleId === 1 ? <><li><NavLink className='no-link' to="/addmarkets" activeClassName="active" exact={true}><span>Admin Markets</span></NavLink></li>
                    <li><NavLink className='no-link' to="/viewmarkets" activeClassName="active" exact={true}><span>View Admin Markets</span></NavLink></li>
                    <li><NavLink className='no-link' to="/betsettlement" activeClassName="active" exact={true}><span>Bet Settlement</span></NavLink></li></> : ''}
                  {mainLayoutStore.userData.roleId === 1 ? <><li><NavLink className='no-link' to="/addpointstobase" activeClassName="active" exact={true}><span>Add Points to Base Currency</span></NavLink></li>
                    <li><NavLink className='no-link' to="/addpointstoadmin" activeClassName="active" exact={true}><span>Add Points to Admin Base Currency</span></NavLink></li>
                    <li><NavLink className='no-link' to="/betfairpercentages" activeClassName="active" exact={true}><span>Bet Fair Percentages</span></NavLink></li>
                    <li><NavLink className='no-link' to="/viewpoints" activeClassName="active" exact={true}><span>View Points</span></NavLink></li></> : ''}
                </ul>
              </div>
              <div className="RDE-dashboard-mainContent">
                <Switch>
                  <Route path="/downline" render={(props) => <Downline {...props} downlineStore={downlineStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route path="/myreports" render={(props) => <MyReports {...props} myReportsStore={myReportsStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route path="/betlist" render={(props) => <BetList betListStore={betListStore} />} />
                  <Route path="/betlistlive" render={(props) => <BetListLive betListLiveStore={betListLiveStore} />} />
                  <Route path="/riskmanagement" render={(props) => <RiskManagement riskManagementStore={riskManagementStore} />} />
                  <Route exact path="/myaccount" render={(props) => <MyAccount {...props} downlineStore={downlineStore} myAccountStore={myAccountStore}
                    mainLayoutStore={mainLayoutStore}
                    fromComponent="mainLayout" />} />
                  <Route path="/myaccount/:userId" render={(props) => <MyAccount {...props} downlineStore={downlineStore} myAccountStore={myAccountStore}
                    mainLayoutStore={mainLayoutStore}
                    fromComponent="mainLayout" />} />
                  <Route exact path="/addmarkets" render={(props) => <AddMarkets {...props} addMarketsStore={addMarketsStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route exact path="/banking" render={(props) => <Banking {...props} bankingStore={bankingStore}
                    mainLayoutStore={mainLayoutStore}
                    balanceUpdate={mainLayoutStore.balanceUpdate} />} />
                  <Route exact path="/viewmarkets" render={(props) => <ViewMarkets {...props} viewMarketsStore={viewMarketsStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route exact path="/betsettlement" render={(props) => <BetSettlement {...props} betSettlementStore={betSettlementStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route exact path="/addpointstobase" render={(props) => <AddPointsToBase {...props} addPointsToBaseStore={addPointsToBaseStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route exact path="/addpointstoadmin" render={(props) => <AddPointsToAdmin {...props} addPointsToAdminStore={addPointsToAdminStore}
                    mainLayoutStore={mainLayoutStore} />} />
                       <Route exact path="/betfairpercentages" render={(props) => <BetFairPercentages {...props} betFairPercentagesStore={betFairPercentagesStore}
                    mainLayoutStore={mainLayoutStore} />} />
                  <Route exact path="/viewpoints" render={(props) => <ViewPoints {...props} viewPointsStore={viewPointsStore}
                    mainLayoutStore={mainLayoutStore} />} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={mainLayoutStore.showAddUser}
          onHide={mainLayoutStore.toggleAddUser.bind(mainLayoutStore)}
          backdrop={true}
          backdropClassName="fade"
          centered
          dialogClassName="RDE-loginSignup-modal">
          <AddUser addUserStore={addUserStore} mainLayoutStore={mainLayoutStore}
            downlineStore={downlineStore} />


        </Modal>
        <Modal
          show={mainLayoutStore.showLogoutPopup}
          backdrop={true}
          backdropClassName="fade"
          centered
          dialogClassName="RDE-idleLogout-modal sm">
          <div className="RDE-modal-content">
            <div className="RDE-modal-header">
            </div>
            <div className="RDE-modal-body text-center">
              <div className="RDE-idleLogout-txt"><b>Your session has expired, Please login again.</b></div>
            </div>
            <div className="RDE-modal-footer">
              <div className="grid-noGutter-middle-center">
                <div className="col-4 text-center">
                  <button className="btn RDE-btn-primary" onClick={this.logOut.bind(this)}>Ok</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
export default observer(MainLayout);
