import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

class ByMarket extends Component {
    componentWillMount() {
      
        this.props.byMarketStore.calculatePagination(this.props.data.myReportEventDetails, this.props.profitLossReportStore.showLoader);
       
    }
    componentWillReceiveProps(nextProps) {
      this.props.byMarketStore.calculatePagination(nextProps.data.myReportEventDetails, this.props.profitLossReportStore.showLoader);
      
    }
    componentDidMount() { }
    componentWillUnmount() { }
    render() {
        const { byMarketStore, data, profitLossReportStore } = this.props;
        return (
            <>
                <table className="RDE-DB-table-default">
                    <thead>
                        <tr>
                            <th>UID</th>
                            <th>Stake</th>
                            <th>Player P/L</th>
                            {profitLossReportStore.activeBreadCrumb.roleId === 2 ? <th>Super Comm.</th> :
                                profitLossReportStore.activeBreadCrumb.roleId === 3 ? <th>Master Comm.</th> :
                                    profitLossReportStore.activeBreadCrumb.roleId === 1 ? <th>Admin Comm.</th> : ''}
                            <th>Upline P/L</th>
                            {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <th>Downline P/L</th> : ""}
                        </tr>
                    </thead>
                    {
                        data.myReportEventDetails && data.myReportEventDetails.map((item, index) => {
                            if (index >= byMarketStore.startIndex && index < byMarketStore.endIndex) {
                                return <>
                                    <tbody>
                                        <tr key={'reportBymarket_' + index} onClick={byMarketStore.openAccordion.bind(byMarketStore, index)}>
                                            <td><i className="RDE-icon-right-arrow" ></i>{item.sportName} &gt; {item.eventName} </td>
                                            <td>{Number(item.totalStake).toFixed(2)}</td>
                                            <td><span className={"RDE-DB-txtHighlight" + (item.totalPlyrProfitlossSport < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>({Number(item.totalPlyrProfitlossSport).toFixed(2)})</span></td>
                                            <td className="">{Number(item.totalCommisonSport).toFixed(2)}</td>
                                            <td className="">{Number(item.totalUpProfitlosssport).toFixed(2)}</td>
                                            {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className="">{Number(item.totalDwnProfitlossSport).toFixed(2)}</td> : ""}
                                        </tr>
                                    </tbody>
                                    <Collapse in={byMarketStore.accordionOpenIndex.indexOf(index) !== -1}>
                                        <tbody>
                                            {
                                                item.marketDetails && item.marketDetails.map((market, marketIndex) => {
                                                    return <tr key={"bymarket_market_" + marketIndex}>
                                                        <td>{market.marketName}</td>
                                                        <td>{Number(market.stake).toFixed(2)}</td>
                                                        <td><span className={"RDE-DB-txtHighlight" + (market.playerProfitLoss < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>({Math.abs(market.playerProfitLoss)})</span>
                                                        </td>
                                                        <td className="">{Number(market.commision).toFixed(2)}</td>
                                                        <td className={"RDE-DB-txtHighlight" + (market.uplineProfitLoss < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>{Math.abs(Number(market.uplineProfitLoss).toFixed(2))}</td>
                                                        {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className={"RDE-DB-txtHighlight" + (market.downlineProfitLoss < 0 ? " RDE-DB-loss" : " RDE-DB-profit")}>{Math.abs(Number(market.downlineProfitLoss).toFixed(2))}</td> : ""}
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Collapse>
                                </>
                            }
                        })
                    }
                    <tbody className="RDE-DB-table__total">
                        {data.myReportEventDetails && data.myReportEventDetails.length > 0 ? <tr>
                            <td>Total</td>
                            <td>{Number(data.totalMarketStake).toFixed(2)}</td>
                            <td><span className="RDE-DB-txtHighlight">({Number(data.totalMarketPlyrprofitlossSport).toFixed(2)})</span></td>
                            <td className="">{Number(data.totalMarketCommisonSport).toFixed(2)}</td>
                            <td className="">{Number(data.totalMarketUpProfitlosssport).toFixed(2)}</td>
                            {profitLossReportStore.activeBreadCrumb.roleId !== 3 ? <td className="">{Number(data.totalMarketDwnprofitlosssport).toFixed(2)}</td> : ""}
                        </tr> : ""}
                    </tbody>
                </table>
                {data.myReportEventDetails && data.myReportEventDetails.length > 0 ? <div className="RDE-DB-pagination">
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={byMarketStore.toalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={byMarketStore.handlePageChange.bind(byMarketStore)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div> : ""}
            </>
        )
    }
}
export default observer(ByMarket);