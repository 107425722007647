import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../assets/images/loader-orange.svg';
import { getUserRole } from '../../sharedfiles/helper'
import MatchOdds from './matchodds/matchOdds';
import MatchOddsStore from './matchodds/matchOddsStore';
import FancyBets from './fancybets/fancyBets';
import FancyBetsStore from './fancybets/fancyBetsStore';
import OtherMarkets from './othermarkets/otherMarkets';
import OtherMarketsStore from './othermarkets/otherMarketsStore';

const matchOddsStore = new MatchOddsStore();
const fancyBetsStore = new FancyBetsStore();
const otherMarketsStore = new OtherMarketsStore();

class RiskManagement extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        //   this.props.betListLiveStore.userRole = getUserRole();
        this.props.riskManagementStore.getRiskManagementPlayers();
    }
    componentDidMount(props) {

    }
    componentWillUnmount() {

    }
    render() {
        const { riskManagementStore } = this.props;
        return (
            <div className="RDE-DB-riskManagement">
                <div className="RDE-sports-nav-tabs">
                    <ul className="RDE-sports-nav-tabs-menu">
                        <li onClick={riskManagementStore.selectTab.bind(riskManagementStore, 'matchedPlayer')}>
                            <div className={riskManagementStore.selectedTab == 'matchedPlayer' ? "RDE-sports-nav-tabs-menu__item active" : "RDE-sports-nav-tabs-menu__item"}>
                                <span className="RDE-skewTxt">
                                    Top 10 Matched Amount Player</span>
                            </div>
                        </li>
                        <li onClick={riskManagementStore.selectTab.bind(riskManagementStore, 'exposurePlayer')}>
                            <div className={riskManagementStore.selectedTab == 'exposurePlayer' ? "RDE-sports-nav-tabs-menu__item active" : "RDE-sports-nav-tabs-menu__item"}>
                                <span className="RDE-skewTxt">
                                    Top 10 Exposure Player</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="space-2"></div>
                <div className="RDE-tabcontent">
                {riskManagementStore.selectedTab == 'matchedPlayer' ? <div className="RDE-top10Matched">
                        <div className="RDE-DB-table mb-10">
                            <table className="RDE-DB-table-default">
                                <thead>
                                    <tr>
                                        <th>UID</th>
                                        <th>Exposure</th>
                                        <th>Matched Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (riskManagementStore.playersList.matchedList && riskManagementStore.playersList.matchedList.length > 0) ?
                                        riskManagementStore.playersList.matchedList.map(function (matchedItem, matchedIndex) {
                                            return (
                                                <tr key={'matched_' + matchedIndex}>
                                                    <td>{matchedItem.uid}</td>
                                                    <td><span className="RDE-DB-txtHighlight">{'(' + matchedItem.exposure + ')'}</span></td>
                                                    <td>{matchedItem.matchedAmount}</td>
                                                </tr>
                                            )
                                        })
                                        : ''
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>:''}

                    {riskManagementStore.selectedTab == 'exposurePlayer' ? <div className="RDE-top10Exposure">
                        <div className="RDE-DB-table mb-10">
                            <table className="RDE-DB-table-default">
                                <thead>
                                    <tr>
                                        <th>UID</th>
                                        <th>Exposure</th>
                                        <th>Matched Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (riskManagementStore.playersList.exposureList && riskManagementStore.playersList.exposureList.length > 0) ?
                                        riskManagementStore.playersList.exposureList.map(function (exposureItem, exposureIndex) {
                                            return (
                                                <tr key={'exposure_' + exposureIndex}>
                                                    <td>{exposureItem.uid}</td>
                                                    <td><span className="RDE-DB-txtHighlight">{'(' + exposureItem.exposure + ')'}</span></td>
                                                    <td>{exposureItem.matchedAmount}</td>
                                                </tr>
                                            )
                                        })
                                        : ''
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>:''}
                </div>

                <div className="space-10"></div>

                <MatchOdds matchOddsStore = {matchOddsStore} riskManagementStore={riskManagementStore} />

                <div className="space-10"></div>

                <FancyBets fancyBetsStore = {fancyBetsStore} riskManagementStore={riskManagementStore} />

                <div className="space-10"></div>
                <div className="RDE-Admin-sportsBook">
                    <div className="RDE-sports-nav-tabs">
                        <ul className="RDE-sports-nav-tabs-menu">
                            <li>
                                <div className="RDE-sports-nav-tabs-menu__item active">
                                    <span className="RDE-skewTxt">Sports Book</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="RDE-DB-table mb-10">
                        <table className="RDE-DB-table-default">
                            <thead>
                                <tr>
                                    <th>Sports</th>
                                    <th>Market Date </th>
                                    <th>Event/Market Name</th>
                                    <th className="RDE-Admin-riskOutcome">
                                        <div className="RDE-Admin-riskOutcome__details">
                                            <div>Player P/L</div>
                                            <ul>
                                                <li>1</li>
                                                <li>X</li>
                                                <li>2</li>
                                            </ul>
                                        </div>
                                    </th>
                                    <th>Downline P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cricket</td>
                                    <td>2019-05-28</td>
                                    <td className="text-left"><span className="RDE-tableExpand"> <i
                                        className="RDE-icon-right-arrow"></i></span>
                                        Bangladesh v
                                                India Odd Run Match 1st Over(1,3,5,7,9..)</td>
                                    <td className="RDE-Admin-riskOutcome">
                                        <div className="RDE-Admin-riskOutcome__details">
                                            <ul className="mt-0 mb-0">
                                                <li><span className="RDE-DB-txtHighlight">(22.00)</span></li>
                                                <li>20.00</li>
                                                <li>20.00</li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2">
                                        <div className="">
                                            <div className="RDE-singleLineOutcomeMarketHeader">
                                                <div className="RDE-singleLineOutcomeMarketHeader__leftContainer">
                                                    <div className="RDE-sports-nav-tabs">
                                                        <ul className="RDE-sports-nav-tabs-menu">
                                                            <li>
                                                                <div
                                                                    className="RDE-sports-nav-tabs-menu__item active">
                                                                    <span className="RDE-skewTxt">Sports Book</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="RDE-singleLineOutcomeMarketHeader__rightContainer ">
                                                    <div
                                                        className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll RDE-yes">
                                                        Yes</div>
                                                    <div
                                                        className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll RDE-no">
                                                        No</div>
                                                    <div className="RDE-singleLineOutcomeMarketHeader__minMaxEmpty">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="RDE-OutcomeItemGroup">
                                                <div className="RDE-singleLineOutcomeItem">
                                                    <div className="RDE-singleLineOutcomeItem__OutcomeContainer">
                                                        <div className="RDE-singleLineOutcomeItem__outComeDetails">
                                                            <div className="RDE-singleLineOutcomeItem__outComeName">
                                                                ALL
                                                                        INDIA BJP TOTAL SEAT 9</div>
                                                        </div>
                                                    </div>
                                                    <div className="RDE-singleLineOutcomeItem__BackAllLayAll">
                                                        <ul>
                                                            <li>
                                                                <div className="RDE-backOddsGroup"><button
                                                                    className="btn RDE-btnOdds RDE-btnBackOdds RDE-btnBackOdds_one">
                                                                    <div><b>250</b></div>150.00
                                                                            </button></div>
                                                            </li>
                                                            <li>
                                                                <div className="RDE-backOddsGroup"><button
                                                                    className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds_one">
                                                                    <div><b>250</b></div>230.00
                                                                            </button></div>
                                                            </li>
                                                            <li>
                                                                <div className="RDE-minMax">
                                                                    <div className="RDE-minMax__label">Min/Max</div>
                                                                    <div className="RDE-minMax__value">100 / 200000
                                                                            </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="space-10"></div>
                <OtherMarkets otherMarketsStore={otherMarketsStore} riskManagementStore={riskManagementStore} />
            </div>
        )
    }
}
export default observer(RiskManagement);