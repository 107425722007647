import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class TransactionHistoryStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole:getUserRole(),
      accountStatement:[]
    });
  }
 

  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getaccountStatement(){
    // apiCaller(myaccount.getAccountStatement+"?userToken="+getUserToken(),'GET').then(response => {
    // apiCaller(myaccount.getAccountStatement+"?userToken=7203dcfd-dde2-44ee-ae85-7218096b9579",'GET').then(response => {
    //   if(response && response.data){
    //     this.accountStatement = response.data;
    //   }
    // })
  }

}

export default TransactionHistoryStore;
