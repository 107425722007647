import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TransactionTable from '../transactiontable/transactionTable';
import TransactionTableStore from '../transactiontable/transactionTableStore';
import { getUserData } from "../../../sharedfiles/helper";

const transactionTableStore = new TransactionTableStore();
class AccountStatement extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.accountStatementStore.getaccountStatement();
    }
    // componentDidMount() { }
    // componentWillUnmount() { }
    // onChange() {

    // }
    render() {
        const { mainLayoutStore, myAccountStore } = this.props;
        return (
            <div className="RDE-Admin-profileView__accountStatement">
                <div className="RDE-sportsPage-leagueHeader">
                    Account Statement
                </div>
                {/* <div className="RDE-DB-table mb-10">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Deposit</th>
                                <th>Withdraw</th>
                                <th>Balance</th>
                                <th>Remark</th>
                                <th>From/To</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accountStatementStore.accountStatement.map(function (item, index) {
                                return (
                                    <tr key={'accountstatement_'+index}>
                                        <td>{item.updatedDateTime}</td>
                                        <td>{item.deposit}</td>
                                        <td>{item.withdraw}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.remark}</td>
                                        <td>{item.fromAccount} > {item.toAccount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div> */}
                <TransactionTable transactionTableStore={transactionTableStore} myAccountStore={myAccountStore} mainLayoutStore={mainLayoutStore} />
            </div>
        )
    }
}
export default observer(AccountStatement);