import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactPaginate from 'react-paginate';
import { Dropdown } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import dataLoader from '../../assets/images/loader-orange.svg';
import close from '../../assets/images/modal-close.svg';

class BetSettlement extends Component {
  componentWillMount() {
    this.props.betSettlementStore.getBetSettlement();
  }
  componentDidMount() { }
  componentWillUnmount() { }
  render() {
    const { betSettlementStore } = this.props;
    return (
      <div className="RDE-DB-Admin-betSettlement">
        <div className="RDE-sportsPage-leagueHeader">
          Bet Settlement
        </div>
        <div className="RDE-DB-table">
          <table className="RDE-DB-table-default">
            <thead>
              <tr>
                <th>Event Name</th>
                <th>Market Name</th>
                <th>Sub-Market Name</th>
                <th>Outcome</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {
               !betSettlementStore.showLoader && betSettlementStore.betSettlementList.map((item, index) => {
                  if (index >= betSettlementStore.startIndex && index < betSettlementStore.endIndex) {
                    return <tr>
                      <td>{item.eventName}</td>
                      <td>{item.marketName}</td>
                      <td>{item.submarketName}</td>
                      <td>{item.outcomeName}</td>
                      <td>
                        <Dropdown className="RDE-DB-dropdownDefault">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {item.selectedResult.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul>
                              {
                                betSettlementStore.betResultsList.map((result, resultIndex) => {
                                  if (resultIndex > 0) {
                                    return <li key={'betresults_' + index + '_' + resultIndex}
                                      onClick={betSettlementStore.selectResult.bind(betSettlementStore, result, index)}>
                                      <Dropdown.Item eventKey={result.id}>{result.name}</Dropdown.Item>
                                    </li>
                                  }

                                })
                              }
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  }

                })
              }
            </tbody>
          </table>
        </div>
        <div className="space-5"></div>
        {!betSettlementStore.showLoader && betSettlementStore.betSettlementList && betSettlementStore.betSettlementList.length > 0 ? <div className="RDE-DB-pagination">
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={betSettlementStore.toalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={betSettlementStore.handlePageChange.bind(betSettlementStore)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div> : ""}
        {betSettlementStore.betSettlementList && betSettlementStore.betSettlementList.length > 0 ? <div className="grid-noGutter text-right">
          <div className="col-2">
            <button className="btn RDE-btn-primary" onClick={betSettlementStore.togglePerviewPopUp.bind(betSettlementStore)}>Preview</button>
          </div>

        </div> : ""}
        {!betSettlementStore.showLoader && (betSettlementStore.betSettlementList && betSettlementStore.betSettlementList.length === 0) ? <div className="RDE-noDataMessageBox">
          Currently data is not available.
        </div> : ''}
        {betSettlementStore.showLoader ? <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div> : ''}
        <Modal
          show={betSettlementStore.showPreview}
          onHide={betSettlementStore.togglePerviewPopUp.bind(betSettlementStore)}
          backdrop={true}
          backdropClassName="fade"
          centered
          dialogClassName="RDE-loginSignup-modal">
          <div className="RDE-betSettlementPopup">
            <div className="modal-header">
              <div className="RDE-modal-header__txt">Confirmation Preview</div>
              <img src={close} class="RDE-modal-closeIcon" onClick={betSettlementStore.togglePerviewPopUp.bind(betSettlementStore)} />
            </div>

            <div className="  modal-body">
              <div class="RDE-DB-table">
                <table class="RDE-DB-table-default">
                  <thead>
                    <tr>
                      <th>Event Name</th>
                      <th>Market Name</th>
                      <th>Outcome</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      betSettlementStore.betSettlementParam.map((item, index) => {
                        return <tr key={"betsettlement_preview_" + index}>
                          <td>{item.eventName}</td>
                          <td>{item.marketName}</td>
                          <td>{item.outcomeName}</td>
                          <td>{betSettlementStore.getResultName(item.betStatusId)}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="space-5"></div>
            <div className="RDE-modal-footer modal-footer">
              <div className="grid-noGutter">
                <div className="col-12">
                  <button className="btn RDE-btn-primary RDE-btn-auto mr-5" onClick={betSettlementStore.togglePerviewPopUp.bind(betSettlementStore)}>Back to Edit</button>
                  <button className="btn RDE-btn-primary RDE-btn-auto" onClick={betSettlementStore.UpdateBetSettlement.bind(betSettlementStore)}>Confirm</button>
                </div>
              </div>
            </div>

          </div>
        </Modal>
      </div>
    )
  }
}
export default observer(BetSettlement);