import { extendObservable } from "mobx";

import { reports, riskmanagement } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../../sharedfiles/helper'

class MatchOddsStore {
    constructor(props) {
        extendObservable(this, {
            userRole: null,
            selectedTab : 'matchedPlayer',
            matchOddsList : []
        });
    }

    getRiskManagementMatchOdds() {
        this.showLoader = true;
        this.showErrorMsg = false;
        var token = "";
        var cookeTocken = getUserToken();
        if (cookeTocken && cookeTocken.length > 0) {
            token = cookeTocken;
        }
        if (token.length > 0) {
            apiCaller(riskmanagement.getRiskMatchOdds+"?loginToken="+cookeTocken, 'GET').then(response => {
                this.showLoader = false;
                if (response && response.data) {
                    this.matchOddsList = response.data.matchOdds;
                }
            });
        }
    }
}

export default MatchOddsStore;
