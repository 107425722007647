import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../assets/images/loader-orange.svg';
import { getUserRole } from '../../sharedfiles/helper'

class BetListLive extends Component {
    constructor(props){
        super(props);
    }
    componentWillMount() {
          this.props.betListLiveStore.userRole = getUserRole();
          this.props.betListLiveStore.getSports();
        //   this.props.betListLiveStore.getBetListLiveData();
    }
    componentDidMount(props) { 
      //  console.log('Number(this.props.betListLiveStore.selectedRefTime.time) ==== ', Number(this.props.betListLiveStore.selectedRefTime.time));
      //  console.log('this.props.betListLiveStore.selectedRefTime.time ===== ', this.props.betListLiveStore.selectedRefTime.time);
        // if(this.props.betListLiveStore.selectedRefTime.time !== 'Stop'){
        // try {
        //     this.interval = setInterval(async () => {
        //         this.props.betListLiveStore.getBetListLiveData()
        //     }, Number(this.props.betListLiveStore.selectedRefTime.time));
        // } catch (e) {
        //     console.log('error ==== ', e);
        // }
        // }
    }
    componentWillUnmount() {
        // clearInterval(this.interval);
    }
    render() {
        const { betListLiveStore } = this.props;
        return (
            <div className="RDE-DB-betListLive">
                <div className="RDE-DB-myBets-filters mb-10">
                    <div className="RDE-DB-myBets-filters__left">
                        <span className="RDE-DB-myBets-filters__label">Sports :</span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {betListLiveStore.selectedSport.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            {
                              betListLiveStore.sportsList.map((sport,index)=>{
                                return <li key={'betlistsport_'+index}
                                           onClick={betListLiveStore.selectSport.bind(betListLiveStore, sport)}>
                                            <Dropdown.Item eventKey={sport.id}>{sport.name}</Dropdown.Item>
                                      </li>
                              })
                            }
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                        <span className="RDE-DB-myBets-filters__label">Last: </span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {betListLiveStore.selectedNumTransaction.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            {
                              betListLiveStore.transactionsList.map((trans,index)=>{
                                return <li key={'betlisttransac_'+index}
                                           onClick={betListLiveStore.selectNumTransactions.bind(betListLiveStore, trans)}>
                                            <Dropdown.Item eventKey={trans.id}>{trans.name}</Dropdown.Item>
                                      </li>
                              })
                            }
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                        <span className="RDE-DB-myBets-filters__label">Auto refresh (sec): </span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {betListLiveStore.selectedRefTime.time}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            {
                              betListLiveStore.autoRefreshTimeList.map((refTime,index)=>{
                                return <li key={'betlistliveRefTime_'+index}
                                           onClick={betListLiveStore.selectRefreshTime.bind(betListLiveStore, refTime)}>
                                            <Dropdown.Item eventKey={refTime.id}>{refTime.time}</Dropdown.Item>
                                      </li>
                              })
                            }
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                        <span className="RDE-DB-myBets-filters__label">Bet Status: </span>
                        <Dropdown className="RDE-DB-dropdownDefault">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {betListLiveStore.selectedStatus.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul>
                              {
                                betListLiveStore.betStatusList.map((status,index)=>{
                                  return <li onClick={betListLiveStore.selectStatus.bind(betListLiveStore, status)}>
                                              <Dropdown.Item eventKey={status.id}>{status.name}</Dropdown.Item>
                                          </li>
                                })
                              }
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="RDE-DB-myBets-filters__right">
                        <div className="">
                            <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={betListLiveStore.getBetListLiveData.bind(betListLiveStore)}>
                                <i className="RDE-icon-reload"></i></button>
                        </div>
                    </div>
                </div>
                {betListLiveStore.betListLiveData.unMatched && betListLiveStore.betListLiveData.unMatched !== null?<div className="RDE-DB-betListLive__unMatched mb-10">
                    <div className="RDE-DB-table">
                        <table className="RDE-DB-table-default">
                            <caption className="RDE-DB-table-default__caption">{"unMatched" in betListLiveStore.betListLiveData ? 'UnMatched' : '---' }</caption>
                            <thead>
                                <tr>
                                    <th>MA ID</th>
                                    <th>PL ID</th>
                                    <th>Bet ID</th>
                                    <th>Bet taken</th>
                                    <th>IP Address</th>
                                    <th>Market</th>
                                    <th>Selection</th>
                                    <th>Type</th>
                                    <th>Odds req.</th>
                                    <th>Stake</th>
                                    <th>Liability</th>
                                    {/* <th>Profit/Loss</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {!betListLiveStore.showLoader && betListLiveStore.betListLiveData.unMatched && betListLiveStore.betListLiveData.unMatched.length > 0 ? betListLiveStore.betListLiveData.unMatched.map(function(unMatchedItem,index){
                                    return(<tr key={unMatchedItem.betId+'_'+index}>
                                    <td>{unMatchedItem.masterId}</td>
                                    <td>{unMatchedItem.playerId}</td>
                                    <td>{unMatchedItem.betId}</td>
                                    <td>{unMatchedItem.betPlaced}	</td>
                                    <td>{unMatchedItem.ipAddress}</td>
                                    <td>{unMatchedItem.sportName > unMatchedItem.eventName > unMatchedItem.market}</td>
                                    <td>{unMatchedItem.selection}</td>
                                    <td>{unMatchedItem.type}</td>
                                    <td>{unMatchedItem.oddsRequest}</td>
                                    <td>{unMatchedItem.stake}</td>
                                    <td>{unMatchedItem.liability}</td>
                                    {/* <td>{unMatchedItem.}</td> */}
                                </tr>)
                                }):''}
                                
                                
                            </tbody>
                        </table>
                        {(!betListLiveStore.showLoader &&  (betListLiveStore.betListLiveData.unMatched && betListLiveStore.betListLiveData.unMatched.length === 0)) ? <div className="RDE-noDataMessageBox">
                    Currently data is not available for your selection.
                                      </div> : ''}
                                      {betListLiveStore.showLoader?<div className="RDE-loader">
                                <img src={dataLoader} alt="loading...!" />
                            </div>:''}
                    </div>
                </div>:""}
                {betListLiveStore.betListLiveData.matched && betListLiveStore.betListLiveData.matched!== null?<div className="RDE-DB-betListLive__matched mb-10">
                    <div className="RDE-DB-table">
                        <table className="RDE-DB-table-default">
                            <caption className="RDE-DB-table-default__caption">{"matched" in betListLiveStore.betListLiveData ? 'Matched' : '---' }</caption>
                            <thead>
                                <tr>
                                    <th>MA ID</th>
                                    <th>PL ID</th>
                                    <th>Bet ID</th>
                                    <th>Bet taken</th>
                                    <th>IP Address</th>
                                    <th>Market</th>
                                    <th>Selection</th>
                                    <th>Type</th>
                                    <th>Odds req.</th>
                                    <th>Stake</th>
                                    <th>Liability</th>
                                    {/*<th>Profit/Loss</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                            {!betListLiveStore.showLoader && betListLiveStore.betListLiveData.matched && betListLiveStore.betListLiveData.matched.length > 0 ? betListLiveStore.betListLiveData.matched.map(function(matchedItem,index){
                                return(<tr key={matchedItem.betId+'_'+index}>
                                <td>{matchedItem.masterId}</td>
                                <td>{matchedItem.playerId}</td>
                                <td>{matchedItem.betId}</td>
                                <td>{matchedItem.betPlaced}	</td>
                                <td>{matchedItem.ipAddress}</td>
                                <td>{matchedItem.sportName +' > '+ matchedItem.eventName +' > '+ matchedItem.market}</td>
                                <td>{matchedItem.selection}</td>
                                <td>{matchedItem.type}</td>
                                <td>{matchedItem.oddsRequest}</td>
                                <td>{matchedItem.stake}</td>
                                <td>{matchedItem.liability}</td>
                                {/* <td>{unMatchedItem.}</td> */}
                            </tr>)
                            }):''}
                            </tbody>
                        </table>
                        {(!betListLiveStore.showLoader && (betListLiveStore.betListLiveData.matched && betListLiveStore.betListLiveData.matched.length === 0)) ? <div className="RDE-noDataMessageBox">
                    Currently data is not available for your selection.
                                      </div> : ''}
                        {betListLiveStore.showLoader?<div className="RDE-loader">
                                <img src={dataLoader} alt="loading...!" />
                            </div>:''}
                    </div>
                </div>:""}
            </div>

        )
    }
}
export default observer(BetListLive);