import { extendObservable } from "mobx";

import { currency } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { betStatus, getUserToken } from '../../sharedfiles/helper'

const re = /^[0-9\b.]+$/;
class AddPointsToBaseStore {
  constructor(props) {
    extendObservable(this, {
      currencyList:[],
      showErr:''
    });
  }
  /**
  * This method is used to get the currency list.
  */
  getCurrency(){
    this.showErr="";
    apiCaller(currency.getCurrency,'GET').then(response => {
      this.showLoader=false;
      if(response && response.data && response.data.length>0){
          this.currencyList = response.data;
          for(var currency of this.currencyList){
              currency.rate = '';
          }
      }
    });
  }
  /**
  * This method is called when rate is changed.
  * @param {Number} index contains the index number.
  * @param {Object} e contains the js object with target value. 
  */
  rateChange(index, e){
    if(e.target.value === '' || (re.test(e.target.value) && Number(e.target.value)>0)){
      this.currencyList[index].rate = e.target.value;
    }
  }
  /**
  * This method is used to call the save the currency rating
  *
  */
  saveCurrencyRate(){
    var params=[];
    this.showErr='';
    for(var item of this.currencyList){
      if(Number(item.rate)>0){
        params.push({
          "currencyId":item.currencyId,
          "conversionRate":item.rate
        })
      }
    }
    if(params.length>0){
      apiCaller(currency.updateCurrencyRate,'POST',params).then(response => {
        this.showLoader=false;
        if(response && response.data && response.data==='Success'){
          this.showErr="data saved succesfully"
          setTimeout(()=>{
            this.getCurrency();
          },3000)
        }
        else{
          this.showErr='Request failed please try again'
        }
      });
    }
  }

}

export default AddPointsToBaseStore;
