import { extendObservable } from "mobx";

import { banking } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken, getUserData } from '../../sharedfiles/helper';

const re = /^[0-9\b.]+$/;
class BankingStore {
  constructor(props) {
    extendObservable(this, {
      bankingList: [],
      password: "",
      payments: [],
      failedData: [],
      transError: "",
      userData: {},
      showLogs: false,
      bankingLogs: [],
      logsUserId: "",
      logsUser: "",
      totalBalance: 0,
      totalAvailableDW: 0,
      totalExposure: 0,
      showLoader: false,
      mainLayoutStore: {},
      isCreditLimitCall: false,
      isPaymentUpdated: false,
      isCreditLimitUpdated: false,
      userRole:''
    });
  }
  /**
  * This method is used to get the banking data.
  */
  getBankingData() {
    var token = "";
    this.totalAvailableDW = 0;
    this.totalBalance = 0;
    this.totalExposure = 0;
    this.bankingList = [];
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    this.showLoader = true;
    this.transError = "";
    apiCaller(banking.getBankingDetails + "?userLoginToken=" + token, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data && response.data.length > 0) {
        this.bankingList = response.data;
        for (var i = 0; i < this.bankingList.length; i++) {
          this.bankingList[i]['tranType'] = '';
          this.bankingList[i]['amount'] = "";
          this.bankingList[i]['remark'] = "";
          this.bankingList[i]['transError'] = "";
          this.bankingList[i]['isEdit'] = false;
          this.bankingList[i]['editExpoVal'] = this.bankingList[i]['exposureLimit'];
          this.bankingList[i]['editCreditVal'] = this.bankingList[i]['creditLimit'];
          this.totalBalance = this.totalBalance + this.bankingList[i]['balance'];
          this.totalAvailableDW = this.totalAvailableDW + this.bankingList[i]['availableDW'];
          this.totalExposure = this.totalExposure + this.bankingList[i]['exposure'];
          this.bankingList[i]['showexpoErr'] = "";
        }
        // this.failedData=response.data.failedUser;
        let errorCount = 0
        this.failedData.map((failData) => {
          this.bankingList.map((mainData) => {
            if (mainData.userId === failData.userid) {
              mainData.transError = failData.message;
              errorCount++
            }
          })
        })
        if (errorCount > 0) {
          this.transError = "There seems to be an error in your request please check above and try again ";
        }
      }
    });
  }
  /**
  * This method is called when user select trans type
  * @param {Number} index contain the index number in which transaction type is changed.
  * @param {String} type contain trasaction type.
  */
  selectDW(index, type) {
    if (index < this.bankingList.length) {
      // this.bankingList[index]['tranType'] = type;
      if (type === 'D') {
        if (this.bankingList[index]['tranType'] === 'D') {
          this.bankingList[index]['tranType'] = '';
          this.bankingList[index]['amount'] = '';
          this.bankingList[index]['transError'] = '';
        } else {
          this.bankingList[index]['tranType'] = 'D'
        }
      } else if (type === 'W') {
        if (this.bankingList[index]['tranType'] === 'W') {
          this.bankingList[index]['tranType'] = '';
          this.bankingList[index]['amount'] = '';
          this.bankingList[index]['transError'] = '';
        } else {
          this.bankingList[index]['tranType'] = 'W'
        }
      }
      this.validatePayments();
    }
    let errorCount = 0
    this.bankingList.map((data) => {

      if (data.transError) {
        errorCount++
      }

    })
    if (errorCount == 0) {
      this.transError = "";
    }

  }
  /**
  * This method is called when user changes the amount.
  * @param {Number} index contains the index number.
  * @param {Object} e contains the target element value.
  */
  amountChange(index, e) {
    if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
      this.bankingList[index]['amount'] = e.target.value;
    }
    this.validatePayments();
  }
  /**
  * This method is called when user changes the amount.
  * @param {Number} index contains the index number.
  * @param {Object} index contains the target element value.
  */
  remarkChange(index, e) {
    this.bankingList[index]['remark'] = e.target.value;
    this.validatePayments();
  }
  /**
  * This method is called when user changes the amount.
  * @param {Number} index contains the index number.
  * @param {Object} index contains the target element value.
  */
  passwordChange(e) {
    this.password = e.target.value;
    this.transError = '';
  }
  /**
  * This method is called when user clicks clear all.
  */
  clearAll() {
    for (var i = 0; i < this.bankingList.length; i++) {
      this.bankingList[i]['tranType'] = '';
      this.bankingList[i]['amount'] = "";
      this.bankingList[i]['remark'] = "";
      this.bankingList[i]['transError'] = "";
    }
    this.password = "";
    this.validatePayments();
  }
  /**
  * This method is called when user click full.
  * @param {Number} index contains index number of obj.
  */
  clickFull(index) {
    if (this.bankingList[index]['tranType'] === 'W') {
      if (this.bankingList[index]['availableDW'] > 0) {
        this.bankingList[index]['amount'] = this.bankingList[index]['availableDW'];
      } else {
        this.bankingList[index]['amount'] = ''
      }
    }
    this.validatePayments();
  }
  /**
  * This method is used to validate paments by checking mandatory fields.
  */
  validatePayments() {
    var paymentsObjs = [];
    for (var i = 0; i < this.bankingList.length; i++) {
      if (this.bankingList[i]['tranType'] !== '' && Number(this.bankingList[i]['amount']) > 0) {
        var obj = {
          "userId": this.bankingList[i]['userId'],
          "amount": this.bankingList[i]['amount'],
          "transactionType": this.bankingList[i]['tranType'],
          "remark": this.bankingList[i]['remark']
        }
        paymentsObjs.push(obj);
      }
    }
    this.payments = paymentsObjs;
  }
  checkCredit(isPayment) {
    if (this.password.length > 0 && this.transError.length === 0) {
      var token = "";
      var cookeTocken = getUserToken();
      if (cookeTocken && cookeTocken.length > 0) {
        token = cookeTocken;
      }
      var params = {
        "loginToken": token,
        "password": this.password,
        "creditList": []
      }
      this.bankingList.map((data) => {
        if (data.creditLimit !== data.editCreditVal) {
          params.creditList.push({
            creditLimit: data.editCreditVal,
            userId: data.userId,
          })
        }
      });
      if (params.creditList.length > 0) {
        this.isCreditLimitCall = true;
        apiCaller(banking.updateCredit, 'POST', params).then(response => {
          this.isCreditLimitUpdated = true;
          if (response && response.success) {
            if (isPayment) {
              if (this.isPaymentUpdated) {
                this.isPaymentUpdated = false;
                this.isCreditLimitUpdated = false;
                this.isCreditLimitCall = false;
                this.clearAll();
                this.getBankingData();
              }
            } else {
              this.isCreditLimitUpdated = false;
              this.isCreditLimitCall = false;
              this.clearAll();
              this.getBankingData();
              if (response.data == "fail")
                this.transError = "Your request failed please try again"
            }
          }
          else {
            this.transError = response.description
          }
        });
      }

    }
  }
  /**
  * This method is used to submit request for payments.
  */
  doPayment() {
    this.validateRequest();
    var isPayment = false;
    this.failedData=[];
    if (this.payments.length > 0 && this.transError.length === 0) {
      isPayment = true;
      var token = "";
      var cookeTocken = getUserToken();
      if (cookeTocken && cookeTocken.length > 0) {
        token = cookeTocken;
      }
      var param = {
        "userLoginToken": token,
        "userPassword": this.password,
        "bankingDetailsList": this.payments
      }
      //this.bankingList=[];
      this.showLoader = true;
      this.isPaymentUpdated = true;
      apiCaller(banking.payment, 'POST', param).then(response => {
        this.showLoader = false;
        if (response && response.data && response.data.successUser && response.data.successUser.length > 0) {
          this.isPaymentUpdated = true;

          if (this.isCreditLimitCall) {
            if (this.isCreditLimitUpdated) {
              this.isPaymentUpdated = false;
              this.isCreditLimitUpdated = false;
              this.isCreditLimitCall = false;

              this.clearAll();
              this.getBankingData();
            }
          } else {
            this.isPaymentUpdated = false;
            this.clearAll();
            this.getBankingData();
          }

          this.mainLayoutStore.getUserBalance();
        } else if (response && response.data && response.data === "Invalid password.") {
          this.transError = "Invalid password.";
          

        }
        if (response && response.data && response.data.failedUser && response.data.failedUser.length > 0) {
          this.failedData = response.data.failedUser;
          this.getBankingData();
          //var userNames = response.data.failedUser.join(",")

          // this.transError = "transaction failed for ";
        }
      });
    }
    this.checkCredit(isPayment);
  }
  /**
  * This method is used to validate the request obj.
  */
  validateRequest() {
    this.transError = "";
    let errorCount = 0;
    if (this.password.length > 0) {
      for (var i = 0; i < this.bankingList.length; i++) {
        if (this.bankingList[i]['tranType'] === 'W' &&
          this.bankingList[i]['amount'] > this.bankingList[i]['availableDW']) {
          this.bankingList[i]['transError'] = 'Insufficient available balance to withdraw';
          errorCount++
          // this.transError = (this.transError + (this.transError.length>0?',':'')+ 'insufficient available balance to withdraw for '+this.bankingList[i]['name'])
        } else if (this.bankingList[i]['tranType'] === 'D' &&
          this.bankingList[i]['amount'] > this.userData.userAccountBalance) {
          this.bankingList[i]['transError'] = 'Insufficient available balance to deposit';
          errorCount++
          // this.transError = (this.transError + (this.transError.length>0?',':'')+ 'insufficient available balance to deposit to '+this.bankingList[i]['name'])
        }
      }
      if (errorCount > 0) {
        this.transError = "There seems to be an error in your request please check the above and try again"
      }
    } else {
      this.transError = "Please enter password."
    }

  }
  /**
  * This method is used to get the logged in user balance.
  */
  getBalance() {
    this.userData = getUserData();
  }
  /**
  * This method is used to hide the logs popup.
  */
  hideLogs() {
    this.showLogs = false;
  }
  /**
  * This method is used to open the logs popup.
  * @param {Number} userId contains the id of the user for logs.
  */
  openLogs(userId) {
    this.bankingLogs = [];
    this.logsUserId = userId;
    apiCaller(banking.getLogs + "?userId=" + userId, 'GET').then(response => {
      if (response && response.data &&
        response.data.bankingLogs &&
        response.data.bankingLogs.length > 0) {
        this.bankingLogs = response.data.bankingLogs;
      }
      if (response && response.data && response.data.userName) {
        this.logsUser = response.data.userName;
      }
      this.showLogs = true;
    });
  }
  /**
  * This method is used to convert exposure to edit view.
  * @param{Number} index contains the index number of item.
  */
  editEposure(index) {
    this.bankingList[index]['isEdit'] = true;
  }

  editCredit(index) {
    this.bankingList[index]['isEditCredit'] = true;
  }

  /**
  * This method is used to convert exposure to edit view.
  * @param{Number} index contains the index number of item.
  * @param {Object} e contains the target element value.
  */
  changeExpo(index, e) {
    if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) > 0)) {
      this.bankingList[index]['editExpoVal'] = e.target.value;
    }
  }
  changeCredit(index, e) {
    this.transError = "";
    if (e.target.value === '' || (re.test(e.target.value) && Number(e.target.value) >= 0)) {
      this.bankingList[index]['editCreditVal'] = e.target.value;
    }
  }
  /**
  * This method is used to save the changed expo.
  * @param {Object} user contains the user details. 
  */
  saveExpo(user, index) {
    var token = '';
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    var params = {
      "userId": user.userId,
      "exposureLimit": Number(user.editExpoVal),
      "userLoginToken": token
    }
    apiCaller(banking.updateExpo, 'POST', params).then(response => {
      this.showLoader = false;
      if (response && response.data && response.data == 'Exposure limit updated successfully') {
        this.bankingList[index]['isEdit'] = false;
        this.bankingList[index]['exposureLimit'] = this.bankingList[index]['editExpoVal'];
        this.bankingList[index]['showexpoErr'] = "";
      } else {
        this.bankingList[index]['isEdit'] = false;
        this.bankingList[index]['editExpoVal'] = this.bankingList[index]['exposureLimit'];
        this.bankingList[index]['showexpoErr'] = response.data;
        setTimeout(() => {
          this.bankingList[index]['showexpoErr'] = "";
        }, 2000)
      }
    });
  }
  /**
  * This method is used to save the changed expo.
  * @param {Number} index contains the index number of item. 
  */
  cancelExpo(index) {
    this.bankingList[index]['editExpoVal'] = this.bankingList[index]['exposureLimit'];
    this.bankingList[index]['isEdit'] = false;
  }
  cancelCredit(index) {
    this.bankingList[index]['editCreditVal'] = this.bankingList[index]['creditLimit'];
    this.bankingList[index]['isEditCredit'] = false;
  }

}

export default BankingStore;