import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { getUserData } from "../../../sharedfiles/helper";
import dataLoader from '../../../assets/images/loader-orange.svg';

class TransactionTable extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
    	this.props.transactionTableStore.userId = this.props.myAccountStore.userId;
        this.props.transactionTableStore.getTransactionResults();
    }
    // componentDidMount() { }
     componentWillUnmount() { 
		this.props.transactionTableStore.transactionTableResults=[];
	 }
    // onChange() {

    // }
    render() {
        const { transactionTableStore } = this.props;
        return (
				<div className="RDE-DB-table mb-10">

				{!transactionTableStore.showLoader ? <table className="RDE-DB-table-default">
					<thead>
						<tr>
							<th>Date/Time</th>
							<th>Credit</th>
							<th>Debit</th>
							<th>Balance</th>
							<th>Remark</th>
							<th>From/To</th>
						</tr>
					</thead>
					<tbody>
						{transactionTableStore.transactionTableResults && transactionTableStore.transactionTableResults.map((item, index)=> {
							return (
								<tr key={'accountstatement_'+index}>
									<td>{item.updatedDateTime}</td>
									<td>{item.deposit}</td>
									<td>{item.withdraw}</td>
									<td>{item.balance}</td>
									<td>{item.remark}</td>
									<td>{item.fromAccount} {'>'} {item.toAccount}</td>
								</tr>
							)
						})}
					</tbody>
				</table>:
				
        <div className="RDE-loader">
          <img src={dataLoader} alt="loading...!" />
        </div>}
			</div>
        )
    }
}
export default observer(TransactionTable);