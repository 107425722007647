import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../../assets/images/loader-orange.svg';
import { getUserRole } from '../../../sharedfiles/helper'

class FancyBets extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        //   this.props.betListLiveStore.userRole = getUserRole();
        this.props.fancyBetsStore.getFancyBets();

    }
    componentDidMount(props) {

    }
    componentWillUnmount() {

    }
    render() {
        const { riskManagementStore,fancyBetsStore } = this.props;
        return (

            <div className="RDE-Admin-fancyBet">
                    <div className="RDE-sports-nav-tabs">
                        <ul className="RDE-sports-nav-tabs-menu">
                            <li>
                                <div className="RDE-sports-nav-tabs-menu__item active">
                                    <span className="RDE-skewTxt">Fancy
                                                Bets</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="RDE-DB-table mb-10">
                        <table className="RDE-DB-table-default">
                            <thead>
                                <tr>
                                    <th>Sports</th>
                                    <th>Market Date </th>
                                    <th>Event/Market Name</th>
                                    <th className="RDE-Admin-riskOutcome">
                                        <div className="RDE-Admin-riskOutcome__details">
                                            <div>Player P/L</div>
                                            <ul>
                                                <li>Min</li>
                                                <li>Max</li>
                                            </ul>
                                        </div>
                                    </th>
                                    <th>Books</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cricket</td>
                                    <td>2019-05-28</td>
                                    <td className="text-left"><span className="RDE-tableExpand"> <i
                                        className="RDE-icon-right-arrow"></i></span>
                                        Bangladesh v
                                                India Odd Run Match 1st Over(1,3,5,7,9..)</td>
                                    <td className="RDE-Admin-riskOutcome">
                                        <div className="RDE-Admin-riskOutcome__details">
                                            <ul className="mt-0 mb-0">
                                                <li><span className="RDE-DB-txtHighlight">(22.00)</span></li>
                                                <li>20.00</li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>Books</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td colSpan="2">
                                        <div className="">
                                            <div className="RDE-singleLineOutcomeMarketHeader">
                                                <div className="RDE-singleLineOutcomeMarketHeader__leftContainer">
                                                    <div className="RDE-sports-nav-tabs">
                                                        <ul className="RDE-sports-nav-tabs-menu">
                                                            <li>
                                                                <div
                                                                    className="RDE-sports-nav-tabs-menu__item active">
                                                                    <span className="RDE-skewTxt">Fancy
                                                                                Bets</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="RDE-singleLineOutcomeMarketHeader__rightContainer ">
                                                    <div
                                                        className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-backAll RDE-yes">
                                                        Yes</div>
                                                    <div
                                                        className="RDE-singleLineOutcomeMarketHeader__backAndLayAll RDE-layAll RDE-no">
                                                        No</div>
                                                    <div className="RDE-singleLineOutcomeMarketHeader__minMaxEmpty">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="RDE-OutcomeItemGroup">
                                                <div className="RDE-singleLineOutcomeItem">
                                                    <div className="RDE-singleLineOutcomeItem__OutcomeContainer">
                                                        <div className="RDE-singleLineOutcomeItem__outComeDetails">
                                                            <div className="RDE-singleLineOutcomeItem__outComeName">
                                                                Bangladesh v India Odd Run Match 1st Over</div>
                                                        </div>
                                                    </div>
                                                    <div className="RDE-singleLineOutcomeItem__BackAllLayAll">
                                                        <ul>
                                                            <li>
                                                                <div className="RDE-backOddsGroup"><button className="btn RDE-btnOdds RDE-btnBackOdds RDE-btnBackOdds_one">
                                                                    <div><b>250</b></div>150.00
                                                                            </button></div>
                                                            </li>
                                                            <li>
                                                                <div className="RDE-backOddsGroup"><button className="btn RDE-btnOdds RDE-btnLayOdds RDE-btnLayOdds_one">
                                                                    <div><b>250</b></div>230.00
                                                                            </button></div>
                                                            </li>
                                                            <li>
                                                                <div className="RDE-minMax">
                                                                    <div className="RDE-minMax__label">Min/Max</div>
                                                                    <div className="RDE-minMax__value">100 / 200000
                                                                            </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><button className="btn RDE-btn-primary RDE-btn-small">Books</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

        )
    }
}
export default observer(FancyBets);