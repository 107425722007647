import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import dataLoader from '../../assets/images/loader-orange.svg';

class ViewPoints extends Component {
    componentWillMount() {
        this.props.viewPointsStore.getPoints();
    }
    componentDidMount() { }
    componentWillUnmount() { }
    render() {
        const { viewPointsStore } = this.props;
        return (
            <div className="RDE-DB-Admin-viewPoints">
                <div className="RDE-sportsPage-leagueHeader">
                    View Points
                </div>
                <div className="RDE-DB-myBets-filters mb-10">
                    <div className="RDE-DB-myBets-filters__left">
                        <span className="RDE-DB-myBets-filters__label">From :</span>
                        <DatePicker selected={viewPointsStore.fromDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={viewPointsStore.selectFromDate.bind(viewPointsStore)}
                            className="RDE-DB-datePickerInput"
                            maxDate={new Date()} />
                        <span className="RDE-DB-myBets-filters__label">To :</span>
                        <DatePicker selected={viewPointsStore.toDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={viewPointsStore.selectToDate.bind(viewPointsStore)}
                            className="RDE-DB-datePickerInput"
                            maxDate={new Date()} />
                        <button className="btn RDE-btn-primary RDE-btn-small RDE-btn-auto" onClick={()=> viewPointsStore.getPoints()}>Go</button>
                    </div>
                </div>
                <div className="RDE-DB-table mb-10">
                    <table className="RDE-DB-table-default">
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Amount</th>
                                <th>Rate/Cost %</th>
                                <th>Points in HKD</th>
                                <th>Points balance HKD</th>
                                <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !viewPointsStore.showLoader && viewPointsStore.data.length > 0 && viewPointsStore.data.map((item, index) => {
                                    return <tr>
                                        <td>{item.createdDate}</td>
                                        <td>{item.depositAmount}</td>
                                        <td>{item.conversionRate}</td>
                                        <td>{item.amountPoints}</td>
                                        <td>{item.balancePoints}</td>
                                        <td>{item.reference}</td>
                                    </tr>
                                })
                            }
                        </tbody>

                    </table>
                    {!viewPointsStore.showLoader && viewPointsStore.data.length == 0 ? <div className="RDE-noDataMessageBox">
                        Currently data is not available for your selection.
                    </div>
                        : viewPointsStore.showLoader ? <div className="RDE-loader">
                            <img src={dataLoader} alt="loading...!" />
                        </div> : ''}
                </div>
            </div>
        )
    }
}
export default observer(ViewPoints);