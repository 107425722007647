import { extendObservable } from "mobx";

import { downline } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken, getUserRole } from "../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class DownlineStore {
  constructor(props) {
    extendObservable(this, {
      totalBalance: null,
      totalExposure: null,
      userDetails: [],
      userBreadCrumb: [],
      activeBreadCrumb: {},
      loggedInUserRole: getUserRole(),
      showLoader: false,
      currency: "",
      downlineProps: {},
      showingComponent: "downline",
      availableBalance: null,
      creditLimmit: null,
      creditDistributed: null,
      totalProfitLoss: null,
      showCommissionMsg:{
        userId:'',
        msg:''
      }
    });
  }
  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getDownlineList() {
    this.showLoader = true;
    apiCaller(downline.getDownline + "?userToken=" + getUserToken() + "&userId=", 'GET').then(response => {
      this.showLoader = false;
      this.userDetails = [];
      if (response && response.data && response.data.hasOwnProperty('creditLimmit')) {
        this.creditLimmit = response.data.creditLimmit;
      } else {
        this.creditLimmit = "";
      }
      if (response && response.data && response.data.hasOwnProperty('creditDistributed')) {
        this.creditDistributed = response.data.creditDistributed;
      } else {
        this.creditDistributed = "";
      }
      if (response && response.data && response.data.hasOwnProperty('totalProfitLoss')) {
        this.totalProfitLoss = response.data.totalProfitLoss;
      } else {
        this.totalProfitLoss = "";
      }
      if (response && response.data && response.data.hasOwnProperty('totalExposure')) {
        this.totalExposure = response.data.totalExposure;
      } else {
        this.totalExposure = "";
      }
      if (response && response.data && response.data.hasOwnProperty('userDetails') && response.data.userDetails.length > 0) {
        this.userDetails = response.data.userDetails;
      } else {
        this.userDetails = [];
      }
      if (response && response.data && response.data.hasOwnProperty('totalAvailableBalance')) {
        this.totalAvailableBalance = response.data.totalAvailableBalance;
      } else {
        this.totalAvailableBalance = "";
      }
    })
  }
  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  getDownlineListOfUser(item, isBreadCrumb) {
    this.showingComponent = 'downline';
    this.userDetails = [];
    this.showLoader = true;
    if (!isBreadCrumb) {
      this.userBreadCrumb.push({
        "userId": item.userId,
        "roleId": item.roleId,
        "userName": item.account
      })
    }
    this.activeBreadCrumb = item;
    apiCaller(downline.getDownline + "?userToken=" + getUserToken() + "&userId=" + item.userId, 'GET').then(response => {
      this.showLoader = false;
      if (response && response.data && response.data.hasOwnProperty('userDetails') && response.data.userDetails.length > 0) {
        this.userDetails = response.data.userDetails;
      }
    })
  }
  /**
  * This method is used to show the user account details.
  *
  */
  goToUserAccount(item, isBreadCrumb) {
    if (!isBreadCrumb) {
      this.userBreadCrumb.push({
        "userId": item.userId,
        "roleId": item.roleId,
        "userName": item.account
      })
    }
    this.showingComponent = "myaccount"
    this.accountUserRole = item.roleId;
    this.accountUserId = item.userId;
  }
  /**
  * This method is used to get the downline list data when user clicks on breadcrumb item.
  * @param {number} index- contains index.
  */
  breadCrumbClick(index, userId) {
    if (this.userBreadCrumb[index].roleId !== 4) {
      var breadCrumbItems = this.userBreadCrumb.slice(0, index + 1);
      this.userBreadCrumb = breadCrumbItems;
      this.showingComponent = "downline";
      this.getDownlineListOfUser(this.userBreadCrumb[index], true);
    }
  }
  /**
  * This method is used to get the downline list data.
  * @param {number} userId- contains userId.
  */
  refreshData() {
    this.getDownlineListOfUser(this.activeBreadCrumb, true);
  }

  /**
   * this method used to handle mark as paid
   */
  handleMarkAsPaid(data) {
    this.showCommissionMsg={
      userId:'',
      msg:''
    };
    const params = {
      userLoginToken: getUserToken(),
      userId: data.userId,
      commissionToCredit: data.pendingCommission
    }

    apiCaller(downline.settleCommission, 'POST', params).then(response => {
      if(response.hasOwnProperty('data')){
        this.showCommissionMsg={
          userId:data.userId,
          msg:response.data
        };
      }
      else{
        this.showCommissionMsg="Request failed please try again"
        this.showCommissionMsg={
          userId:data.userId,
          msg:"Request failed please try again"
        };
      }
      setTimeout(()=>{
        this.showCommissionMsg="";
        this.getDownlineList();
      },3000)
    })
  
  }
}

export default DownlineStore;
