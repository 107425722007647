import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, DropdownButton } from "react-bootstrap";
import dataLoader from '../../assets/images/loader-orange.svg';

class AddMarkets extends Component {
  componentWillMount() {
    this.props.addMarketsStore.getSports()
  }
  componentDidMount() { }
  componentWillUnmount() {
    this.props.addMarketsStore.showErrMsg = false;
  }
  render() {
    const { addMarketsStore } = this.props
    return (
      <div className="RDE-DB-Admin-screens">
        <div className="RDE-DB-Admin-addAdminMatchOdds">
          <div className="RDE-sportsPage-leagueHeader">
            Admin Markets
          </div>
          <div className="RDE-DB-myBets-filters mb-10">
            <div className="RDE-DB-myBets-filters__left">
              <span className="RDE-DB-myBets-filters__label">Sports :</span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" className="truncate" id="dropdown-basic">
                  {addMarketsStore.selectedSport.sportName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      addMarketsStore.sportsList.map((sport, index) => {
                        return <li key={"adminmarketsport_" + index}
                          onClick={addMarketsStore.selectSport.bind(addMarketsStore, sport)}><Dropdown.Item eventKey={sport.sportName}>{sport.sportName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>

              <span className="RDE-DB-myBets-filters__label">League: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" className="truncate" id="dropdown-basic">
                  {addMarketsStore.selectedLeague.leagueName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      addMarketsStore.leaguesList.map((league, index) => {
                        return <li key={"adminmarketleague_" + index}
                          onClick={addMarketsStore.selectLeague.bind(addMarketsStore, league)}><Dropdown.Item eventKey={league.leagueName}>{league.leagueName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <span className="RDE-DB-myBets-filters__label">Events: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" className="truncate" id="dropdown-basic">
                  {addMarketsStore.selectedEvent.eventName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    {
                      addMarketsStore.eventsList.map((event, index) => {
                        return <li key={"adminmarketevent_" + index}
                          onClick={addMarketsStore.selectEvent.bind(addMarketsStore, event)}><Dropdown.Item eventKey={event.eventName}>{event.eventName}</Dropdown.Item></li>
                      })
                    }
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <span className="RDE-DB-myBets-filters__label">Bet Type: </span>
              <Dropdown className="RDE-DB-dropdownDefault">
                <Dropdown.Toggle variant="success" className="truncate" id="dropdown-basic">
                  {addMarketsStore.selectedMarket}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul>
                    <li>
                      <Dropdown.Item eventKey="Match Odds"
                        onClick={addMarketsStore.selectMarket.bind(addMarketsStore, "Match Odds")}>Match Odds</Dropdown.Item>
                    </li>
                    <li>

                      <Dropdown.Item eventKey="Fancy Markets"
                        onClick={addMarketsStore.selectMarket.bind(addMarketsStore, "Fancy Markets")}>Fancy Markets</Dropdown.Item>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {addMarketsStore.selectedMarket === "Match Odds" && addMarketsStore.selectedEvent.eventName && addMarketsStore.selectedEvent.eventName.length > 0 ? <><div className="RDE-DB-table">
            <table className="RDE-DB-table-default">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Back Odds</th>
                  <th>Lay Odds</th>
                  <th>Back Size</th>
                  <th>Lay Size</th>
                  <th>Min. Stake</th>
                  <th>Max. Stake</th>
                </tr>
              </thead>
              <tbody>
                {
                  addMarketsStore.addMatchOdds.map((outcome, index) => {
                    return <tr key={"addmatchOdds_" + index}>
                      <td>{outcome.team}</td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["backOdds"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'backOdds', index)} /></td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["layOdds"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'layOdds', index)} /></td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["backSize"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'backSize', index)} /></td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["laySize"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'laySize', index)} /></td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["minStake"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'minStake', index)} /></td>
                      <td><input type="text"
                        value={addMarketsStore.addMatchOdds[index]["maxStake"]}
                        onChange={addMarketsStore.changeMOField.bind(addMarketsStore, 'maxStake', index)} /></td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
            <div className="space-5"></div>
            {addMarketsStore.addMatchOdds && addMarketsStore.addMatchOdds.length > 0 ? <div className="grid-noGutter text-right">
              <div className="col-2">
                <button className="btn RDE-btn-primary"
                  onClick={addMarketsStore.confirmMO.bind(addMarketsStore)}>Confirm</button>
              </div>

            </div> : ''}
            {addMarketsStore.showErrMsg &&
              <div className="RDE-DB-errorMessage text-center">{addMarketsStore.showErrMsg}</div>}
          </> : ''}

          {addMarketsStore.selectedMarket === "Fancy Markets" && addMarketsStore.selectedEvent.eventName && addMarketsStore.selectedEvent.eventName.length > 0 ? <><div className="RDE-DB-table">
            <div className="grid-noGutter">
              <div className="col-12 text-right mb-5">
                <button className="btn RDE-btn-default RDE-btn-auto RDE-btn-small"
                  onClick={addMarketsStore.pushFancy.bind(addMarketsStore)}>
                  Add
                </button>
              </div>
            </div>
            <table className="RDE-DB-table-default">
              <thead>
                <tr>
                  <th rowspan="2">Market</th>
                  <th colspan="2">Outcome</th>
                  <th colspan="2">Odds</th>
                  <th rowspan="2">Min. Stake</th>
                  <th rowspan="2">Max. Stake</th>
                </tr>
                <tr>
                  <th>Yes</th>
                  <th>No</th>
                  <th>Yes</th>
                  <th>No</th>
                </tr>
              </thead>
              <tbody>
                {addMarketsStore.addFancyMarkets.map((item, index) => {
                  return <tr key={"addfancy_" + index}>
                    <td>{item.marketName}</td>
                    <td>{item.outcomeYes}</td>
                    <td>{item.outcomeNo}</td>
                    <td>{item.oddsYes}</td>
                    <td>{item.oddsNo}</td>
                    <td>{Number(item.minStake) > 0 ? item.minStake : '-'}</td>
                    <td>{Number(item.maxStake) > 0 ? item.maxStake : '-'}</td>
                  </tr>
                })}
                <tr>
                  <td><input type="text" value={addMarketsStore.addFancy.marketName}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'marketName')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.outcomeYes}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'outcomeYes')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.outcomeNo}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'outcomeNo')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.oddsYes}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'oddsYes')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.oddsNo}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'oddsNo')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.minStake}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'minStake')} /></td>
                  <td><input type="text" value={addMarketsStore.addFancy.maxStake}
                    onChange={addMarketsStore.changeFancyField.bind(addMarketsStore, 'maxStake')} /></td>
                </tr>

              </tbody>
            </table>
            <div className="RDE-DB-errorMessage text-center">{addMarketsStore.addFancyErr}</div>
          </div>
            <div className="space-5"></div>
            <div className="grid-noGutter text-right">
              <div className="col-2">
                <button className="btn RDE-btn-primary" onClick={addMarketsStore.confirmFancy.bind(addMarketsStore)}>Confirm</button>
              </div>
            </div>
            {addMarketsStore.showErrMsg &&
              <div className="RDE-DB-errorMessage text-center">{addMarketsStore.showErrMsg}</div>}
          </> : ''}
          {!addMarketsStore.showLoader &&
            (Object.keys(addMarketsStore.selectedEvent).length === 0 ||
              addMarketsStore.selectedMarket.length === 0) ? <div className="RDE-noDataMessageBox">
            Currently data is not available.
          </div> : ''}

          {addMarketsStore.showLoader ? <div className="RDE-loader">
            <img src={dataLoader} alt="loading...!" />
          </div> : ''}
        </div>
      </div>
    )
  }
}
export default observer(AddMarkets);