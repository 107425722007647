import { extendObservable } from "mobx";

import { myaccount } from "../../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../../sharedfiles/apiStore';
import { getUserToken, getUserRole, betStatus, getUserData } from "../../../sharedfiles/helper";

const re = /^[0-9\b]+$/;
class BettingHistoryStore {
  constructor(props) {
    extendObservable(this, {
      loggedInUserRole: getUserRole(),
      bettingHistoryData: [],
      selectedBetStatus: 'Matched',
      fromDate: new Date(),
      toDate: new Date(),
      selectedOption: '',
      selectedStatus: betStatus[0],
      betStatusList: betStatus,
      accordionOpenIndex: [0],
      userData : getUserData(),
      userId:"",
      dateErrMsg:''
    });
  }

  /**
  * This method is called when user selects status
  * @param {string} status contains the user selected status.
  */
  selectStatus(status) {
    this.selectedStatus = status;
  }
  /**
   *	This method is called when user selects the from date.
   * @param {date} selectDate contatins the selected from date.
   */
  selectFromDate(selectedDate) {
    this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate) {
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  getHistory() {
    this.selectedOption = "";
    this.getBetHistory();
  }
  resetFields(){
    this.dateErrMsg="";
    this.fromDate=new Date();
    this.toDate=new Date();
    this.selectedOption="";
   
  }
  getBetHistory() {
   
    this.showErrorMsg = false;
    var fromDate = new Date(this.fromDate);
    var toDate = new Date(this.toDate);

    var fDate = fromDate.getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);

    var tDate = toDate.getFullYear() + "-" + ("0" + (toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + toDate.getDate()).slice(-2);
    if (new Date(fDate) > new Date(tDate)) {
      this.dateErrMsg = "From date should less than to date";
      this.showErrorMsg = true;
      return;
    }
    this.showLoader = true;
    var token = "";
    var cookeTocken = getUserToken();
    if (cookeTocken && cookeTocken.length > 0) {
      token = cookeTocken;
    }
    if (token.length > 0) {
      var param = {
        "fromDate": fDate,
        "toDate": tDate,
        // "playerUserId": 171,
        "playerUserId": this.userId,
        "timeInterval": 330,
        "betStatusId": this.selectedStatus.id,
        "userToken": cookeTocken,
        // "userToken":"b408b9b0-534a-4dee-b78f-325d8a1f99cd"
      }


      apiCaller(myaccount.getBettingHistoryDetails, 'POST', param).then(response => {
        this.showLoader = false;
        this.bettingHistoryData =[];
        var self = this;
        if (response && response.data) {
          if(response.data.errorMessage){
            this.bettingHistoryData =[];
          }else{
          //this.bettingHistoryData = response.data;
          response.data.map(function(item,index){
            item['market'] = item.sportName +' > '+ item.eventName +' > '+ item.marketName
            self.bettingHistoryData.push(item);
          })
          }
        }
      });
    }
  }
  /**
  * This method is called when user select today or yesterday
  * @param {String} option contains 'today' and 'yesterday' to get data
  */
  selectTodayYesterday(option) {
    this.dateErrMsg="";
    this.selectedOption = option;
    if (option === "today") {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getBetHistory();
    } else if (option === "yesterday") {
      var yDate = new Date();
      var yDay = yDate.getDate() - 1;
      var yMonth = yDate.getMonth() + 1;
      var yYear = yDate.getFullYear();
      this.fromDate = new Date(yMonth + "/" + yDay + "/" + yYear);
      this.toDate = new Date();
      this.getBetHistory();
    }
  }
}

export default BettingHistoryStore;
