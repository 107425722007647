import { extendObservable } from "mobx";

class DownlineListTableStore {
  constructor(props) {
    extendObservable(this, {
      showChangeStatus:false,
      changeStatusUser:{}
    });
  }
  /**
  * This method is used to show and hide the change status popup.
  * @param {Object} item contatins user information to which status should change.
  */
  toggleChangeSatausPopUp(item){
    this.changeStatusUser = item;
    this.showChangeStatus = !this.showChangeStatus
  }
}

export default DownlineListTableStore;
