import { extendObservable } from "mobx";

import { currency } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from '../../sharedfiles/apiStore';
import { getUserToken } from '../../sharedfiles/helper'

class ViewPointsStore {
  constructor(props) {
    extendObservable(this, {
      fromDate: new Date(),
      toDate: new Date(),
      data: [],
      showLoader: ''
    });
  }
  /**
  * This method is called when user selects the to date.
  */
  selectToDate(selectedDate) {
    this.toDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * This method is called when user selects the from date.
  * @param {date} selectDate contatins the selected from date.
  */
  selectFromDate(selectedDate) {
    this.fromDate = new Date(selectedDate);
    this.dateErrMsg = "";
  }
  /**
  * this method is used to get the points
  */
  getPoints() {
    this.showLoader = true;
    var params = {
      "userLoginToken": getUserToken(),
      "fromDate": this.fromDate,
      "toDate": this.toDate
    }
    apiCaller(currency.viewpoints, 'POST', params).then(response => {
      this.showLoader = false;
      if (response && response.data &&
        response.data.length > 0) {
        this.data = response.data;
      }
    });
  }
}

export default ViewPointsStore;
